import React from 'react';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { FaCalendar } from 'react-icons/fa';

const AppointmentsTab = ({ 
  appointments, 
  currentPage, 
  setCurrentPage, 
  itemsPerPage,
  getStatusColor 
}) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAppointments = appointments.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(appointments.length / itemsPerPage);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <FaCalendar className="mr-2 text-blue-500" />
        Randevular
      </h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                Tarih
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                Randevu Tipi
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Paket
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                Veteriner
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">
                Durum
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentAppointments.map((appointment) => (
              <tr key={appointment._id} className="hover:bg-gray-50">
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                  {format(new Date(appointment.date), 'dd MMMM yyyy', { locale: tr })} {appointment.time}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                  {appointment.appointment_type}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                  {appointment.appointment_package}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                  {appointment.veterinarian}
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(appointment.status)}`}>
                    {appointment.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center mt-4 space-x-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 bg-gray-100 text-gray-700 rounded-lg disabled:opacity-50"
          >
            Önceki
          </button>
          <span className="px-3 py-1">
            Sayfa {currentPage} / {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-3 py-1 bg-gray-100 text-gray-700 rounded-lg disabled:opacity-50"
          >
            Sonraki
          </button>
        </div>
      )}

      {appointments.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          Henüz randevu bulunmamaktadır.
        </div>
      )}
    </div>
  );
};

export default AppointmentsTab;
