import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SearchBar() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({ pets: [], owners: [] });
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const searchTimeoutRef = useRef(null);
  const navigate = useNavigate();

  // Dropdown dışına tıklayınca kapanma
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // API'den veri çekme
  const searchData = async (searchQuery) => {
    if (!searchQuery.trim()) {
      setResults({ pets: [], owners: [] });
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error('Token bulunamadı');
        return;
      }

      const response = await axios.get(`https://api.dev1.fugesoft.com/api/search?query=${encodeURIComponent(searchQuery)}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setResults(response.data);
    } catch (error) {
      console.error('Arama hatası:', error);
    } finally {
      setLoading(false);
    }
  };

  // Debounce ile arama
  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowDropdown(true);

    // Önceki timeout'u temizle
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // Yeni timeout ayarla
    searchTimeoutRef.current = setTimeout(() => {
      searchData(value);
    }, 300);
  };

  // Sonuçlara gitme
  const handleResultClick = (item, type) => {
    setShowDropdown(false);
    if (type === 'pet') {
      navigate(`/pet-details/${item._id}`);
    } else {
      navigate(`/pet-owner/${item._id}`);
    }
  };

  return (
    <div className="relative w-full max-w-2xl" ref={dropdownRef}>
      <div className="relative">
        <input
          type="search"
          value={query}
          onChange={handleSearch}
          placeholder="Hasta veya hasta sahibi ara..."
          className="w-full h-10 px-5 pr-10 text-sm bg-white border rounded-lg focus:outline-none focus:border-blue-500 text-gray-900"
        />
        <div className="absolute right-0 top-0 mt-3 mr-4">
          {loading ? (
            <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <svg className="h-4 w-4 fill-current text-gray-500" viewBox="0 0 20 20">
              <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
            </svg>
          )}
        </div>
      </div>

      {/* Sonuçlar Dropdown */}
      {showDropdown && (query.trim() !== '') && (
        <div className="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-96 overflow-y-auto">
          {/* Hasta Sonuçları */}
          {results.pets.length > 0 && (
            <div className="p-2">
              <h3 className="text-xs font-semibold text-gray-500 uppercase px-2 mb-1">Hastalar</h3>
              {results.pets.map((pet) => (
                <div
                  key={pet._id}
                  onClick={() => handleResultClick(pet, 'pet')}
                  className="p-2 hover:bg-gray-100 rounded cursor-pointer text-gray-900"
                >
                  <div className="font-medium">{pet.name}</div>
                  <div className="text-sm text-gray-600">
                    {pet.owner_name} {pet.ProtokolNo && `(${pet.ProtokolNo})`}
                  </div>
                  <div className="text-xs text-gray-500">
                    {[pet.species, pet.breed, pet.gender].filter(Boolean).join(' - ')}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Hasta Sahibi Sonuçları */}
          {results.owners.length > 0 && (
            <div className="p-2">
              <h3 className="text-xs font-semibold text-gray-500 uppercase px-2 mb-1">Hasta Sahipleri</h3>
              {results.owners.map((owner) => (
                <div
                  key={owner._id}
                  onClick={() => handleResultClick(owner, 'owner')}
                  className="p-2 hover:bg-gray-100 rounded cursor-pointer text-gray-900"
                >
                  <div className="font-medium">{owner.name}</div>
                  <div className="text-sm text-gray-600">
                    {owner.protocol_no && `Protokol: ${owner.protocol_no}`}
                    {owner.phone_number && ` • Tel: ${owner.phone_number}`}
                  </div>
                  {owner.address && (
                    <div className="text-xs text-gray-500 truncate">
                      {owner.address}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Sonuç Bulunamadı */}
          {!loading && query.trim() !== '' && results.pets.length === 0 && results.owners.length === 0 && (
            <div className="p-4 text-center text-gray-500">
              Sonuç bulunamadı
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
