import React, { useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import { format } from 'date-fns';

const NewOwnerForm = ({ onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    address: '',
    email: '',
    tc_no: '',
    protocol_no: '',
    balance: 0,
    group: '',
    city: '',
    district: '',
    neighborhood: '',
    adoption_status: false,
    card_number: '',
    description: '',
    contact_person: '',
    birth_date: '',
    profession: '',
    tax_office: '',
    tax_number: '',
    secondary_phone: '',
    tertiary_phone: '',
    additional_info: '',
    status: 'Aktif'
  });

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const token = localStorage.getItem('authToken');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateStep = (step) => {
    switch(step) {
      case 1:
        if (!formData.name || !formData.phone_number) {
          toast.error('Lütfen ad soyad ve telefon numarasını girin');
          return false;
        }
        break;
      case 2:
        if (!formData.address) {
          toast.error('Lütfen adres bilgisini girin');
          return false;
        }
        break;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleConfirmSubmit = () => {
    setShowConfirmation(true);
  };

  const handleCancelSubmit = () => {
    setShowConfirmation(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Format dates
      const now = new Date();
      const submissionData = {
        ...formData,
        registration_date: format(now, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        created_at: format(now, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        updated_at: format(now, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        birth_date: formData.birth_date ? format(new Date(formData.birth_date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null,
        pets: [],
        total_price: 0
      };

      const response = await axios.post('https://api.dev1.fugesoft.com/api/owners', submissionData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        toast.success('Hasta sahibi başarıyla eklendi');
        onSuccess?.();
        onClose?.();
      }
    } catch (error) {
      console.error('Error creating owner:', error);
      toast.error(error.response?.data?.message || 'Hasta sahibi eklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const renderStep1 = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">İsim Soyisim *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Telefon *</label>
          <input
            type="tel"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">TC No</label>
        <input
          type="text"
          name="tc_no"
          value={formData.tc_no}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">E-posta</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Protokol No</label>
        <input
          type="text"
          name="protocol_no"
          value={formData.protocol_no}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Şehir</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">İlçe</label>
          <input
            type="text"
            name="district"
            value={formData.district}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Mahalle</label>
          <input
            type="text"
            name="neighborhood"
            value={formData.neighborhood}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Adres</label>
        <textarea
          name="address"
          value={formData.address}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Grup</label>
        <select
          name="group"
          value={formData.group}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="">Seçiniz</option>
          <option value="Kedi Hasta Sahibi">Kedi Hasta Sahibi</option>
          <option value="Köpek Hasta Sahibi">Köpek Hasta Sahibi</option>
          <option value="Kuş Hasta Sahibi">Kuş Hasta Sahibi</option>
          <option value="Diğer">Diğer</option>
        </select>
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Doğum Tarihi</label>
        <input
          type="date"
          name="birth_date"
          value={formData.birth_date}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Meslek</label>
        <input
          type="text"
          name="profession"
          value={formData.profession}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Vergi Dairesi</label>
          <input
            type="text"
            name="tax_office"
            value={formData.tax_office}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Vergi No</label>
          <input
            type="text"
            name="tax_number"
            value={formData.tax_number}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Özel Not</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Yeni Hasta Sahibi Ekle</h2>
      
      <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
        {/* Progress bar */}
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
          <div 
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${(currentStep / 3) * 100}%` }}
          ></div>
        </div>

        {/* Step content */}
        {currentStep === 1 && renderStep1()}
        {currentStep === 2 && renderStep2()}
        {currentStep === 3 && renderStep3()}

        {/* Navigation buttons */}
        <div className="flex justify-between pt-4">
          <button
            type="button"
            onClick={() => setCurrentStep(prev => Math.max(prev - 1, 1))}
            disabled={currentStep === 1}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            Geri
          </button>
          
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              İptal
            </button>
            
            {currentStep < 3 ? (
              <button
                type="button"
                onClick={handleNext}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                İleri
              </button>
            ) : (
              showConfirmation ? (
                <div className="flex space-x-2">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="flex items-center space-x-2 px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                  >
                    <FaCheck />
                    <span>{loading ? 'Kaydediliyor...' : 'Onayla'}</span>
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelSubmit}
                    className="flex items-center space-x-2 px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <FaTimes />
                    <span>İptal</span>
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  onClick={handleConfirmSubmit}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Kaydet
                </button>
              )
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewOwnerForm;
