import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Pagination, Button } from '@mui/material';
import { FaWhatsapp, FaSms, FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

const UpcomingVaccinesModal = ({ open, onClose }) => {
  const [upcomingVaccines, setUpcomingVaccines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  const fetchVaccineSchedules = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('authToken');
      const response = await axios.get('https://api.dev1.fugesoft.com/api/vaccine-schedules/all-pet-schedules', {
        headers: { Authorization: `Bearer ${token}` }
      });

      const sortedSchedules = response.data.schedules
        .sort((a, b) => moment(a.start_date).diff(moment(b.start_date)));

      setUpcomingVaccines(sortedSchedules);
      setTotalPages(Math.ceil(sortedSchedules.length / itemsPerPage));
    } catch (err) {
      setError('Aşı takvimi yüklenirken bir hata oluştu');
      toast.error('Aşı takvimi yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchVaccineSchedules();
    }
  }, [open]);

  const [tempData] = useState([
    {
      id: 1,
      petName: 'Max',
      ownerName: 'Ahmet Yılmaz',
      vaccineName: 'Kuduz Aşısı',
      vaccineDate: '2025-02-15',
      status: 'yapılmadı'
    },
    {
      id: 2,
      petName: 'Luna',
      ownerName: 'Ayşe Demir',
      vaccineName: 'Karma Aşı',
      vaccineDate: '2025-02-14',
      status: 'yapılmadı'
    },
  ]);

  const handleSendWhatsApp = (ownerName) => {
    // WhatsApp mesaj gönderme işlemi
    toast.success(`${ownerName} kişisine WhatsApp mesajı gönderildi`);
  };

  const handleSendSMS = (ownerName) => {
    // SMS gönderme işlemi
    toast.success(`${ownerName} kişisine SMS gönderildi`);
  };

  const handleCompleteVaccine = async (vaccine) => {
    if (!vaccine?.schedule_id) {
      toast.error('Geçersiz aşı kaydı');
      return;
    }
  
    try {
      setLoading(true);
      const token = localStorage.getItem('authToken');
  
      // Debug için loglar
      console.log('Güncellenecek aşı:', vaccine);
      console.log('Aşı ID:', vaccine.schedule_id);
      
      const response = await axios.put(
        `https://api.dev1.fugesoft.com/api/vaccine-schedules/${vaccine.schedule_id}`,
        { status: 'yapıldı' },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('API Response:', response.data);

      toast.success('Aşı başarıyla tamamlandı');
      await fetchVaccineSchedules(); // Listeyi yenile
    } catch (err) {
      console.error('Aşı tamamlanırken hata:', err);
      toast.error(
        err.response?.data?.message ||
        'Aşı tamamlanırken bir hata oluştu'
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle className="bg-primary text-white">
          <div>
            <h2 className="text-xl font-semibold">Yaklaşan Aşılar</h2>
            <p className="text-sm text-gray-100 mt-1">Klinik takip sistemindeki tüm aşı randevuları</p>
          </div>
        </DialogTitle>
        <DialogContent className="mt-4">
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle className="bg-primary text-white">
          <div>
            <h2 className="text-xl font-semibold">Yaklaşan Aşılar</h2>
            <p className="text-sm text-gray-100 mt-1">Klinik takip sistemindeki tüm aşı randevuları</p>
          </div>
        </DialogTitle>
        <DialogContent className="mt-4">
          <div className="flex justify-center items-center h-40 text-red-500">
            {error}
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle className="bg-primary p-6">
        <div className="flex justify-between items-start">
          <div className="text-white">
            <h2 className="text-2xl font-bold tracking-tight">
              Yaklaşan Aşılar
            </h2>
            <p className="text-gray-200 mt-2 text-sm">
              Klinik takip sistemindeki tüm aşı randevularını görüntüleyin ve yönetin
            </p>
          </div>
          <div className="flex gap-3">
            <Button
              variant="contained"
              startIcon={<FaWhatsapp />}
              disabled
              sx={{
                backgroundColor: '#25D366',
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(37, 211, 102, 0.5)',
                  color: '#fff'
                },
                '&:hover': {
                  backgroundColor: '#128C7E'
                }
              }}
            >
              Toplu WhatsApp
            </Button>
            <Button
              variant="contained"
              startIcon={<FaSms />}
              disabled
              sx={{
                backgroundColor: '#2196F3',
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(33, 150, 243, 0.5)',
                  color: '#fff'
                },
                '&:hover': {
                  backgroundColor: '#1976D2'
                }
              }}
            >
              Toplu SMS
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className="mt-4">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Hayvan Adı
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sahip
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Aşı
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tarih
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Durum
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  İşlemler
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {upcomingVaccines
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((vaccine) => (
                <tr key={vaccine.assignment_id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {vaccine.pet_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {vaccine.owner_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {vaccine.vaccine_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className={`flex flex-col ${vaccine.status === 'yapılmadı' && moment(vaccine.start_date).isBefore(moment()) ? 'text-red-600' : ''}`}>
                      <span>
                        {moment(vaccine.start_date).format('DD.MM.YYYY')}
                      </span>
                      {vaccine.next_date && (
                        <span className="text-gray-500 text-xs mt-1">
                          Sonraki: {moment(vaccine.next_date).format('DD.MM.YYYY')}
                        </span>
                      )}
                      {vaccine.status === 'yapılmadı' && moment(vaccine.start_date).isBefore(moment()) && (
                        <span className="text-xs text-red-500 mt-1 font-medium">
                          {moment(vaccine.start_date).fromNow()} geçti
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {vaccine.status === 'yapıldı' ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Yapıldı
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Yapılmadı
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap space-x-2">
                    <button
                      onClick={() => handleSendWhatsApp(vaccine.ownerName)}
                      className="text-green-600 hover:text-green-900"
                      title="WhatsApp mesajı gönder"
                    >
                      <FaWhatsapp size={20} />
                    </button>
                    <button
                      onClick={() => handleSendSMS(vaccine.ownerName)}
                      className="text-blue-600 hover:text-blue-900"
                      title="SMS gönder"
                    >
                      <FaSms size={20} />
                    </button>
                    {vaccine.status !== 'yapıldı' && (
                      <button
                        onClick={() => handleCompleteVaccine(vaccine)}
                        className="text-green-600 hover:text-green-900"
                        title="Aşıyı tamamla"
                        disabled={loading}
                      >
                        <FaCheck size={20} />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="flex justify-center mt-4 pb-4">
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="primary"
                size="large"
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpcomingVaccinesModal;
