import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  FaChevronDown, 
  FaEye, 
  FaPlus, 
  FaTrash, 
  FaSearch, 
  FaPrint,
  FaFilter,
  FaSort,
  FaWhatsapp,
  FaPaw,
  FaCat,
  FaDog,
  FaDove,
  FaHorse
} from 'react-icons/fa';
import Modal from 'react-modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import NewOwnerForm from './NewOwnerForm';
import NewPetForm from './NewPetForm';

function PatientsClinic() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedOwner, setExpandedOwner] = useState(null);
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null); // 'owner' or 'pet'
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalOwners, setTotalOwners] = useState(0);
  const [limit] = useState(50);
  const [newOwner, setNewOwner] = useState({
    name: '', phone_number: '', address: '', email: '', tc_no: '', registration_date: '', protocol_no: '', last_visit_date: ''
  });
  const [petModalIsOpen, setPetModalIsOpen] = useState(false);
  const [newPet, setNewPet] = useState({ name: '', breed: '', age: '', owner_name: '', species: '' });
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();

  const fetchOwners = useCallback(async () => {
    setIsLoading(true);
    try {
      const requestUrl = `https://api.dev1.fugesoft.com/api/owners?page=${currentPage}&limit=${limit}${searchTerm ? `&search=${searchTerm}` : ''}`;


      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      

      
      const { owners: responseOwners, total_owners, total_pages } = response.data;
      
      if (!Array.isArray(responseOwners)) {

        setOwners([]);
        return;
      }

      setOwners(responseOwners);
      setTotalOwners(total_owners);
      
      // Calculate total pages if not provided
      const calculatedTotalPages = total_pages || Math.ceil(total_owners / limit);
      setTotalPages(calculatedTotalPages);
      
      // If current page is greater than total pages, reset to page 1
      if (currentPage > calculatedTotalPages) {
        setCurrentPage(1);
      }
    } catch (error) {

      setOwners([]);
      setTotalPages(0);
      setTotalOwners(0);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, limit, searchTerm, token]);

  // Initial fetch and search term changes
  useEffect(() => {
    fetchOwners();
  }, [searchTerm, fetchOwners]);

  // Initial fetch
  useEffect(() => {
    fetchOwners();
  }, [fetchOwners]);

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // We're using server-side pagination, so we'll use the owners array directly

  const toggleExpand = (id) => setExpandedOwner(expandedOwner === id ? null : id);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setNewOwner({
      name: '', phone_number: '', address: '', email: '', tc_no: '', registration_date: '', protocol_no: '', last_visit_date: ''
    });
  };
  
  const openPetModal = () => setPetModalIsOpen(true);
  const closePetModal = () => {
    setPetModalIsOpen(false);
    setNewPet({ name: '', breed: '', age: '', owner_name: '', species: '' });
  };

  const handleAddOwner = () => {
    axios.post('https://api.dev1.fugesoft.com/api/owners', newOwner, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setOwners([...owners, { ...newOwner, _id: response.data.owner_id, pets: [], total_price: 0 }]);
        closeModal();
      })
      .catch(error => {

      });
  };

  const handleAddPet = () => {
    axios.post('https://api.dev1.fugesoft.com/api/pets', newPet, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const updatedOwners = owners.map(owner => {
          if (owner.name === newPet.owner_name) {
            return { ...owner, pets: [...owner.pets, { ...newPet, _id: response.data.pet_id }] };
          }
          return owner;
        });
        setOwners(updatedOwners);
        closePetModal();
      })
      .catch(error => {

      });
  };

  const handleDeleteOwner = (ownerId) => {
    if (window.confirm('Bu evcil hayvan sahibini silmek istediğinizden emin misiniz?')) {
      axios.delete(`https://api.dev1.fugesoft.com/api/owners/${ownerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(() => setOwners(owners.filter(owner => owner._id !== ownerId)))
        .catch(error => {});
    }
  };

  const handleRowClick = (id) => {
    toggleExpand(id);
  };

  const handleOwnerClick = (e, ownerId) => {
    e.stopPropagation(); // Row'un genişlemesini engellemek için
    navigate(`/pet-owner/${ownerId}`);
  };

  const handleWhatsAppClick = (e, phoneNumber) => {
    e.stopPropagation(); // Row'un genişlemesini engellemek için
    // Telefon numarasını string'e çevir ve null/undefined kontrolü yap
    if (!phoneNumber) return;
    
    const phoneStr = String(phoneNumber);
    // Telefon numarasından tüm boşlukları ve özel karakterleri temizle
    const cleanNumber = phoneStr.replace(/[\s()-]/g, '');
    // Eğer numara + ile başlamıyorsa başına +90 ekle
    const formattedNumber = cleanNumber.startsWith('+') ? cleanNumber : `+90${cleanNumber}`;
    window.open(`https://wa.me/${formattedNumber}`, '_blank');
  };

  const getPetIcon = (species) => {
    const speciesLower = species.toLowerCase();
    if (speciesLower.includes('kedi')) {
      return { icon: FaCat, color: 'bg-orange-100 text-orange-500' };
    } else if (speciesLower.includes('köpek')) {
      return { icon: FaDog, color: 'bg-blue-100 text-blue-500' };
    } else if (speciesLower.includes('kuş')) {
      return { icon: FaDove, color: 'bg-sky-100 text-sky-500' };
    } else if (speciesLower.includes('at')) {
      return { icon: FaHorse, color: 'bg-amber-100 text-amber-500' };
    }
    return { icon: FaPaw, color: 'bg-purple-100 text-purple-500' };
  };

  const handlePageChange = useCallback((newPage) => {

    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }, [totalPages]);

  // Effect to fetch data when page changes
  useEffect(() => {
    fetchOwners();
  }, [currentPage, fetchOwners]);

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Header Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800">Hasta Yönetimi</h2>
        <p className="text-gray-600 mt-1">Hasta ve sahip bilgilerini yönetin</p>
      </div>

      {/* Search and Actions Bar */}
      <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          {/* Search Bar */}
          <div className="relative flex-1 max-w-md">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="İsim, telefon veya email ile ara..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex gap-3">
            <button
              onClick={() => {
                setModalType('owner');
                setModalIsOpen(true);
              }}
              className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <FaPlus size={16} />
              <span>Yeni Sahip</span>
            </button>
            <button
              onClick={() => {
                setModalType('pet');
                setModalIsOpen(true);
              }}
              className="flex items-center gap-2 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FaPlus size={16} />
              <span>Yeni Hasta</span>
            </button>
            <button 
              disabled
              className="flex items-center gap-2 bg-gray-100 text-gray-400 px-4 py-2 rounded-lg cursor-not-allowed opacity-50"
            >
              <FaFilter size={16} />
              <span className="hidden md:inline">Filtrele</span>
              <span className="text-xs">(Yakında)</span>
            </button>
            <button
              disabled
              className="flex items-center gap-2 bg-gray-100 text-gray-400 px-4 py-2 rounded-lg cursor-not-allowed opacity-50"
            >
              <FaPrint size={16} />
              <span className="hidden md:inline">Yazdır</span>
              <span className="text-xs">(Yakında)</span>
            </button>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="min-w-full divide-y divide-gray-200">
          <div className="bg-gray-50">
            {isLoading ? (
              <div className="text-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
              </div>
            ) : (
              <>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="w-10 px-6 py-3 text-left"></th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sahip Bilgileri
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        İletişim
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Kayıt Bilgileri
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Hasta Sayısı
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        İşlemler
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {owners.map((owner) => (
                      <React.Fragment key={owner._id}>
                        <tr 
                          className="hover:bg-gray-50 transition-colors cursor-pointer"
                          onClick={() => handleRowClick(owner._id)}
                        >
                          <td className="px-6 py-4">
                            <button
                              className={`transition-transform duration-200 ${
                                expandedOwner === owner._id ? 'transform rotate-180' : ''
                              }`}
                            >
                              <FaChevronDown className="text-gray-500" />
                            </button>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-start">
                              <div>
                                <p 
                                  className="text-sm font-medium text-blue-600 hover:text-blue-800 cursor-pointer"
                                  onClick={(e) => handleOwnerClick(e, owner._id)}
                                >
                                  {owner.name}
                                </p>
                                <p className="text-sm text-gray-500">{owner.tc_no}</p>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="text-sm text-gray-900 flex items-center gap-2">
                              {owner.phone_number}
                              <button
                                onClick={(e) => handleWhatsAppClick(e, owner.phone_number)}
                                className="text-green-500 hover:text-green-600 transition-colors"
                                title="WhatsApp'ta mesaj gönder"
                              >
                                <FaWhatsapp size={16} />
                              </button>
                            </div>
                            <div className="text-sm text-gray-500">{owner.email}</div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="text-sm text-gray-900">#{owner.protocol_no}</div>
                            <div className="text-sm text-gray-500">
                              {new Date(owner.registration_date).toLocaleDateString('tr-TR')}
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                              {owner.pets ? owner.pets.length : 0} Hasta
                            </span>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-center gap-3">
                              <Link
                                to={`/pet-owner/${owner._id}`}
                                className="text-blue-600 hover:text-blue-800"
                                title="Detayları Görüntüle"
                              >
                                <FaEye size={18} />
                              </Link>
                              <button
                                onClick={() => handleDeleteOwner(owner._id)}
                                className="text-red-600 hover:text-red-800"
                                title="Sil"
                              >
                                <FaTrash size={16} />
                              </button>
                            </div>
                          </td>
                        </tr>

                        {/* Expanded Pet List */}
                        {expandedOwner === owner._id && owner.pets && owner.pets.length > 0 && (
                          <tr>
                            <td colSpan="6" className="px-6 py-4 bg-gray-50">
                              <div className="border rounded-lg overflow-hidden">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                                  {owner.pets.map((pet) => (
                                    <Link
                                      to={`/pet-details/${pet._id}`}
                                      key={pet._id}
                                      className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow flex items-center gap-4"
                                    >
                                      <div className={`flex-shrink-0 h-12 w-12 rounded-full flex items-center justify-center ${getPetIcon(pet.species).color}`}>
                                        {React.createElement(getPetIcon(pet.species).icon, { size: 24 })}
                                      </div>
                                      <div>
                                        <div className="font-medium text-gray-900">{pet.name}</div>
                                        <div className="text-sm text-gray-500">
                                          {pet.breed} • {pet.species}
                                        </div>
                                      </div>
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
                
                {/* Pagination */}
                <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                  <div className="flex-1 flex justify-between sm:hidden">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                        currentPage === 1 ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'
                      }`}
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                        currentPage === totalPages ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'
                      }`}
                    >
                      Next
                    </button>
                  </div>
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing{' '}
                        <span className="font-medium">{((currentPage - 1) * limit) + 1}</span>
                        {' '}-{' '}
                        <span className="font-medium">
                          {Math.min(currentPage * limit, owners.length)}
                        </span>
                        {' '}of{' '}
                        <span className="font-medium">{owners.length}</span>
                        {' '}results
                      </p>
                    </div>
                    <div>
                      <nav className="relative z-0 inline-flex items-center gap-1">
                        {/* Previous Button */}
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className={`relative inline-flex items-center px-3 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                            ${currentPage === 1
                              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                              : 'bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600 border border-gray-200'
                            }`}
                        >
                          ←
                        </button>

                        {/* Page Numbers */}
                        <div className="flex items-center gap-1">
                          <button
                            onClick={() => handlePageChange(1)}
                            className={`relative inline-flex items-center px-3 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                              ${currentPage === 1
                                ? 'bg-blue-500 text-white'
                                : 'bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600 border border-gray-200'
                              }`}
                          >
                            1
                          </button>

                          {currentPage > 3 && (
                            <span className="px-2 text-gray-500">...</span>
                          )}

                          {Array.from({ length: totalPages }, (_, i) => i + 1)
                            .filter(page => page !== 1 && page !== totalPages && Math.abs(currentPage - page) <= 1)
                            .map(page => (
                              <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                className={`relative inline-flex items-center px-3 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                                  ${currentPage === page
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600 border border-gray-200'
                                  }`}
                              >
                                {page}
                              </button>
                            ))}

                          {currentPage < totalPages - 2 && (
                            <span className="px-2 text-gray-500">...</span>
                          )}

                          {totalPages > 1 && (
                            <button
                              onClick={() => handlePageChange(totalPages)}
                              className={`relative inline-flex items-center px-3 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                                ${currentPage === totalPages
                                  ? 'bg-blue-500 text-white'
                                  : 'bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600 border border-gray-200'
                                }`}
                            >
                              {totalPages}
                            </button>
                          )}
                        </div>

                        {/* Next Button */}
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                          className={`relative inline-flex items-center px-3 py-2 rounded-lg text-sm font-medium transition-colors duration-200
                            ${currentPage === totalPages
                              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                              : 'bg-white text-gray-700 hover:bg-blue-50 hover:text-blue-600 border border-gray-200'
                            }`}
                        >
                          →
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Form Modals */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setModalType(null);
          setSelectedOwner(null);
        }}
        className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-y-auto max-h-[90vh]"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
      >
        {modalType === 'owner' && (
          <NewOwnerForm 
            onClose={() => {
              setModalIsOpen(false);
              setModalType(null);
            }}
            onSuccess={() => {
              fetchOwners();
              setModalIsOpen(false);
              setModalType(null);
              toast.success('Hasta sahibi başarıyla eklendi');
            }}
          />
        )}
        {modalType === 'pet' && (
          <NewPetForm
            onClose={() => {
              setModalIsOpen(false);
              setModalType(null);
              setSelectedOwner(null);
            }}
            onSuccess={() => {
              fetchOwners();
              setModalIsOpen(false);
              setModalType(null);
              setSelectedOwner(null);
              toast.success('Hasta başarıyla eklendi');
            }}

          />
        )}
      </Modal>
    </div>
  );
}

export default PatientsClinic;
