import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";
import { components } from "react-select";
import axios from "axios";
import moment from 'moment';

function AppointmentModal({
  isOpen,
  onRequestClose,
  newEvent,
  setNewEvent,
  handleAddEvent,
}) {
  const [loading, setLoading] = useState(false);
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedVet, setSelectedVet] = useState(null);
  const token = localStorage.getItem("authToken");

  // Form state
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    date: moment().format('YYYY-MM-DD'),
    startTime: '',
    duration: '30' // Default 30 dakika
  });

  const durationOptions = [
    { value: '15', label: '15 Dakika' },
    { value: '30', label: '30 Dakika' },
    { value: '45', label: '45 Dakika' },
    { value: '60', label: '1 Saat' },
    { value: '90', label: '1.5 Saat' },
    { value: '120', label: '2 Saat' },
  ];

  // Reset form when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      loadData();
      setFormState({
        title: newEvent.title || '',
        description: newEvent.description || '',
        date: newEvent.date || moment().format('YYYY-MM-DD'),
        startTime: newEvent.startTime || '',
        duration: '30' // Default 30 dakika
      });
    }
  }, [isOpen, newEvent]);

  const loadData = async () => {
    console.log('LoadData başladı:', new Date().toISOString());
    setLoading(true);

    // Önce cache'den kontrol et
    const cachedData = localStorage.getItem('appointmentModalData');
    const cacheTimestamp = localStorage.getItem('appointmentModalDataTimestamp');
    const CACHE_DURATION = 5 * 60 * 1000; // 5 dakika

    if (cachedData && cacheTimestamp) {
      const isExpired = Date.now() - parseInt(cacheTimestamp) > CACHE_DURATION;
      console.log('Cache durumu:', { isExpired, cacheAge: (Date.now() - parseInt(cacheTimestamp)) / 1000 + ' saniye' });
      
      if (!isExpired) {
        console.log('Cache kullanılıyor');
        const { petOptions, usersData } = JSON.parse(cachedData);
        setPets(petOptions);
        setUsers(usersData);
        setLoading(false);
        return;
      }
    }

    console.log('Cache yok veya süresi geçmiş, yeni veri çekiliyor');
    await fetchData();
  };

  const fetchData = async () => {
    console.log('FetchData başladı:', new Date().toISOString());
    try {
      console.time('API İstekleri');
      // Fetch users (veterinarians)
      const usersResponse = await axios.get("https://api.dev1.fugesoft.com/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Fetch owners and their pets
      const ownersResponse = await axios.get("https://api.dev1.fugesoft.com/api/owners/all", {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.timeEnd('API İstekleri');
      console.log('Kayıt sayıları:', {
        users: usersResponse.data?.users?.length || 0,
        owners: ownersResponse.data?.length || 0
      });
      
      console.time('Veri İşleme');
      // Veteriner verilerini işle - sadece veteriner rolündeki kullanıcıları al
      const formattedUsers = (usersResponse.data?.users || [])
        .filter(user => user.role === 'vet' || user.role === 'admin')
        .map(user => ({
          value: user.id,
          label: user.name && user.surname 
            ? `${user.name} ${user.surname}` 
            : user.username,
          color: user.color || 'blue',
          id: user.id,
          name: user.name,
          surname: user.surname,
          username: user.username,
          role: user.role
        }));
      
      setUsers(formattedUsers);

      // Sahipleri alfabetik sırala
      const sortedOwners = ownersResponse.data.sort((a, b) => a.name.localeCompare(b.name));

      const petOptions = sortedOwners
        .filter(owner => owner.pets && owner.pets.length > 0)
        .flatMap(owner => 
          owner.pets.map(pet => ({
            value: pet.id,
            label: `${owner.name} - ${pet.name}${owner.protocol_no ? ` (${owner.protocol_no})` : ''}`,
            ownerName: owner.name,
            petName: pet.name,
            petId: pet.id,
            owner: {
              id: owner._id,
              name: owner.name,
              protocol_no: owner.protocol_no,
              email: owner.email || '',
              address: owner.address || ''
            }
          }))
        );

      setPets(petOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Veri yüklenirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const handlePetSelection = (selectedOption) => {
    setSelectedPet(selectedOption);
    if (selectedOption) {
      setNewEvent(prev => ({
        ...prev,
        petId: selectedOption.petId,
        owner_name: selectedOption.owner.name,
        owner_address: selectedOption.owner.address || '',
        owner_phone: selectedOption.owner.phone || '',
        owner_email: selectedOption.owner.email || '',
        pet_name: selectedOption.petName,
        pet_species: '',
        pet_breed: '',
        pet_age: '',
        title: `${selectedOption.owner.name} - Randevu`
      }));
    }
  };

  const handleVetSelection = (selectedOption) => {
    console.log('=== Veteriner Seçim Detayları ===');
    console.log('Seçilen Veteriner:', selectedOption);
    console.log('Veteriner ID:', selectedOption?.id);
    console.log('Veteriner Value:', selectedOption?.value);
    console.log('Veteriner Adı:', selectedOption?.label);
    
    setSelectedVet(selectedOption);
    if (selectedOption) {
      const assignedToId = selectedOption.id || selectedOption.value;
      console.log('Atanacak ID:', assignedToId);
      
      setNewEvent(prev => ({
        ...prev,
        assignedTo: assignedToId,
        veterinarian: selectedOption.label,
        color: selectedOption.color || 'blue'
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedPet) {
      alert('Lütfen bir hasta seçin');
      return;
    }

    if (!selectedVet) {
      alert('Lütfen bir veteriner seçin');
      return;
    }

    if (!formState.date || !formState.startTime) {
      alert('Lütfen tarih ve saat seçin');
      return;
    }

    try {
      const assignedToId = selectedVet.id || selectedVet.value;
      console.log('=== Randevu Oluşturma Detayları ===');
      console.log('Seçili Veteriner:', selectedVet);
      console.log('Atanan ID:', assignedToId);

      if (!assignedToId) {
        console.error('Veteriner ID bulunamadı:', selectedVet);
        alert('Veteriner seçiminde bir hata oluştu');
        return;
      }

      const userId = localStorage.getItem('user_id');
      console.log('Current User ID:', userId);

      if (!userId) {
        console.error('User ID bulunamadı. Lütfen tekrar giriş yapın.');
        alert('Oturum bilgileriniz eksik. Lütfen tekrar giriş yapın.');
        return;
      }

      // Postman'deki başarılı formata uygun veri yapısı
      // Bitiş saatini hesapla
      const startMoment = moment(formState.date + ' ' + formState.startTime);
      const endMoment = startMoment.clone().add(parseInt(formState.duration), 'minutes');
      const endTime = endMoment.format('HH:mm');

      const appointmentData = {
        petId: selectedPet.petId,
        userId: "string", // Postman'de bu şekilde
        assignedTo: assignedToId,
        owner_name: selectedPet.owner.name,
        owner_address: "string", // Postman'de bu şekilde
        owner_phone: "string", // Postman'de bu şekilde
        owner_email: "string",
        pet_name: selectedPet.petName,
        pet_species: "string", // Postman'de bu şekilde
        pet_breed: "string", // Postman'de bu şekilde
        pet_age: 0, // Postman'de sayı olarak
        description: formState.description || "test",
        title: "string", // Postman'de bu şekilde
        date: formState.date,
        time: formState.startTime,
        endTime: endTime, // Hesaplanan bitiş saati
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        color: selectedVet.color || "red",
        appointment_type: "string",
        appointment_package: "string",
        department: "string",
        veterinarian: "string", // Postman'de bu şekilde
        notes: "string",
        status: "string",
        protocol_number: "string",
        card_number: "string"
      };

      console.log('API\'ye Gönderilen Veri:', appointmentData);
      
      await handleAddEvent(appointmentData);
      
      // Clear form
      setFormState({
        title: '',
        description: '',
        date: moment().format('YYYY-MM-DD'),
        startTime: '',
        duration: '30'
      });
      setSelectedPet(null);
      setSelectedVet(null);
      
      onRequestClose();
      
    } catch (error) {
      console.error('Error submitting appointment:', error);
      alert('Randevu oluşturulurken bir hata oluştu');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <h2 className="text-xl font-bold mb-4">Yeni Randevu Ekle</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Hasta
          </label>
          <WindowedSelect
            options={pets}
            value={selectedPet}
            onChange={handlePetSelection}
            isLoading={loading}
            placeholder="Hasta sahibi veya hasta adı ile ara..."
            className="text-sm"
            windowThreshold={100}
            components={{
              MenuList: components.MenuList
            }}
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: loading ? '#f3f4f6' : 'white',
                minHeight: '42px'
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999
              }),
              option: (base) => ({
                ...base,
                padding: '8px 12px'
              })
            }}
            formatOptionLabel={({ ownerName, petName, owner }) => (
              <div className="flex flex-col">
                <span className="font-medium">{petName}</span>
                <span className="text-gray-500 text-sm">
                  {ownerName} {owner.protocol_no ? `(${owner.protocol_no})` : ''}
                </span>
              </div>
            )}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Veteriner
          </label>
          <WindowedSelect
            options={users}
            value={selectedVet}
            onChange={handleVetSelection}
            isLoading={loading}
            placeholder="Veteriner seçin..."
            className="text-sm"
            windowThreshold={100}
            components={{
              MenuList: components.MenuList
            }}
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: loading ? '#f3f4f6' : 'white',
                minHeight: '42px'
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999
              }),
              option: (base) => ({
                ...base,
                padding: '8px 12px'
              })
            }}
            formatOptionLabel={({ name, surname, username }) => (
              <div className="flex flex-col">
                {name && surname ? (
                  <>
                    <span className="font-medium">{`${name} ${surname}`}</span>
                    <span className="text-gray-500 text-sm">{username}</span>
                  </>
                ) : (
                  <span className="font-medium">{username}</span>
                )}
              </div>
            )}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Başlık
          </label>
          <input
            type="text"
            name="title"
            value={formState.title}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Açıklama
          </label>
          <textarea
            name="description"
            value={formState.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            rows="3"
          />
        </div>

        <div className="grid grid-cols-3 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Tarih
            </label>
            <input
              type="date"
              name="date"
              value={formState.date}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Başlangıç Saati
            </label>
            <input
              type="time"
              name="startTime"
              value={formState.startTime}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Randevu Süresi
            </label>
            <Select
              options={durationOptions}
              value={durationOptions.find(opt => opt.value === formState.duration)}
              onChange={(selected) => setFormState(prev => ({ ...prev, duration: selected.value }))}
              className="text-sm"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '42px'
                }),
                option: (base) => ({
                  ...base,
                  padding: '8px 12px'
                })
              }}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onRequestClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            İptal
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Kaydet
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AppointmentModal;
