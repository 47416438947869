import React from 'react';
import { FaSmile, FaAngry } from 'react-icons/fa';
import { toast } from 'react-toastify';

const NotesTab = ({ 
  newNote, 
  setNewNote, 
  behaviorNote, 
  setBehaviorNote, 
  mockBehavior 
}) => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-bold mb-4">Veteriner Notları</h3>
        <textarea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Yeni not ekle..."
          className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          rows="3"
        />
        <button
          onClick={() => {
            toast.success('Not eklendi');
            setNewNote('');
          }}
          className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Not Ekle
        </button>
      </div>

      <div>
        <h3 className="text-xl font-bold mb-4">Mizaç ve Davranış</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="bg-green-50 p-4 rounded-lg flex items-center">
            <FaSmile className="text-green-500 mr-2" />
            <div>
              <p className="font-semibold">Mizaç</p>
              <p className="text-sm text-gray-600">{mockBehavior.temperament}</p>
            </div>
          </div>
          <div className="bg-red-50 p-4 rounded-lg flex items-center">
            <FaAngry className="text-red-500 mr-2" />
            <div>
              <p className="font-semibold">Saldırganlık Seviyesi</p>
              <p className="text-sm text-gray-600">{mockBehavior.aggressionLevel}</p>
            </div>
          </div>
        </div>
        <textarea
          value={behaviorNote}
          onChange={(e) => setBehaviorNote(e.target.value)}
          placeholder="Davranış notu ekle..."
          className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          rows="3"
        />
        <button
          onClick={() => {
            toast.success('Davranış notu eklendi');
            setBehaviorNote('');
          }}
          className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Davranış Notu Ekle
        </button>
      </div>

      {/* Previous Notes Display */}
      <div className="space-y-4">
        <h3 className="text-xl font-bold mb-4">Önceki Notlar</h3>
        {mockBehavior.notes.map((note, index) => (
          <div
            key={note.id}
            className={`p-4 rounded-lg ${
              note.mood === 'positive'
                ? 'bg-green-50'
                : note.mood === 'neutral'
                ? 'bg-gray-50'
                : 'bg-red-50'
            }`}
          >
            <p className="text-gray-800">{note.note}</p>
            <p className="text-sm text-gray-500 mt-1">{note.date}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotesTab;
