import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Modal from "react-modal";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarScreen.css";
import AppointmentModal from "../../../components/AppointmentModal";
import Select from "react-select";

const localizer = momentLocalizer(moment);

// Configure moment to use local timezone
moment.locale('tr');

Modal.setAppElement("#root");

// Pastel renk stilleri
const styles = {
  blue: {
    backgroundColor: 'rgba(59, 130, 246, 0.15)',
    color: '#1e40af',
    borderLeft: '3px solid #3B82F6',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  green: {
    backgroundColor: 'rgba(16, 185, 129, 0.15)',
    color: '#065f46',
    borderLeft: '3px solid #10B981',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  red: {
    backgroundColor: 'rgba(239, 68, 68, 0.15)',
    color: '#991b1b',
    borderLeft: '3px solid #EF4444',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  purple: {
    backgroundColor: 'rgba(139, 92, 246, 0.15)',
    color: '#5b21b6',
    borderLeft: '3px solid #8B5CF6',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  orange: {
    backgroundColor: 'rgba(249, 115, 22, 0.15)',
    color: '#9a3412',
    borderLeft: '3px solid #F97316',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  }
};

function CalendarScreen() {
  const [events, setEvents] = useState([]);
  const [pets, setPets] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    petId: "",
    userId: "",
    assignedTo: "",
    owner_name: "",
    owner_address: "",
    owner_phone: "",
    owner_email: "",
    pet_name: "",
    pet_species: "",
    pet_breed: "",
    pet_age: 0,
    description: "",
    appointment_type: "",
    appointment_package: "",
    department: "Klinik",
    veterinarian: "",
    notes: "",
    status: "Yapılacak",
    protocol_number: "",
    card_number: "",
    date: "",
    startTime: "",
    endTime: "",
    time: "",
    color: "blue"
  });
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem("authToken");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentView, setCurrentView] = useState('month');
  const [currentDate, setCurrentDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let filtered = [...events];

    if (selectedColor && selectedColor.value !== 'all') {
      filtered = filtered.filter(event => event.color === selectedColor.value);
    }

    if (selectedUser) {
      filtered = filtered.filter(event => event.assignedTo === selectedUser.label.split(' - ')[0]);
    }

    setFilteredEvents(filtered);
  }, [events, selectedColor, selectedUser]);

  const fetchAppointments = async (date = currentDate, view = currentView) => {
    try {
      setLoading(true);
      
      // Calculate date range based on view type
      let startDate, endDate;
      
      switch(view) {
        case 'month':
          startDate = date.clone().startOf('month').format('YYYY-MM-DD');
          endDate = date.clone().endOf('month').format('YYYY-MM-DD');
          break;
        case 'week':
          startDate = date.clone().startOf('week').format('YYYY-MM-DD');
          endDate = date.clone().endOf('week').format('YYYY-MM-DD');
          break;
        case 'day':
          // Günlük görünüm için bir gün öncesi ve sonrasını da al
          startDate = date.clone().subtract(1, 'day').format('YYYY-MM-DD');
          endDate = date.clone().add(1, 'day').format('YYYY-MM-DD');
          break;
        default:
          startDate = date.clone().startOf('month').format('YYYY-MM-DD');
          endDate = date.clone().endOf('month').format('YYYY-MM-DD');
      }

      // API çağrısı query string ile
      const response = await axios.get(
        `https://api.dev1.fugesoft.com/api/appointments?start_date=${startDate}&end_date=${endDate}&page=1&limit=999999990`,
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        }
      );

      const { appointments } = response.data;

      if (!appointments || !Array.isArray(appointments)) {
        setEvents([]);
        setLoading(false);
        return;
      }

      const formattedEvents = appointments
        .map((item) => {
          const dateStr = item.date;
          const timeStr = item.time;
          
          if (!dateStr || !timeStr) {
            return null;
          }

          let [startTime] = timeStr.split(' - ');
          if (!startTime) {
            return null;
          }

          const start = moment(`${dateStr} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
          if (!start.isValid()) {
            return null;
          }

          const end = moment(start).add(1, 'hour');

          // Günlük görünümde sadece seçili güne ait randevuları göster
          if (view === 'day') {
            const appointmentDate = moment(dateStr).format('YYYY-MM-DD');
            const selectedDate = date.format('YYYY-MM-DD');
            if (appointmentDate !== selectedDate) {
              return null;
            }
          }

          return {
            id: item._id,
            title: `${item.owner_name} (${item.pet_name || 'İsimsiz'})`,
            description: item.appointment_package || "Detay yok",
            start: start.toDate(),
            end: end.toDate(),
            color: item.color || "blue",
            assignedTo: item.assignedTo || "",
            status: item.status || "Yapılacak",
            owner_name: item.owner_name,
            pet_name: item.pet_name,
            appointment_package: item.appointment_package,
          };
        })
        .filter(Boolean); // null değerleri filtrele

      setEvents(formattedEvents);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setLoading(false);
    }
  };

  const fetchPets = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/owners", {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Extract all pets from owners
      const allPets = response.data.owners?.reduce((acc, owner) => {
        if (owner.pets && Array.isArray(owner.pets)) {
          return [...acc, ...owner.pets.map(pet => ({
            ...pet,
            owner_name: owner.name
          }))];
        }
        return acc;
      }, []) || [];

      setPets(allPets);
    } catch (error) {
      console.error("Error fetching pets:", error);
      setErrorMessage('Evcil hayvan listesi yüklenirken bir hata oluştu');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/owners", {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setUsers(response.data.owners || []);
    } catch (error) {
      console.error("Error fetching users:", error);
      setErrorMessage('Kullanıcı listesi yüklenirken bir hata oluştu');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleAddEvent = async (appointmentData) => {
    try {
      console.log('Creating appointment with data:', appointmentData);
      const response = await axios.post(
        'https://api.dev1.fugesoft.com/api/appointments',
        appointmentData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Appointment creation response:', response.data);

      if (response.data) {
        alert('Randevu başarıyla oluşturuldu');
        await fetchAppointments(); // Refresh events after adding new one
        setModalIsOpen(false);
      }
    } catch (error) {
      console.error('API Error Details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
        requestData: JSON.parse(error.config?.data || '{}')
      });

      // API'den gelen hata mesajını detaylı göster
      let errorMessage = 'Randevu oluşturulurken hata: ';
      
      if (error.response?.data?.message) {
        errorMessage += error.response.data.message;
      } else if (error.response?.data?.error) {
        errorMessage += error.response.data.error;
      } else {
        errorMessage += error.message;
      }

      alert(errorMessage);

      // API'den gelen validation hatalarını göster
      if (error.response?.data?.errors) {
        console.error('Validation Errors:', error.response.data.errors);
        const validationErrors = Object.entries(error.response.data.errors)
          .map(([field, message]) => `${field}: ${message}`)
          .join('\n');
        
        if (validationErrors) {
          alert('Doğrulama Hataları:\n' + validationErrors);
        }
      }

      // Request payload'ı kontrol et
      try {
        const sentData = JSON.parse(error.config?.data || '{}');
        console.log('API\'ye gönderilen veri:', sentData);
        
        // Eksik veya hatalı alanları kontrol et
        const requiredFields = ['petId', 'user_id', 'assignedTo', 'date', 'time'];
        const missingFields = requiredFields.filter(field => !sentData[field]);
        
        if (missingFields.length > 0) {
          console.error('Eksik alanlar:', missingFields);
          alert('Eksik alanlar: ' + missingFields.join(', '));
        }
      } catch (e) {
        console.error('Request data parsing error:', e);
      }
    }
  };

  const handleEventSave = async (eventData) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/appointments",
        eventData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        fetchAppointments(currentDate, currentView);
        setModalIsOpen(false);
        setErrorMessage('Randevu başarıyla kaydedildi');
        setTimeout(() => setErrorMessage(''), 3000);
      }
    } catch (error) {
      console.error("Error saving appointment:", error);
      setErrorMessage('Randevu kaydedilirken bir hata oluştu');
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleEventDelete = async (eventId) => {
    try {
      setLoading(true);
      await axios.delete(
        `https://api.dev1.fugesoft.com/api/appointments/${eventId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      fetchAppointments(currentDate, currentView);
      setDetailsModalIsOpen(false);
      setErrorMessage('Randevu başarıyla silindi');
      setTimeout(() => setErrorMessage(''), 3000);
    } catch (error) {
      console.error("Error deleting appointment:", error);
      setErrorMessage('Randevu silinirken bir hata oluştu');
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (slotInfo) => {
    const date = moment(slotInfo.start).format("YYYY-MM-DD");
    const startTime = moment(slotInfo.start).format("HH:mm");
    const endTime = moment(slotInfo.end).format("HH:mm");
    setNewEvent({
      ...newEvent,
      date,
      startTime,
      endTime,
      time: startTime, // Keep this for API compatibility
    });
    setModalIsOpen(true);
  };

  const closeModal = () => setModalIsOpen(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setDetailsModalIsOpen(true);
  };

  const closeDetailsModal = () => setDetailsModalIsOpen(false);

  const colorOptions = [
    { value: 'all', label: 'Hepsi', color: '#6B7280' },
    { value: 'blue', label: 'Mavi', color: '#3B82F6' },
    { value: 'green', label: 'Yeşil', color: '#10B981' },
    { value: 'red', label: 'Kırmızı', color: '#EF4444' },
    { value: 'purple', label: 'Mor', color: '#8B5CF6' },
    { value: 'orange', label: 'Turuncu', color: '#F97316' }
  ];

  const handleClearFilters = () => {
    setSelectedColor(null);
    setSelectedUser(null);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
    fetchAppointments(currentDate, view);
  };

  const handleNavigate = (newDate) => {
    const momentDate = moment(newDate);
    setCurrentDate(momentDate);
    fetchAppointments(momentDate, currentView);
  };

  // Event stil yönetimi için yeni fonksiyon
  const eventPropGetter = (event) => {
    const style = styles[event.color] || styles.blue;
    return { style };
  };

  // Initial fetch
  useEffect(() => {
    fetchAppointments();
    fetchPets();
    fetchUsers();
  }, []);

  return (
    <div className="p-2">
      <div className="calendar-container p-4">
        {/* Error Message Display */}
        {errorMessage && (
          <div className={`error-message ${errorMessage.includes('başarıyla') ? 'success' : 'error'}`}>
            {errorMessage}
          </div>
        )}
        
        {loading && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}
        <div className="flex justify-between items-center">
  {/* Header Section */}
  <div className="mb-4">
        <h2 className="text-2xl font-bold text-gray-800">Randevu Takvimi</h2>
      </div>          
          {/* Filtreler */}
          <div className="flex items-center gap-4 bg-white p-3 rounded-lg shadow-sm">
            <div className="w-48">
              <Select
                value={selectedColor}
                onChange={setSelectedColor}
                options={colorOptions}
                isClearable
                placeholder="Renk"
                formatOptionLabel={option => (
                  <div className="flex items-center">
                    <div
                      className="w-4 h-4 rounded-full mr-2"
                      style={{ backgroundColor: option.color }}
                    />
                    {option.label}
                  </div>
                )}
              />
            </div>
            <div className="w-48">
              <Select
                value={selectedUser}
                onChange={setSelectedUser}
                options={users}
                isClearable
                placeholder="Çalışan"
              />
            </div>
            {(selectedColor || selectedUser) && (
              <button
                onClick={handleClearFilters}
                className="px-3 py-2 text-sm text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded"
              >
                Filtreleri Temizle
              </button>
            )}
          </div>
        </div>

        <Calendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 'calc(100vh - 200px)' }}
          onSelectEvent={event => {
            setSelectedEvent(event);
            setDetailsModalIsOpen(true);
          }}
          onSelectSlot={slotInfo => {
            openModal(slotInfo);
          }}
          selectable
          eventPropGetter={eventPropGetter}
          defaultView={currentView}
          views={["month", "week", "day"]}
          toolbar={true}
          popup={true}
          step={30}
          timeslots={2}
          min={new Date(0, 0, 0, 7, 0, 0)} // Gün başlangıcı 07:00
          max={new Date(0, 0, 0, 21, 0, 0)} // Gün bitişi 21:00
          scrollToTime={new Date(0, 0, 0, 7, 0, 0)}
          formats={{
            timeGutterFormat: 'HH:mm',
            eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
              `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`,
          }}
          onView={handleViewChange}
          onNavigate={handleNavigate}
          messages={{
            next: "İleri",
            previous: "Geri",
            today: "Bugün",
            month: "Ay",
            week: "Hafta",
            day: "Gün",
            noEventsInRange: "Bu aralıkta randevu bulunmamaktadır."
          }}
        />
        <AppointmentModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          pets={pets}
          users={users}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
          handleAddEvent={handleAddEvent}
        />
        {/* Detay Modalı */}
        {selectedEvent && (
          <Modal
            isOpen={detailsModalIsOpen}
            onRequestClose={closeDetailsModal}
            className="modal-content"
            overlayClassName="modal-overlay"
          >
            <div className="bg-white rounded-lg p-6 max-w-2xl mx-auto">
              {/* Header */}
              <div className="flex justify-between items-start mb-8">
                <div>
                  <h2 className="text-2xl font-bold text-gray-800 mb-2">{selectedEvent.title}</h2>
                  <div className="flex items-center text-gray-600">
                    <span className={`w-3 h-3 rounded-full mr-2`} style={{ backgroundColor: styles[selectedEvent.color]?.color || '#3B82F6' }}></span>
                    <span className="text-sm">{colorOptions.find(opt => opt.value === selectedEvent.color)?.label || 'Mavi'}</span>
                  </div>
                </div>
                <button
                  onClick={closeDetailsModal}
                  className="text-gray-400 hover:text-gray-600 transition-colors"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Content */}
              <div className="space-y-8">
                {/* Hasta Bilgileri */}
                {selectedEvent.pet_name && (
                  <div className="border-b pb-6">
                    <div className="flex items-center text-gray-800 mb-4">
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                      <span className="font-medium text-lg">Hasta Bilgileri</span>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="text-gray-600">
                        <span className="font-medium">Hasta Adı:</span>
                        <span className="ml-2">{selectedEvent.pet_name}</span>
                      </div>
                      {selectedEvent.owner_name && (
                        <div className="text-gray-600">
                          <span className="font-medium">Sahip:</span>
                          <span className="ml-2">{selectedEvent.owner_name}</span>
                        </div>
                      )}
                      {selectedEvent.pet_species && (
                        <div className="text-gray-600">
                          <span className="font-medium">Tür:</span>
                          <span className="ml-2">{selectedEvent.pet_species}</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Randevu Detayları */}
                <div className="border-b pb-6">
                  <div className="flex items-center text-gray-800 mb-4">
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <span className="font-medium text-lg">Randevu Detayları</span>
                  </div>
                  <div className="space-y-3">
                    {selectedEvent.assignedTo && (
                      <div className="text-gray-600">
                        <span className="font-medium">Atanan Kişi:</span>
                        <span className="ml-2">{selectedEvent.assignedTo}</span>
                      </div>
                    )}
                    {selectedEvent.description && (
                      <div className="text-gray-600">
                        <span className="font-medium block mb-1">Açıklama:</span>
                        <p className="text-gray-600">{selectedEvent.description}</p>
                      </div>
                    )}
                    {selectedEvent.status && (
                      <div className="text-gray-600">
                        <span className="font-medium">Durum:</span>
                        <span className={`ml-2 px-2 py-1 rounded-full text-sm ${selectedEvent.status === 'Yapılacak' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'}`}>
                          {selectedEvent.status}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Zaman Bilgisi */}
                <div>
                  <div className="flex items-center text-gray-800 mb-4">
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="font-medium text-lg">Randevu Zamanı</span>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="text-gray-600">
                      <span className="font-medium">Başlangıç:</span>
                      <div className="mt-1">
                        <span>{moment(selectedEvent.start).format("DD MMMM YYYY, dddd")}</span>
                        <span className="ml-2 text-blue-600 font-medium">{moment(selectedEvent.start).format("HH:mm")}</span>
                      </div>
                    </div>
                    <div className="text-gray-600">
                      <span className="font-medium">Bitiş:</span>
                      <div className="mt-1">
                        <span>{moment(selectedEvent.end).format("DD MMMM YYYY, dddd")}</span>
                        <span className="ml-2 text-blue-600 font-medium">{moment(selectedEvent.end).format("HH:mm")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="mt-8 flex justify-end space-x-3">
                <button
                  onClick={() => handleEventDelete(selectedEvent.id)}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                >
                  Randevuyu Sil
                </button>
                <button
                  onClick={closeDetailsModal}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Kapat
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default CalendarScreen;
