import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import TreatmentModal from '../../dashboardScreen/actionButtons/ActionButtonModals/TreatmentModal';
import { FaPlus, FaSort, FaSpinner } from 'react-icons/fa';

Modal.setAppElement('#root');

function TreatmentHistoryModal({ isOpen, onRequestClose, petId }) {
  const [treatments, setTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [treatmentModalOpen, setTreatmentModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' or 'desc'

  useEffect(() => {
    if (isOpen && petId) {
      fetchTreatments();
    }
  }, [isOpen, petId, sortOrder]);

  const fetchTreatments = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`https://api.dev1.fugesoft.com/api/treatments/pet/${petId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Fetched treatments:', response.data); // Debug için
      // Sort treatments by date
      const sortedTreatments = response.data.sort((a, b) => {
        const dateA = new Date(a.treatment_date);
        const dateB = new Date(b.treatment_date);
        return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
      });

      setTreatments(sortedTreatments);
    } catch (error) {
      console.error("Error fetching treatments:", error);
      setError("Muayene geçmişi yüklenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const handleNewTreatment = () => {
    setTreatmentModalOpen(true);
  };

  const handleTreatmentModalClose = () => {
    setTreatmentModalOpen(false);
    fetchTreatments();
  };

  const toggleSortOrder = () => {
    setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc');
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('tr-TR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxHeight: '90vh',
          padding: '24px',
          borderRadius: '8px'
        },
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)' }
      }}
    >
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Muayene Geçmişi</h2>
        <div className="flex items-center gap-4">
          <button
            onClick={handleNewTreatment}
            className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-green-600 transition-colors"
          >
            <FaPlus />
            Yeni Muayene
          </button>
          <button 
            onClick={onRequestClose} 
            className="text-gray-500 hover:text-gray-700 text-xl font-semibold"
          >
            ×
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
        </div>
      ) : (
        <div className="flex h-full gap-6">
          {/* Treatment List */}
          <div className="w-2/5">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b flex justify-between items-center">
                <h3 className="font-semibold text-gray-700">Muayene Listesi</h3>
                <button
                  onClick={toggleSortOrder}
                  className="text-gray-500 hover:text-gray-700 flex items-center gap-1"
                >
                  <FaSort />
                  {sortOrder === 'desc' ? 'En Yeni' : 'En Eski'}
                </button>
              </div>
              <div className="overflow-auto max-h-[60vh]">
                {treatments.length === 0 ? (
                  <div className="text-center py-8 text-gray-500">
                    Henüz muayene kaydı bulunmamaktadır.
                  </div>
                ) : (
                  <table className="min-w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Tarih
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Veteriner
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Teşhis
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {treatments.map((treatment) => (
                        <tr
                          key={treatment._id}
                          onClick={() => setSelectedTreatment(treatment)}
                          className={`cursor-pointer transition-colors ${
                            selectedTreatment?._id === treatment._id 
                              ? 'bg-blue-50 hover:bg-blue-100' 
                              : 'hover:bg-gray-50'
                          }`}
                        >
                          <td className="px-4 py-3 whitespace-nowrap text-sm">
                            {formatDate(treatment.treatment_date)}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {treatment.veterinarian || 'Belirtilmemiş'}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {treatment.pre_diagnosis || 'Belirtilmemiş'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          {/* Treatment Details */}
          <div className="w-3/5">
            <div className="bg-white rounded-lg shadow p-6">
              {selectedTreatment ? (
                <div className="space-y-6">
                  <div className="flex justify-between items-center border-b pb-4">
                    <h3 className="text-xl font-semibold text-gray-800">
                      Muayene Detayları
                    </h3>
                    <div className="text-sm text-gray-500">
                      {formatDate(selectedTreatment.treatment_date)}
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 gap-6">
                    {/* Temel Bilgiler */}
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h4 className="font-semibold text-gray-700 mb-4 flex items-center">
                        <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                        Temel Bilgiler
                      </h4>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Veteriner Hekim</p>
                          <p className="font-medium">{selectedTreatment.veterinarian || 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Tedavi Türü</p>
                          <p className="font-medium">{selectedTreatment.treatment_type || 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm col-span-2">
                          <p className="text-sm text-gray-500 mb-1">Ön Teşhis</p>
                          <p className="font-medium">{selectedTreatment.pre_diagnosis || 'Belirtilmemiş'}</p>
                        </div>
                      </div>
                    </div>

                    {/* Şikayet ve Bulgular */}
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h4 className="font-semibold text-gray-700 mb-4 flex items-center">
                        <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                        Şikayet ve Bulgular
                      </h4>
                      <div className="grid grid-cols-1 gap-4">
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Semptomlar</p>
                          <p className="font-medium">{selectedTreatment.symptoms || 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Anamnez</p>
                          <p className="font-medium">{selectedTreatment.anamnez || 'Belirtilmemiş'}</p>
                        </div>
                      </div>
                    </div>

                    {/* Fiziksel Muayene */}
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h4 className="font-semibold text-gray-700 mb-4 flex items-center">
                        <span className="w-2 h-2 bg-purple-500 rounded-full mr-2"></span>
                        Fiziksel Muayene
                      </h4>
                      <div className="grid grid-cols-3 gap-4">
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Kilo</p>
                          <p className="font-medium">{selectedTreatment.weight ? `${selectedTreatment.weight} kg` : 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Vücut Sıcaklığı</p>
                          <p className="font-medium">{selectedTreatment.body_temperature ? `${selectedTreatment.body_temperature}°C` : 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Genel Durum</p>
                          <p className="font-medium">{selectedTreatment.general_condition || 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Solunum Hızı</p>
                          <p className="font-medium">{selectedTreatment.Solunum_rate ? `${selectedTreatment.Solunum_rate}/dk` : 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Nabız</p>
                          <p className="font-medium">{selectedTreatment.pulse ? `${selectedTreatment.pulse}/dk` : 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">KDZ</p>
                          <p className="font-medium">{selectedTreatment.capillary_refill_time ? `${selectedTreatment.capillary_refill_time} sn` : 'Belirtilmemiş'}</p>
                        </div>
                      </div>
                    </div>

                    {/* Detaylı Muayene */}
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h4 className="font-semibold text-gray-700 mb-4 flex items-center">
                        <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
                        Detaylı Muayene
                      </h4>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Lenf Nodülleri</p>
                          <p className="font-medium">{selectedTreatment.lymph_nodes || 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Mukoz Membranlar</p>
                          <p className="font-medium">{selectedTreatment.mucous_membranes || 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Tüy Yapısı</p>
                          <p className="font-medium">{selectedTreatment.coat_structure || 'Belirtilmemiş'}</p>
                        </div>
                        <div className="bg-white rounded p-3 shadow-sm">
                          <p className="text-sm text-gray-500 mb-1">Dehidrasyon</p>
                          <p className="font-medium">{selectedTreatment.dehydration_level || 'Belirtilmemiş'}</p>
                        </div>
                      </div>
                    </div>

                    {/* Reçete Bilgileri */}
                    {(selectedTreatment.prescription_notes || selectedTreatment.prescription_food) && (
                      <div className="bg-gray-50 rounded-lg p-4">
                        <h4 className="font-semibold text-gray-700 mb-4 flex items-center">
                          <span className="w-2 h-2 bg-red-500 rounded-full mr-2"></span>
                          Reçete Bilgileri
                        </h4>
                        <div className="space-y-4">
                          {selectedTreatment.prescription_notes && (
                            <div className="bg-white rounded p-3 shadow-sm">
                              <p className="text-sm text-gray-500 mb-1">Reçete Notları</p>
                              <p className="font-medium">{selectedTreatment.prescription_notes}</p>
                            </div>
                          )}
                          {selectedTreatment.prescription_food && (
                            <div className="bg-white rounded p-3 shadow-sm">
                              <p className="text-sm text-gray-500 mb-1">Önerilen Mama</p>
                              <p className="font-medium">{selectedTreatment.prescription_food}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-64 text-gray-500">
                  Detayları görüntülemek için bir muayene seçin
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <TreatmentModal
        isOpen={treatmentModalOpen}
        onClose={handleTreatmentModalClose}
        petId={petId}
      />
    </Modal>
  );
}

export default TreatmentHistoryModal; 