import React, { useState, useEffect, useRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function UserMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Token'ı kaldır
    window.dispatchEvent(new Event('storage')); // storage eventini tetikle
    console.log('Kullanıcı çıkış yaptı, storage eventi tetiklendi.'); // Kontrol için log
    navigate('/login', { replace: true }); // Login sayfasına yönlendir
  };

  return (
    <div className="relative" ref={menuRef}>
      <button onClick={toggleMenu} className="flex items-center space-x-2">
        <FaUserCircle className="text-3xl" />
        <span className="text-sm font-medium">West Vet</span>
      </button>
      {menuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg py-2 z-20">
          <a href="/profile" className="block px-4 py-2 hover:bg-gray-100">Profile</a>
          <a href="/settings" className="block px-4 py-2 hover:bg-gray-100">Settings</a>
          <button
            onClick={handleLogout}
            className="block text-left w-full px-4 py-2 hover:bg-gray-100"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default UserMenu;
