import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FaSave, FaTimes } from 'react-icons/fa';

const UpdateOwnerForm = ({ owner, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    email: '',
    address: '',
    tc: '',
    description: '',
    status: '',
    group: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (owner) {
      setFormData({
        name: owner.name || '',
        phone_number: owner.phone_number || '',
        email: owner.email || '',
        address: owner.address || '',
        tc: owner.tc || '',
        description: owner.description || '',
        status: owner.status || '',
        group: owner.group || '',
      });
    }
  }, [owner]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Temel validasyonlar
    const phone = formData.phone_number?.toString().trim() || '';
    
    if (!phone) {
      newErrors.phone_number = 'Telefon numarası zorunludur';
    } else {
      // Telefon numarası formatı kontrolü
      const phoneRegex = /^[0-9]{10,11}$/;
      const cleanPhone = phone.replace(/\D/g, '');
      if (!phoneRegex.test(cleanPhone)) {
        newErrors.phone_number = 'Geçerli bir telefon numarası giriniz';
      }
    }

    // Email kontrolü (opsiyonel)
    const email = formData.email?.trim();
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = 'Geçerli bir e-posta adresi giriniz';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const prepareFormData = () => {
    // Boş string yerine null gönder
    return {
      name: formData.name?.trim() || null,
      phone_number: formData.phone_number?.toString().trim() || null,
      email: formData.email?.trim() || null,
      address: formData.address?.trim() || null,
      tc: formData.tc?.trim() || null,
      description: formData.description?.trim() || null,
      status: formData.status || 'Aktif',
      group: formData.group?.trim() || null
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await axios.put(
        `https://api.dev1.fugesoft.com/api/owners/${owner._id}`,
        prepareFormData(),
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        toast.success('Müşteri bilgileri başarıyla güncellendi');
        onSuccess?.();
        onClose?.();
      }
    } catch (error) {
      console.error('Error updating owner:', error);
      toast.error(
        error.response?.data?.message || 
        'Müşteri bilgileri güncellenirken bir hata oluştu'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-6">Müşteri Bilgilerini Güncelle</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* İsim */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              İsim *
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              disabled
              className={`w-full px-3 py-2 border rounded-md shadow-sm bg-gray-100 cursor-not-allowed ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-500">{errors.name}</p>
            )}
          </div>

          {/* Telefon */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Telefon *
            </label>
            <input
              type="tel"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                errors.phone_number ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.phone_number && (
              <p className="mt-1 text-sm text-red-500">{errors.phone_number}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              E-posta
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email}</p>
            )}
          </div>

          {/* TC */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              TC Kimlik No
            </label>
            <input
              type="text"
              name="tc"
              value={formData.tc}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Grup */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Grup
            </label>
            <input
              type="text"
              name="group"
              value={formData.group}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Durum */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Durum
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="Aktif">Aktif</option>
              <option value="Pasif">Pasif</option>
            </select>
          </div>
        </div>

        {/* Adres - Tam Genişlik */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Adres
          </label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
            rows="3"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {/* Özel Not - Tam Genişlik */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Özel Not
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows="4"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      {/* Butonlar */}
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onClose}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={isLoading}
        >
          <FaTimes className="mr-2 -ml-1" />
          İptal
        </button>
        <button
          type="submit"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={isLoading}
        >
          <FaSave className="mr-2 -ml-1" />
          {isLoading ? 'Kaydediliyor...' : 'Kaydet'}
        </button>
      </div>
    </form>
  );
};

export default UpdateOwnerForm;
