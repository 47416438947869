import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import UserMenu from './UserMenu';
import fugelogo from '../assets/fugesoftlogo.png';
import { FaBell, FaEnvelope } from 'react-icons/fa';

function Header() {
  return (
    <header className="bg-gradient-to-r from-blue-500 to-blue-900 text-white shadow-lg">
      <div className=" mx-auto">
        <div className="flex items-center justify-between h-16 px-4">
          {/* Logo ve Başlık */}
          <div className="flex items-center space-x-3">
            <div className="flex items-center hover:opacity-90 transition-opacity">
              <a href="/" className="flex items-center">
                <img 
                  src={fugelogo} 
                  alt="Fuge Logo" 
                  className="h-8 w-auto object-contain"
                />
              </a>
            </div>
            <div className="hidden md:block">
              <h1 className="text-sm  tracking-wide bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100">
                Klinik Yazılımları
              </h1>
            </div>
          </div>

          {/* Arama Çubuğu */}
          <div className="flex-1 max-w-xl mx-8 hidden md:block">
            <div className="max-w-md mx-auto">
              <SearchBar />
            </div>
          </div>

          {/* Sağ Taraf İkonları */}
          <div className="flex items-center space-x-6">
            {/* Bildirimler ve Mesajlar Grubu */}
            <div className="flex items-center space-x-4">
              <button className="p-2 hover:bg-blue-800 rounded-lg transition-colors relative">
                <FaBell className="text-xl" />
                <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></span>
              </button>

              <button className="p-2 hover:bg-blue-800 rounded-lg transition-colors relative">
                <FaEnvelope className="text-xl" />
                <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></span>
              </button>
            </div>

            {/* Ayırıcı */}
            <div className="h-6 w-px bg-blue-700"></div>

            {/* Kullanıcı Menüsü */}
            <div className="flex items-center space-x-3">
              <UserMenu />
            </div>
          </div>
        </div>
      </div>

      {/* Mobil Arama Çubuğu */}
      <div className="md:hidden px-4 pb-3">
        <SearchBar />
      </div>
    </header>
  );
}

export default Header;
