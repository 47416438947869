import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { format, addDays, parseISO } from 'date-fns';
import { tr } from 'date-fns/locale';
import { motion } from 'framer-motion';
import {
  FaPaw,
  FaEdit,
  FaDoorOpen,
  FaMars,
  FaVenus,
  FaBirthdayCake,
  FaWeight,
  FaMicrochip,
  FaSyringe,
  FaExclamationTriangle,
  FaUserCircle,
  FaPhone,
  FaWhatsapp,
  FaMapMarkerAlt,
  FaPlus,
  FaTrash,
  FaCheck
} from 'react-icons/fa';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  List, 
  ListItem, 
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Typography
} from '@mui/material';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

const InfoField = ({
  icon: Icon,
  iconColor,
  label,
  value,
  isEditing,
  field,
  onChange,
  editableFields,
  type = 'text',
  multiline = false
}) => (
  <div className="flex items-start space-x-3">
    <Icon className={`mt-1 ${iconColor}`} />
    <div className="flex-1">
      <label className="block text-sm font-medium text-gray-500">{label}</label>
      {isEditing ? (
        multiline ? (
          <textarea
            value={editableFields[field] || ''}
            onChange={(e) => onChange(field, e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            rows="3"
          />
        ) : (
          <input
            type={type}
            value={editableFields[field] || ''}
            onChange={(e) => onChange(field, e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        )
      ) : (
        <p className="mt-1">{value || '-'}</p>
      )}
    </div>
  </div>
);

const PetDetailsTab = ({ 
  pet, 
  isEditing, 
  editableFields, 
  handleInputChange, 
  handleSave, 
  handleEditToggle, 
  handleDischarge,
  handleWhatsAppClick,
  categories 
}) => {
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [addVaccineModalOpen, setAddVaccineModalOpen] = useState(false);
  const [availableSchedules, setAvailableSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState('');
  const [startDate, setStartDate] = useState('');
  const [notes, setNotes] = useState('');
  const [vaccineSchedules, setVaccineSchedules] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [expandedTemplate, setExpandedTemplate] = useState(null);
  const [selectedVaccineForComplete, setSelectedVaccineForComplete] = useState(null);
  const [completeVaccineDialogOpen, setCompleteVaccineDialogOpen] = useState(false);

  const fetchVaccineSchedules = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(
        `https://api.dev1.fugesoft.com/api/vaccine-schedules/pet/${pet._id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('Fetched vaccine schedules:', response.data); // API response'unu logla
      setVaccineSchedules(response.data);
    } catch (err) {
      console.error('Aşı takvimi yükleme hatası:', err);
    }
  };

  useEffect(() => {
    if (pet?._id) {
      fetchVaccineSchedules();
    }
  }, [pet?._id]);
  const handleOpenTemplateDialog = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get('/vaccine-schedules/templates');
      setTemplates(response.data);
      setTemplateDialogOpen(true);
    } catch (err) {
      console.error('Template yükleme hatası:', err);
      setError(
        err.response?.data?.message ||
        err.message ||
        'Şablonlar yüklenirken bir hata oluştu'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteVaccineSchedule = async (scheduleId) => {
    if (window.confirm('Bu aşı takvimi kaydını silmek istediğinize emin misiniz?')) {
      try {
        console.log('Silinecek aşı ID:', scheduleId);
        const token = localStorage.getItem('authToken');
        
        const response = await axios.delete(
          `https://api.dev1.fugesoft.com/api/vaccine-schedules/${scheduleId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        console.log('Silme işlemi yanıtı:', response.data);
        toast.success('Aşı takvimi kaydı silindi');
        await fetchVaccineSchedules(); // Listeyi yenile
      } catch (error) {
        console.error('Error deleting vaccine schedule:', error);
        console.error('Hata detayları:', error.response?.data);
        toast.error(
          error.response?.data?.message ||
          'Aşı takvimi kaydı silinirken bir hata oluştu'
        );
      }
    }
  };

  const handleOpenAddVaccineModal = async () => {
    try {
      const response = await api.get('/vaccine-schedules/templates');
      // Her şablonun içindeki aşıları düzleştirip tekil bir liste oluştur
      const allVaccines = response.data.reduce((acc, template) => {
        if (template.vaccines) {
          template.vaccines.forEach(vaccine => {
            // Eğer bu aşı daha önce eklenmemişse ekle
            if (!acc.some(v => v._id === vaccine.schedule_id)) {
              acc.push({
                _id: vaccine.schedule_id,
                name: vaccine.name,
                description: vaccine.description
              });
            }
          });
        }
        return acc;
      }, []);

      setAvailableSchedules(allVaccines);
      setAddVaccineModalOpen(true);
    } catch (err) {
      console.error('Aşı listesi yüklenirken hata:', err);
      toast.error('Aşı listesi yüklenirken bir hata oluştu');
    }
  };

  const handleCloseAddVaccineModal = () => {
    setAddVaccineModalOpen(false);
    setSelectedSchedule('');
    setStartDate('');
    setNotes('');
  };

  const handleAssignVaccine = async () => {
    try {
      if (!selectedSchedule || !startDate) {
        toast.error('Lütfen gerekli alanları doldurun');
        return;
      }

      // Sadece YYYY-MM-DD formatında tarih gönder
      const assignmentData = {
        schedule_id: selectedSchedule,
        pet_id: pet._id,
        start_date: startDate, // Input'tan gelen YYYY-MM-DD formatını direk kullan
        notes: notes || ''
      };

      console.log('Gönderilen veri:', assignmentData);

      const token = localStorage.getItem('authToken');
      const response = await axios.post(
        'https://api.dev1.fugesoft.com/api/vaccine-schedules/assign',
        assignmentData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('API yanıtı:', response.data);
      
      if (response.data) {
        toast.success('Aşı başarıyla eklendi');
        await fetchVaccineSchedules(); // Listeyi yenile
        handleCloseAddVaccineModal();
      }
    } catch (err) {
      console.error('Aşı eklenirken hata:', err);
      console.error('Hata detayları:', err.response?.data);
      toast.error(
        err.response?.data?.message ||
        'Aşı eklenirken bir hata oluştu'
      );
    }
  };

  const handleApplyTemplate = async (templateId) => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Template uygulama isteği:', {
        templateId,
        petId: pet._id,
        url: `/vaccine-schedules/apply-template/${templateId}/pet/${pet._id}`
      });

      // Şu anki tarihi YYYY-MM-DDThh:mm:ssZ formatında al
      const currentDate = new Date().toISOString();

      const response = await api.post(
        `/vaccine-schedules/apply-template/${templateId}/pet/${pet._id}`,
        {
          start_date: currentDate
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Template uygulama başarılı:', response.data);
      
      // Aşı listesini yeniden yükle
      await fetchVaccineSchedules();
      
      setTemplateDialogOpen(false);
    } catch (err) {
      console.error('Şablon uygulama hatası:', {
        status: err.response?.status,
        statusText: err.response?.statusText,
        data: err.response?.data,
        message: err.message
      });

      let errorMessage = 'Şablon uygulanırken bir hata oluştu';
      
      if (err.response?.status === 422) {
        errorMessage = 'Lütfen gerekli tüm alanların doğru formatta olduğunu kontrol edin';
        // Validation hatası detaylarını göster
        if (err.response?.data?.detail) {
          errorMessage += ': ' + err.response.data.detail;
        }
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        {/* Header Section */}
        <div className="bg-white rounded-xl shadow-sm p-2">
          
          <div className="flex gap-8 mb-8">
              {/* Patient Information */}
              <div className="w-1/2 space-y-2">
                <h3 className="text-lg font-semibold text-gray-800 flex items-center space-x-2">
                  <span>Hasta Bilgileri</span>
                </h3>
                
                <div className="flex gap-5">
                <InfoField
                    icon={FaPaw}
                    iconColor={pet.gender === 'Erkek' ? 'text-blue-500' : 'text-pink-500'}
                    label="İsim"
                    value={pet.name}
                    isEditing={isEditing}
                    field="name"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                  />
                  <InfoField
                    icon={pet.gender === 'Erkek' ? FaMars : FaVenus}
                    iconColor={pet.gender === 'Erkek' ? 'text-blue-500' : 'text-pink-500'}
                    label="Cinsiyet"
                    value={pet.gender}
                    isEditing={isEditing}
                    field="gender"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                  />

                  <InfoField
                    icon={FaBirthdayCake}
                    iconColor="text-purple-500"
                    label="Doğum Tarihi"
                    value={format(new Date(pet.birth_date), 'dd MMMM yyyy', { locale: tr })}
                    isEditing={isEditing}
                    field="birth_date"
                    type="date"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                  />

                  <InfoField
                    icon={FaWeight}
                    iconColor="text-green-500"
                    label="Ağırlık"
                    value={`${pet.weight || ''} kg`}
                    isEditing={isEditing}
                    field="weight"
                    type="number"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                  />

                  <InfoField
                    icon={FaMicrochip}
                    iconColor="text-gray-500"
                    label="Mikroçip ID"
                    value={pet.microchip_id}
                    isEditing={isEditing}
                    field="microchip_id"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                  />


                  <InfoField
                    icon={FaExclamationTriangle}
                    iconColor="text-yellow-500"
                    label="Hasta Mizacı"
                    value={pet.hasta_mizaci}
                    isEditing={isEditing}
                    field="hasta_mizaci"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                    multiline
                  />
                </div>
              </div>

              {/* Owner Information */}
              <div className="w-1/2 space-y-2 ml-12">
                <h3 className="text-lg font-semibold text-gray-800 flex items-center space-x-2">
                  <span>Sahip Bilgileri</span>
                </h3>
                
                <div className="flex gap-6">
                  <InfoField
                    icon={FaUserCircle}
                    iconColor="text-green-500"
                    label="Adı Soyadı"
                    value={pet.owner_name}
                    isEditing={isEditing}
                    field="owner_name"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                  />

                  <div className="flex items-center space-x-4">
                    <InfoField
                      icon={FaPhone}
                      iconColor="text-blue-500"
                      label="İletişim"
                      value={pet.owner_phone}
                      isEditing={isEditing}
                      field="owner_phone"
                      onChange={handleInputChange}
                      editableFields={editableFields}
                    />
                    {!isEditing && pet.owner_phone && (
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={handleWhatsAppClick}
                        className="mt-6 text-green-500 hover:text-green-600"
                        title="WhatsApp'ta mesaj gönder"
                      >
                        <FaWhatsapp size={24} />
                      </motion.button>
                    )}
                  </div>

                  <InfoField
                    icon={FaMapMarkerAlt}
                    iconColor="text-red-500"
                    label="Adres"
                    value={pet.owner_address}
                    isEditing={isEditing}
                    field="owner_address"
                    onChange={handleInputChange}
                    editableFields={editableFields}
                    multiline
                  />
                </div>
              </div>
            </div>
        {/* Main Content Area - Flex Container */}
        <div className="flex gap-8">
          
          {/* Left Side - Information Cards */}
          <div className="w-2/3 space-y-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-800">Aşı Takvimi</h3>
                <div className="flex space-x-3">
                  <Button
                    variant="outlined"
                    startIcon={<FaPlus />}
                    onClick={handleOpenTemplateDialog}
                    size="small"
                  >
                    Şablon Ekle
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<FaSyringe />}
                    onClick={handleOpenAddVaccineModal}
                    size="small"
                    color="success"
                  >
                    Aşı Ekle
                  </Button>
                </div>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="text-left text-gray-600 border-b">
                    <th className="pb-3">Aşı Adı</th>
                    <th className="pb-3">Başlangıç Tarihi</th>
                    <th className="pb-3">Süre</th>
                    <th className="pb-3">Sonraki Aşı Tarihi</th>
                    <th className="pb-3">Durum</th>
                    <th className="pb-3">Notlar</th>
                    <th className="pb-3">İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {vaccineSchedules.length > 0 ? (
                    vaccineSchedules.map((assignment) => (
                      <tr key={assignment.assignment_id} className="border-b">
                        <td className="py-3">
                          <div className="text-sm font-medium text-gray-900">
                            {assignment.schedule.name}
                          </div>
                          {assignment.schedule.description && (
                            <div className="text-xs text-gray-500 mt-1">
                              {assignment.schedule.description}
                            </div>
                          )}
                        </td>
                        <td className="py-3">
                          {new Date(assignment.start_date).toLocaleDateString('tr-TR', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                          })}
                        </td>
                        <td className="py-3">
                          {(() => {
                            const { duration_type, duration_value } = assignment.schedule;
                            const durationMap = {
                              day: 'gün',
                              week: 'hafta',
                              month: 'ay',
                              year: 'yıl'
                            };
                            return `${duration_value} ${durationMap[duration_type] || duration_type}`;
                          })()}
                        </td>
                        <td className="py-3">
                          {(() => {
                            const { duration_type, duration_value } = assignment.schedule;
                            const startDate = new Date(assignment.start_date);
                            let nextDate;

                            switch (duration_type) {
                              case 'day':
                                nextDate = addDays(startDate, duration_value);
                                break;
                              case 'week':
                                nextDate = addDays(startDate, duration_value * 7);
                                break;
                              case 'month':
                              case 'ay':
                                nextDate = new Date(startDate);
                                nextDate.setMonth(nextDate.getMonth() + parseInt(duration_value));
                                break;
                              case 'year':
                                nextDate = new Date(startDate);
                                nextDate.setFullYear(nextDate.getFullYear() + duration_value);
                                break;
                              default:
                                return '-';
                            }

                            const now = new Date();
                            const isOverdue = nextDate < now;
                            const dateString = nextDate.toLocaleDateString('tr-TR', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric'
                            });

                            return (
                              <span className={`${isOverdue ? 'text-red-600' : 'text-green-600'} font-medium`}>
                                {dateString}
                              </span>
                            );
                          })()}
                        </td>
                        <td className="py-3">
                          {assignment.schedule.status === 'yapıldı' ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Yapıldı
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                              Yapılmadı
                            </span>
                          )}
                        </td>
                        <td className="py-3">
                          {assignment.notes || '-'}
                        </td>
                        <td className="py-3 space-x-2">
                          {assignment.schedule.status !== 'yapıldı' && (
                            <button 
                              className="text-green-500 hover:text-green-700"
                              onClick={() => {
                                console.log('Seçilen aşı:', assignment); // Debug için
                                setSelectedVaccineForComplete(assignment);
                                setCompleteVaccineDialogOpen(true);
                              }}
                              title="Aşıyı tamamla"
                            >
                              <FaCheck size={16} />
                            </button>
                          )}
                          <button 
                            className="text-red-500 hover:text-red-700"
                            onClick={() => handleDeleteVaccineSchedule(assignment.schedule._id)}
                            title="Aşı kaydını sil"
                          >
                            <FaTrash size={16} />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="py-4 text-center text-gray-500">
                        Henüz aşı takvimi oluşturulmamış
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Right Side - Category Buttons */}
          <div className="w-1/3">
            <div className="grid grid-cols-2 gap-4">
              {categories.map((category, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`${category.bgColor} ${category.hoverColor} p-6 rounded-xl shadow-sm ${category.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'} transition-all duration-200`}
                  onClick={category.disabled ? undefined : (category.onClick || (() => {}))}
                >
                  <div className="flex flex-col items-center space-y-3">
                    <div className="text-3xl">{category.icon}</div>
                    <span className="text-sm font-medium text-gray-700">
                      {category.title}
                      {category.disabled && <span className="block text-xs text-gray-500 mt-1">(Yakında)</span>}
                    </span>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
      </div>

      {/* Template Selection Dialog */}
      {/* Template Seçme Dialog */}
      <Dialog
        open={templateDialogOpen}
        onClose={() => {
          setTemplateDialogOpen(false);
          setExpandedTemplate(null);
        }}
        maxWidth="sm"
        fullWidth
        keepMounted
        disablePortal
      >
        <DialogTitle>Aşı Takvimi Şablonu Seç</DialogTitle>
        <DialogContent>
          {error && (
            <div className="text-red-500 mb-4">{error}</div>
          )}
          <List>
            {templates.map((template) => (
              <React.Fragment key={template._id}>
                <ListItem 
                  component="div"
                  onClick={() => {
                    setSelectedTemplate(template);
                    setConfirmDialogOpen(true);
                  }}
                  disabled={loading}
                  sx={{ cursor: 'pointer' }}
                >
                  <ListItemText
                    primary={template.name}
                    secondary={`${template.vaccines?.length || 0} aşı içeriyor`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandedTemplate(expandedTemplate === template._id ? null : template._id);
                      }}
                    >
                      {expandedTemplate === template._id ? <FaChevronUp /> : <FaChevronDown />}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={expandedTemplate === template._id}>
                  <List component="div" disablePadding>
                    {template.vaccines?.map((vaccine, index) => (
                      <ListItem key={index} sx={{ pl: 4 }}>
                        <ListItemText
                          primary={vaccine.name}
                          secondary={
                            <Typography variant="body2" color="text.secondary">
                              {vaccine.description || 'Açıklama yok'}
                              {vaccine.interval_days && (
                                <span className="block">
                                  Uygulama aralığı: {vaccine.interval_days} gün
                                </span>
                              )}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setTemplateDialogOpen(false);
              setExpandedTemplate(null);
            }}
            disabled={loading}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>

      {/* Onay Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Aşı Takvimi Uygula</DialogTitle>
        <DialogContent>
          <Typography>
            {selectedTemplate?.name} şablonunu uygulamak istediğinize emin misiniz?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setConfirmDialogOpen(false)}
            disabled={loading}
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              handleApplyTemplate(selectedTemplate._id);
              setConfirmDialogOpen(false);
            }}
            disabled={loading}
            color="primary"
            variant="contained"
          >
            Uygula
          </Button>
        </DialogActions>
      </Dialog>

      {/* Aşı Tamamlama Modalı */}
      <Dialog
        open={completeVaccineDialogOpen}
        onClose={() => setCompleteVaccineDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Aşıyı Tamamla</DialogTitle>
        <DialogContent>
          <Typography>
            {selectedVaccineForComplete?.schedule.name} aşısını tamamlamak istediğinize emin misiniz?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setCompleteVaccineDialogOpen(false)}
            disabled={loading}
          >
            İptal
          </Button>
          <Button
            onClick={async () => {
              try {
                const token = localStorage.getItem('authToken');
                console.log('Güncellenecek aşı:', selectedVaccineForComplete); // Debug için
                console.log('Seçilen aşı assignment:', selectedVaccineForComplete); // Debug için
                // Debug için schedule ID'yi logla
                console.log('Schedule ID:', selectedVaccineForComplete.schedule._id);
                
                const response = await axios.put(
                  `https://api.dev1.fugesoft.com/api/vaccine-schedules/${selectedVaccineForComplete.schedule._id}`,
                  { status: 'yapıldı' },
                  {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                    }
                  }
                );
                
                console.log('API Response:', response.data); // Response'u logla
                
                toast.success('Aşı başarıyla tamamlandı');
                await fetchVaccineSchedules(); // Listeyi yenile
                setCompleteVaccineDialogOpen(false);
              } catch (err) {
                console.error('Aşı tamamlanırken hata:', err);
                toast.error(
                  err.response?.data?.message ||
                  'Aşı tamamlanırken bir hata oluştu'
                );
              }
            }}
            disabled={loading}
            color="success"
            variant="contained"
          >
            Tamamla
          </Button>
        </DialogActions>
      </Dialog>

      {/* Aşı Ekleme Modalı */}
      {addVaccineModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4">Aşı Ekle</h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Aşı Seçin
                </label>
                <select
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={selectedSchedule}
                  onChange={(e) => setSelectedSchedule(e.target.value)}
                >
                  <option value="">Seçiniz...</option>
                  {availableSchedules.map((schedule) => (
                    <option key={schedule._id} value={schedule._id}>
                      {schedule.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Başlangıç Tarihi
                </label>
                <input
                  type="date"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Notlar
                </label>
                <textarea
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows="3"
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                onClick={handleCloseAddVaccineModal}
              >
                İptal
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleAssignVaccine}
              >
                Ekle
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PetDetailsTab;
