import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import loginBg from "../../assets/loginbg.jpg"; 

function LoginScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://api.dev1.fugesoft.com/login", {
        username,
        password,
      });

      const {
        access_token,
        username: loggedInUser,
        role,
        user_id, // user_id backend'den alınıyor
      } = response.data;

      // Token ve diğer bilgileri localStorage'a kaydet
      localStorage.setItem("authToken", access_token);
      localStorage.setItem("username", loggedInUser);
      localStorage.setItem("role", role);
      localStorage.setItem("user_id", user_id); // user_id ekleniyor

      // Başarılı giriş sonrası event tetikleniyor
      console.log("Login başarılı, token ve user_id kaydediliyor:", access_token, user_id);
      window.dispatchEvent(new Event("storage"));

      // Anasayfaya yönlendirme
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Giriş işlemi başarısız:", error);
      setError("Giriş başarısız. Lütfen bilgilerinizi kontrol edin.");
    }
  };

  return (
    <section
      className="bg-cover bg-center w-full h-s"
      style={{
        backgroundImage: `url(${loginBg})`,
        height: "100vh",
      }}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-full lg:py-0">
        <a
          href="https://fugesoft.com/"
          className="flex items-center mb-6 text-2xl font-semibold text-white"
        >
          <img
            className="w-12 h-12 mr-2"
            src="/fugefavico.png"
            alt="FugeVet Logo"
          />
        Fugesoft
        </a>
        <div className="w-full bg-white bg-opacity-90 rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Giriş Yap
            </h1>
            <form onSubmit={handleLogin} className="space-y-4 md:space-y-6">
              <div>
                <label
                  htmlFor="username"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Kullanıcı Adı
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Kullanıcı Adı"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Şifre
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="••••••••"
                  required
                />
              </div>
              {error && (
                <p className="text-sm text-red-500 text-center">{error}</p>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 cursor-pointer"
                    >
                      Beni Hatırla
                    </label>
                  </div>
                </div>
                <a
                  href="/forgot-password"
                  className="text-sm font-medium text-blue-600 hover:underline"
                >
                  Şifremi Unuttum?
                </a>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Giriş Yap
              </button>
              <p className="text-sm font-light text-gray-500">
                powered by &nbsp;
                <a
                  href="https://fugesoft.com/"
                  className="font-medium text-blue-600 hover:underline"
                >
                  fugesoft
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginScreen;
