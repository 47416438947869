import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  FaArrowLeft,
  FaTasks,
  FaUserFriends,
  FaMoneyBill,
  FaListUl,
  FaDog,
  FaBoxes,
  FaClinicMedical,
  FaFileInvoice,
} from 'react-icons/fa';

function Navbar({ title }) {
  const navigate = useNavigate();
  const location = useLocation();

  // Define fallback titles based on the path if no title prop is provided
  const getTitle = () => {
    if (title) return title;
    if (location.pathname === '/overview') return 'Dashboard';
    if (location.pathname === '/kanban-board') return 'Yapılacaklar';
    if (location.pathname === '/pet-list') return 'Yatan Hasta Listesi';
    if (location.pathname === '/outpatient') return 'Ayakta Tedavi';
    if (location.pathname === '/patients') return 'Müşteri Listesi';
    if (location.pathname === '/payments') return 'Ödemeler';
    if (location.pathname === '/petlist') return 'Hayvan Listesi';
    return '';
  };

  // Back button behavior
  const getBackButton = () => {
    if (location.pathname.startsWith('/istek-pet')) {
      return (
        <button
          onClick={() => navigate(`/pet-details/${location.pathname.split('/')[2]}`)}
          className="text-gray-700 hover:text-gray-900 transition-colors flex items-center"
        >
          <FaArrowLeft className="mr-2" />
          <span className="font-medium">Geri</span>
        </button>
      );
    }
    if (location.pathname.startsWith('/pet-details')) {
      return (
        <button
          onClick={() => navigate('/pet-list')}
          className="text-gray-700 hover:text-gray-900 transition-colors flex items-center"
        >
          <FaArrowLeft className="mr-2" />
          <span className="font-medium">Geri</span>
        </button>
      );
    }
    return (
      <button
        onClick={() => navigate('/overview')}
        className="text-gray-700 hover:text-gray-900 transition-colors flex items-center"
      >
        <FaArrowLeft className="mr-2" />
        <span className="font-medium">Anasayfa</span>
      </button>
    );
  };

  // Navigation buttons for quick actions
  const actionButtons = [
    { icon: <FaTasks />, label: 'Yapılacaklar', path: '/kanban-board' },
    { icon: <FaDog />, label: 'Hayvan Listesi', path: '/petlist' },
    { icon: <FaUserFriends />, label: 'Müşteriler', path: '/patients' },
    { icon: <FaMoneyBill />, label: 'Ödemeler', path: '/payments' },
    { icon: <FaBoxes />, label: 'Stok Yönetimi (Yakında)', path: '/stock', disabled: true },
    { icon: <FaClinicMedical />, label: 'Klinik Detayları (Yakında)', path: '/clinic-details', disabled: true },
    { icon: <FaFileInvoice />, label: 'Fatura (Yakında)', path: '/invoice', disabled: true },
  ];

  return (
    <div className="bg-white shadow-md mb-8 py-3 px-6 flex items-center justify-between">
      {/* Back Button */}
      <div className="flex items-center">
        {getBackButton()}
      </div>

      {/* Centered Title */}
      <h1 className="text-lg font-bold text-gray-800">{getTitle()}</h1>

      {/* Action Buttons */}
      <div className="flex space-x-4">
        {actionButtons.map((button, index) => (
          <button
            key={index}
            onClick={() => !button.disabled && navigate(button.path)}
            className={`flex items-center p-2 ${button.disabled 
              ? 'text-gray-400 cursor-not-allowed' 
              : 'text-gray-600 hover:text-gray-800'} transition-colors`}
            title={button.label}
            disabled={button.disabled}
          >
            {button.icon}
            <span className="hidden md:inline ml-2">
              {button.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default Navbar;
