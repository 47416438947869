import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Select from 'react-select';

Modal.setAppElement('#root');

function TreatmentModal({ isOpen, onClose, petId: initialPetId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({ pets: [], owners: [] });
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null);
  const [vets, setVets] = useState([]);
  const [selectedVet, setSelectedVet] = useState(null);
  const token = localStorage.getItem("authToken");
  const searchTimeoutRef = useRef(null);
  const dropdownRef = useRef(null);

  const [form, setForm] = useState({
    petId: initialPetId || "",
    veterinarian: "",
    treatment_date: new Date().toISOString().split('T')[0],
    treatment_type: "",
    symptoms: "",
    pre_diagnosis: "",
    anamnez: "",
    weight: "",
    body_temperature: "",
    general_condition: "NORMAL",
    Solunum_rate: "",
    pulse: "",
    capillary_refill_time: "",
    lymph_nodes: "",
    mucous_membranes: "",
    coat_structure: "",
    dehydration_level: "",
    prescription_type: "",
    serial_number: "",
    book_number: "",
    sequence_number: "",
    e_prescription_number: "",
    approved: false,
    printed: false,
    prescription_notes: "",
    prescription_food: "",
    amount: ""
  });



  // API'den veri çekme
  const searchData = async (searchQuery) => {
    if (!searchQuery.trim()) {
      setResults({ pets: [], owners: [] });
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error('Token bulunamadı');
        return;
      }

      const response = await axios.get(`https://api.dev1.fugesoft.com/api/search?query=${encodeURIComponent(searchQuery)}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setResults(response.data);
    } catch (error) {
      console.error('Arama hatası:', error);
      setError('Arama sırasında bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  // Debounce ile arama
  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowDropdown(true);

    // Önceki timeout'u temizle
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // Yeni timeout ayarla
    searchTimeoutRef.current = setTimeout(() => {
      searchData(value);
    }, 300);
  };

  // Dropdown dışına tıklayınca kapanma


  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Veteriner verilerini yükle
  const loadVets = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get('https://api.dev1.fugesoft.com/api/users', {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Veteriner verilerini işle - sadece veteriner ve admin rolündeki kullanıcıları al
      const formattedVets = response.data.users
        .filter(user => user.role === 'vet' || user.role === 'admin')
        .map(user => ({
          value: user.id,
          label: user.name && user.surname 
            ? `${user.name} ${user.surname}`
            : user.username,
          id: user.id,
          color: user.color || '#4B5563' // Default color if not set
        }));

      setVets(formattedVets);
    } catch (error) {
      console.error('Veteriner verileri yüklenirken hata:', error);
      setError('Veteriner verileri yüklenirken bir hata oluştu');
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadVets();
    }
  }, [isOpen]);

  useEffect(() => {
    const loadInitialPet = async () => {
      if (!isOpen || !initialPetId) return;

      setLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`https://api.dev1.fugesoft.com/api/treatments/pet/${initialPetId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (response.data && response.data.length > 0) {
          const lastTreatment = response.data[0]; // En son muayene
          const pet = lastTreatment.pet;
          
          // Pet seçim formatına uygun şekilde hazırla
          const petData = {
            _id: pet._id,
            name: pet.name,
            owner_name: pet.owner?.name || '',
            weight: pet.weight || '',
            species: pet.species || '',
            breed: pet.breed || '',
            gender: pet.gender || ''
          };

          // handlePetSelection'a gönder
          handlePetSelection(petData);
          console.log('Pet bilgileri yüklendi:', petData);
        }
      } catch (error) {
        console.error('Pet bilgileri yüklenirken hata:', error);
        setError('Pet bilgileri yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
      }
    };

    loadInitialPet();
  }, [isOpen, initialPetId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePetSelection = (pet) => {
    const selectedOption = {
      value: pet._id,
      label: `${pet.name} (${pet.owner_name})`,
      ownerName: pet.owner_name,
      petName: pet.name,
      petId: pet._id,
      weight: pet.weight || '',
      species: pet.species || '',
      breed: pet.breed || ''
    };
    
    setSelectedPet(selectedOption);
    setShowDropdown(false);
    setQuery(`${pet.name} (${pet.owner_name})`);
    
    setForm(prev => ({
      ...prev,
      petId: selectedOption.value,
      petName: selectedOption.petName,
      ownerName: selectedOption.ownerName,
      weight: selectedOption.weight,
      species: selectedOption.species,
      breed: selectedOption.breed
    }));
  };

  const handleVetChange = (selectedOption) => {
    console.log('=== Veteriner Seçim Detayları ===');
    console.log('Seçilen Veteriner:', selectedOption);
    console.log('Veteriner ID:', selectedOption?.id);
    console.log('Veteriner Value:', selectedOption?.value);
    console.log('Veteriner Adı:', selectedOption?.label);
    
    setSelectedVet(selectedOption);
    if (selectedOption) {
      const assignedToId = selectedOption.id || selectedOption.value;
      console.log('Atanacak ID:', assignedToId);
      setForm(prev => ({
        ...prev,
        veterinarian: assignedToId
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedPet) {
      setError('Lütfen bir hasta seçin');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('authToken');
      console.log('=== Muayene Kayıt Detayları ===');
      console.log('Seçili Hasta:', selectedPet);
      console.log('Form Verileri:', form);
      
      const submitData = {
        ...form,
        petId: selectedPet.value,
        weight: form.weight ? parseFloat(form.weight) : 0,
        body_temperature: form.body_temperature ? parseFloat(form.body_temperature) : 0,
        Solunum_rate: form.Solunum_rate ? parseInt(form.Solunum_rate) : 0,
        pulse: form.pulse ? parseInt(form.pulse) : 0,
        capillary_refill_time: form.capillary_refill_time ? parseInt(form.capillary_refill_time) : 0,
        amount: form.amount ? parseFloat(form.amount) : 0
      };
      
      console.log('Gönderilecek Veriler:', submitData);

      const response = await axios.post('https://api.dev1.fugesoft.com/api/treatments', submitData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      console.log('=== API Yanıtı ===');
      console.log('Status:', response.status);
      console.log('Data:', response.data);

      onClose();
    } catch (err) {
      setError(err.response?.data?.message || 'Bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: '42px',
      borderRadius: '0.375rem',
      borderColor: '#e5e7eb',
      '&:hover': {
        borderColor: '#3b82f6'
      }
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999
    })
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl w-[90%] max-w-4xl max-h-[90vh] overflow-y-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Yeni Muayene Kaydı</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <FaTimes size={24} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Temel Bilgiler */}
          <div className="bg-white rounded-lg border p-4 space-y-4">
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Temel Bilgiler</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Hasta
                </label>
                <div className="relative w-full" ref={dropdownRef}>
                  <div className="relative">
                    <input
                      type="search"
                      value={query}
                      onChange={handleSearch}
                      placeholder="Hasta veya hasta adı ile ara..."
                      className="w-full h-10 px-5 pr-10 text-sm bg-white border rounded-lg focus:outline-none focus:border-blue-500 text-gray-900"
                    />
                    <div className="absolute right-0 top-0 mt-3 mr-4">
                      {loading ? (
                        <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      ) : (
                        <svg className="h-4 w-4 fill-current text-gray-500" viewBox="0 0 20 20">
                          <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                        </svg>
                      )}
                    </div>
                  </div>

                  {/* Sonuçlar Dropdown */}
                  {showDropdown && (query.trim() !== '') && (
                    <div className="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-96 overflow-y-auto">
                      {/* Hasta Sonuçları */}
                      {results.pets.length > 0 && (
                        <div className="p-2">
                          <h3 className="text-xs font-semibold text-gray-500 uppercase px-2 mb-1">Hastalar</h3>
                          {results.pets.map((pet) => (
                            <div
                              key={pet._id}
                              onClick={() => handlePetSelection(pet)}
                              className="p-2 hover:bg-gray-100 rounded cursor-pointer text-gray-900"
                            >
                              <div className="font-medium">{pet.name}</div>
                              <div className="text-sm text-gray-600">
                                {pet.owner_name} {pet.ProtokolNo && `(${pet.ProtokolNo})`}
                              </div>
                              <div className="text-xs text-gray-500">
                                {[pet.species, pet.breed, pet.gender].filter(Boolean).join(' - ')}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Sonuç Bulunamadı */}
                      {!loading && query.trim() !== '' && results.pets.length === 0 && (
                        <div className="p-4 text-center text-gray-500">
                          Sonuç bulunamadı
                        </div>
                      )}
                    </div>
                  )}
                  
                  {/* Seçili Hasta Bilgisi */}
                  {selectedPet && (
                    <div className="mt-2 p-2 border rounded-lg bg-gray-50">
                      <div className="font-medium">{selectedPet.petName}</div>
                      <div className="text-sm text-gray-600">{selectedPet.ownerName}</div>
                      {(selectedPet.species || selectedPet.breed) && (
                        <div className="text-xs text-gray-500">
                          {[selectedPet.species, selectedPet.breed].filter(Boolean).join(' - ')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Veteriner Hekim
                </label>
                <Select
                  options={vets}
                  value={selectedVet}
                  onChange={handleVetChange}
                  placeholder="Veteriner hekim seçin..."
                  styles={customStyles}
                  isSearchable
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Muayene Tarihi
                </label>
                <input
                  type="date"
                  name="treatment_date"
                  value={form.treatment_date}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Tedavi Türü
                </label>
                <input
                  type="text"
                  name="treatment_type"
                  value={form.treatment_type}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Semptomlar
                </label>
                <textarea
                  name="symptoms"
                  value={form.symptoms}
                  onChange={handleChange}
                  rows="3"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Ön Teşhis
                </label>
                <textarea
                  name="pre_diagnosis"
                  value={form.pre_diagnosis}
                  onChange={handleChange}
                  rows="3"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Anamnez
                </label>
                <textarea
                  name="anamnez"
                  value={form.anamnez}
                  onChange={handleChange}
                  rows="3"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Fiziksel Muayene */}
          <div className="bg-white rounded-lg border p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Fiziksel Muayene</h3>
              <button
                type="button"
                onClick={() => setShowAdvanced(!showAdvanced)}
                className="text-blue-600 hover:text-blue-700 flex items-center gap-2"
              >
                {showAdvanced ? <FaChevronUp /> : <FaChevronDown />}
                {showAdvanced ? 'Gizle' : 'Göster'}
              </button>
            </div>

            <div className={`space-y-4 ${showAdvanced ? '' : 'hidden'}`}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Kilo (kg)
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    name="weight"
                    value={form.weight}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Vücut Sıcaklığı (°C)
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    name="body_temperature"
                    value={form.body_temperature}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Genel Durum
                  </label>
                  <select
                    name="general_condition"
                    value={form.general_condition}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="NORMAL">Normal</option>
                    <option value="ABNORMAL">Anormal</option>
                    <option value="CRITICAL">Kritik</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Solunum Hızı (/dk)
                  </label>
                  <input
                    type="number"
                    name="Solunum_rate"
                    value={form.Solunum_rate}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nabız (/dk)
                  </label>
                  <input
                    type="number"
                    name="pulse"
                    value={form.pulse}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    KDZ (sn)
                  </label>
                  <input
                    type="number"
                    name="capillary_refill_time"
                    value={form.capillary_refill_time}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lenf Nodülleri
                  </label>
                  <input
                    type="text"
                    name="lymph_nodes"
                    value={form.lymph_nodes}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Mukoz Membranlar
                  </label>
                  <input
                    type="text"
                    name="mucous_membranes"
                    value={form.mucous_membranes}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tüy Yapısı
                  </label>
                  <input
                    type="text"
                    name="coat_structure"
                    value={form.coat_structure}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Dehidrasyon Seviyesi
                  </label>
                  <input
                    type="text"
                    name="dehydration_level"
                    value={form.dehydration_level}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Reçete Bilgileri */}
          <div className="bg-white rounded-lg border p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Reçete Bilgileri</h3>
              <button
                type="button"
                onClick={() => setShowPrescription(!showPrescription)}
                className="text-blue-600 hover:text-blue-700 flex items-center gap-2"
              >
                {showPrescription ? <FaChevronUp /> : <FaChevronDown />}
                {showPrescription ? 'Gizle' : 'Göster'}
              </button>
            </div>

            <div className={`space-y-4 ${showPrescription ? '' : 'hidden'}`}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Reçete Türü
                  </label>
                  <input
                    type="text"
                    name="prescription_type"
                    value={form.prescription_type}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Seri No
                  </label>
                  <input
                    type="text"
                    name="serial_number"
                    value={form.serial_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Defter No
                  </label>
                  <input
                    type="text"
                    name="book_number"
                    value={form.book_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Sıra No
                  </label>
                  <input
                    type="text"
                    name="sequence_number"
                    value={form.sequence_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    E-Reçete No
                  </label>
                  <input
                    type="text"
                    name="e_prescription_number"
                    value={form.e_prescription_number}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tutar
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    name="amount"
                    value={form.amount}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Reçete Notları
                  </label>
                  <textarea
                    name="prescription_notes"
                    value={form.prescription_notes}
                    onChange={handleChange}
                    rows="3"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Önerilen Mama
                  </label>
                  <textarea
                    name="prescription_food"
                    value={form.prescription_food}
                    onChange={handleChange}
                    rows="3"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="flex gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="approved"
                    checked={form.approved}
                    onChange={handleChange}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">Onaylandı</span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="printed"
                    checked={form.printed}
                    onChange={handleChange}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">Yazdırıldı</span>
                </label>
              </div>
            </div>
          </div>

          {/* Form Actions */}
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
              disabled={loading}
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors flex items-center gap-2"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="animate-spin">⌛</span>
                  Kaydediliyor...
                </>
              ) : (
                'Kaydet'
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default TreatmentModal;
