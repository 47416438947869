import React, { useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';

const RecentConversations = ({ ownerDetails, token, baseURL, onUpdate }) => {
  const [newConversation, setNewConversation] = useState('');
  const [writtenBy, setWrittenBy] = useState('');

  const handleAddConversation = async () => {
    if (!newConversation.trim()) {
      toast.error('Lütfen görüşme içeriği giriniz');
      return;
    }

    try {
      const response = await axios.post(
        `${baseURL}/owners/${ownerDetails._id}/conversations`,
        {
          content: newConversation
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );

      setNewConversation('');
      toast.success('Görüşme başarıyla eklendi');
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Görüşme eklenirken hata:', error);
      toast.error(
        error.response?.data?.message ||
        'Görüşme eklenirken bir hata oluştu'
      );
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold flex items-center mb-4">
        <FaHistory className="mr-2" />
        Görüşme Geçmişi
      </h3>
      <div className="space-y-4">
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-2">
            <input
              type="text"
              value={newConversation}
              onChange={(e) => setNewConversation(e.target.value)}
              placeholder="Yeni görüşme ekle..."
              className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleAddConversation}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Ekle
            </button>
          </div>
        </div>
        <div className="space-y-2 max-h-60 overflow-y-auto">
          {ownerDetails?.recent_conversations?.length > 0 ? (
            [...ownerDetails.recent_conversations]
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by date, newest first
              .map((conversation, index) => (
                <div key={index} className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                  <div className="flex items-start space-x-2">
                    <div className="flex-1">
                      <div className="flex justify-between items-center mb-1">
                        <p className="text-sm font-medium text-blue-600">
                          Yazan: {conversation.written_by || 'Belirtilmemiş'}
                        </p>
                        <p className="text-xs text-gray-400">
                          {new Date(conversation.created_at).toLocaleString('tr-TR')}
                        </p>
                      </div>
                      <p className="text-gray-700">{conversation.content}</p>
                      {conversation.owner_name && (
                        <p className="text-xs text-gray-500 mt-1">
                          Müşteri: {conversation.owner_name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <p className="text-center text-gray-500 py-4">Henüz görüşme kaydı yok</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentConversations;
