import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-modal";
import { FaEdit, FaTrash, FaCalendarAlt, FaClock, FaUser } from "react-icons/fa";
import moment from "moment";
import axios from "axios";
import "moment/locale/tr";
import AppointmentModal from "../../../../components/AppointmentModal";

Modal.setAppElement("#root");

function AppointmentsSchedule() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [newEvent, setNewEvent] = useState({
    petId: "",
    owner_name: "",
    pet_name: "",
    description: "",
    date: moment().format("YYYY-MM-DD"),
    startTime: "",
    endTime: "",
    title: "",
    assignedTo: "",
    veterinarian: "",
    color: "blue"
  });
  const [pets, setPets] = useState([]);
  const [users, setUsers] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    fetchAppointments(selectedDate);
    fetchPets();
    fetchUsers();
  }, [selectedDate]);

  const fetchAppointments = async (date) => {
    try {
      // Seçili ayın ilk ve son günlerini al
      const startDate = moment(date).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(date).endOf('month').format('YYYY-MM-DD');

      const response = await axios.get(
        `https://api.dev1.fugesoft.com/api/appointments?start_date=${startDate}&end_date=${endDate}&page=1&limit=999999990`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const appointmentsData = response.data.appointments || [];
      
      if (!Array.isArray(appointmentsData)) {
        console.error("Appointments data is not an array:", appointmentsData);
        return;
      }

      const formattedAppointments = appointmentsData.map((item) => {
        // Randevunun rengini al
        const appointmentColor = item.color || 'blue';

        // Saati formatını düzenle (HH:mm)
        const time = item.time || '';
        const [start, end] = time.split(' - ');
        const formattedTime = start && end
          ? `${moment(start, 'HH:mm:ss').format('HH:mm')} - ${moment(end, 'HH:mm:ss').format('HH:mm')}`
          : time;

        return {
          id: item._id,
          date: item.date || '',
          saat: formattedTime,
          baslik: item.title || '',
          hasta: item.owner_name || 'Bilinmiyor',
          renk: appointmentColor
        };
      });
      
      setAppointments(formattedAppointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const fetchPets = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/owners/all", {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      // Sahipleri alfabetik sırala
      const sortedOwners = response.data.sort((a, b) => a.name.localeCompare(b.name));

      const petOptions = sortedOwners
        .filter(owner => owner.pets && owner.pets.length > 0)
        .flatMap(owner => 
          owner.pets.map(pet => ({
            value: pet.id,
            label: `${owner.name} - ${pet.name}${owner.protocol_no ? ` (${owner.protocol_no})` : ''}`,
            ownerName: owner.name,
            petName: pet.name,
            petId: pet.id,
            owner: {
              id: owner._id,
              name: owner.name,
              protocol_no: owner.protocol_no,
              email: owner.email || '',
              address: owner.address || ''
            }
          }))
        );
      setPets(petOptions);
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      const formattedUsers = (response.data?.users || [])
        .filter(user => user.role === 'vet' || user.role === 'admin')
        .map(user => ({
          value: user.id,
          label: user.name && user.surname 
            ? `${user.name} ${user.surname}` 
            : user.username,
          color: user.color || 'blue',
          id: user.id,
          name: user.name,
          surname: user.surname,
          username: user.username,
          role: user.role
        }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const openModal = () => {
    setNewEvent({
      petId: "",
      owner_name: "",
      pet_name: "",
      description: "",
      date: moment(selectedDate).format("YYYY-MM-DD"),
      startTime: "",
      endTime: "",
      title: "",
      assignedTo: "",
      veterinarian: "",
      color: "blue"
    });
    setModalIsOpen(true);
  };

  const closeModal = () => setModalIsOpen(false);
  
  const openDetailsModal = async (appointmentId) => {
    try {
      const response = await axios.get(
        `https://api.dev1.fugesoft.com/api/appointments/${appointmentId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSelectedAppointment(response.data);
      setDetailsModalIsOpen(true);
    } catch (error) {
      console.error('Error fetching appointment details:', error);
    }
  };

  const closeDetailsModal = () => {
    setDetailsModalIsOpen(false);
    setSelectedAppointment(null);
  };

  const handleAddEvent = async (appointmentData) => {
    try {
      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/appointments",
        appointmentData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data) {
        const newEntry = {
          id: response.data._id,
          date: appointmentData.date,
          saat: `${appointmentData.startTime} - ${appointmentData.endTime}`,
          baslik: appointmentData.title,
          hasta: appointmentData.owner_name,
          renk: `border-l-4 border-${appointmentData.color || 'blue'}-500`,
        };

        setAppointments((prev) => [...prev, newEntry]);
        closeModal();
        alert('Randevu başarıyla oluşturuldu');
      }
    } catch (error) {
      console.error("API Error Details:", error.response?.data);
      alert('Randevu oluşturulurken bir hata oluştu');
    }
  };

  const deleteAppointment = async (appointmentId) => {
    try {
      await axios.delete(`https://api.dev1.fugesoft.com/api/appointments/${appointmentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Silinen randevuyu state'den kaldır
      setAppointments((prev) => prev.filter((appointment) => appointment.id !== appointmentId));
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  const filteredAppointments = appointments
    .filter((appointment) => appointment.date === moment(selectedDate).format("YYYY-MM-DD"))
    .sort((a, b) => {
      const timeA = a.saat.split(" - ")[0];
      const timeB = b.saat.split(" - ")[0];
      return moment(timeA, "HH:mm").diff(moment(timeB, "HH:mm"));
    });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="bg-white rounded-lg shadow-md">
      {/* Header */}
      <div className="p-4 border-b">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-800">Randevu Planlayıcı</h2>
          <button
            onClick={() => setShowCalendar(!showCalendar)}
            className="text-blue-500 hover:text-blue-700 flex items-center gap-2 text-sm font-medium"
          >
            <FaCalendarAlt />
            {showCalendar ? "Takvimi Gizle" : "Takvimi Göster"}
          </button>
        </div>
      </div>

      <div className="flex flex-col">
        {/* Calendar Section */}
        {showCalendar && (
          <div className="p-4 border-b">
            <Calendar
              className="w-full max-w-md mx-auto border rounded-lg shadow-sm"
              locale="tr-TR"
              onChange={handleDateChange}
              value={selectedDate}
              tileClassName={({ date }) => {
                const hasAppointment = appointments.some(
                  (appointment) => appointment.date === moment(date).format("YYYY-MM-DD")
                );
                return hasAppointment ? "has-appointment rounded-lg hover:bg-blue-50" : "rounded-lg hover:bg-blue-50";
              }}
              tileContent={({ date }) => {
                const dayAppointments = appointments.filter(
                  (appointment) => appointment.date === moment(date).format("YYYY-MM-DD")
                );
                return dayAppointments.length > 0 ? (
                  <div className="appointment-dot">
                    <span className="text-xs text-blue-500 font-semibold">{dayAppointments.length}</span>
                  </div>
                ) : null;
              }}
            />
          </div>
        )}

        {/* Appointments Section */}
        <div className="p-4">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              <button
                onClick={() => setSelectedDate(moment(selectedDate).subtract(1, "days").toDate())}
                className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-lg transition-colors"
              >
                &lt;
              </button>
              <h3 className="text-lg font-medium text-gray-800">
                {moment(selectedDate).locale("tr").format("LL")}
              </h3>
              <button
                onClick={() => setSelectedDate(moment(selectedDate).add(1, "days").toDate())}
                className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-lg transition-colors"
              >
                &gt;
              </button>
            </div>
            <button
              onClick={openModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center gap-2 text-sm font-medium"
            >
              <FaCalendarAlt className="text-white" />
              Yeni Randevu
            </button>
          </div>

          {/* Appointments List */}
          <div className="space-y-3">
            {filteredAppointments.length === 0 ? (
              <div className="text-center py-8 text-gray-500">
                Bu tarihte randevu bulunmamaktadır.
              </div>
            ) : (
              filteredAppointments.map((randevu, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg border p-4 hover:shadow-md transition-shadow cursor-pointer relative overflow-hidden"
                  style={{
                    borderLeft: `4px solid ${randevu.renk === 'blue' ? '#3B82F6' : 
                      randevu.renk === 'red' ? '#EF4444' : 
                      randevu.renk === 'green' ? '#10B981' : 
                      randevu.renk === 'orange' ? '#F97316' : 
                      randevu.renk === 'purple' ? '#8B5CF6' : 
                      '#3B82F6'}`
                  }}
                  onClick={() => openDetailsModal(randevu.id)}
                >
                  <div className="flex justify-between items-start">
                    <div className="space-y-2">
                      <h4 className="font-semibold text-gray-800">{randevu.baslik}</h4>
                      <div className="flex items-center gap-4 text-sm text-gray-600">
                        <div className="flex items-center gap-1">
                          <FaClock className="text-gray-400" />
                          {randevu.saat}
                        </div>
                        <div className="flex items-center gap-1">
                          <FaUser className="text-gray-400" />
                          {randevu.hasta}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <button className="p-2 text-blue-500 hover:bg-blue-50 rounded-lg transition-colors">
                        <FaEdit />
                      </button>
                      <button 
                        onClick={() => deleteAppointment(randevu.id)}
                        className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Appointment Modal */}
      <AppointmentModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        pets={pets}
        users={users}
        newEvent={newEvent}
        setNewEvent={setNewEvent}
        handleAddEvent={handleAddEvent}
      />

      {/* Randevu Detay Modalı */}
      <Modal
        isOpen={detailsModalIsOpen}
        onRequestClose={closeDetailsModal}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-11/12 max-w-2xl max-h-[90vh] overflow-y-auto shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        {selectedAppointment && (
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Randevu Detayları</h2>
              <button
                onClick={closeDetailsModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <FaEdit className="w-6 h-6" />
              </button>
            </div>
            
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="font-semibold">Hasta Sahibi:</p>
                  <p>{selectedAppointment.owner_name}</p>
                </div>
                <div>
                  <p className="font-semibold">Hasta:</p>
                  <p>{selectedAppointment.pet_name}</p>
                </div>
                <div>
                  <p className="font-semibold">Tarih:</p>
                  <p>{moment(selectedAppointment.date).format('DD.MM.YYYY')}</p>
                </div>
                <div>
                  <p className="font-semibold">Saat:</p>
                  <p>{selectedAppointment.startTime} - {selectedAppointment.endTime}</p>
                </div>
                <div>
                  <p className="font-semibold">Veteriner:</p>
                  <p>{selectedAppointment.veterinarian}</p>
                </div>
                <div>
                  <p className="font-semibold">Durum:</p>
                  <p>{selectedAppointment.status}</p>
                </div>
              </div>
              
              {selectedAppointment.description && (
                <div>
                  <p className="font-semibold">Açıklama:</p>
                  <p className="whitespace-pre-wrap">{selectedAppointment.description}</p>
                </div>
              )}
            </div>

            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={closeDetailsModal}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                Kapat
              </button>
            </div>
          </div>
        )}
      </Modal>

      <style>
        {`
          .has-appointment {
            background-color: rgba(59, 130, 246, 0.1);
            font-weight: bold;
            color: #2563eb;
          }
          .appointment-dot {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2px;
          }
        `}
      </style>
    </div>
  );
}

export default AppointmentsSchedule;
