import React from 'react';
import TreatmentHistoryModal from './TreatmentHistoryModal';

const TreatmentHistoryView = ({ 
  petId,
  isOpen,
  onRequestClose
}) => {
  return (
    <TreatmentHistoryModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      petId={petId}
    />
  );
};

export default TreatmentHistoryView;
