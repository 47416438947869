import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';
import { format } from 'date-fns';
import { FaUpload } from 'react-icons/fa';
import { components } from 'react-select';
import WindowedSelect from 'react-windowed-select';

const NewPetForm = ({ onClose, onSuccess, defaultOwnerId }) => {
  const [owners, setOwners] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedOwnerId, setSelectedOwnerId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const loadOwners = async () => {

    setIsLoading(true);

    // Önce localStorage'dan verileri kontrol et
    const cachedData = localStorage.getItem('ownersList');
    const cachedOptions = localStorage.getItem('ownerOptions');
    const cacheTimestamp = localStorage.getItem('ownersListTimestamp');
    const CACHE_DURATION = 5 * 60 * 1000; // 5 dakika

    // Cache geçerli mi kontrol et
    if (cachedData && cachedOptions && cacheTimestamp) {
      const isExpired = Date.now() - parseInt(cacheTimestamp) > CACHE_DURATION;

      
      if (!isExpired) {

        const parsedData = JSON.parse(cachedData);
        const parsedOptions = JSON.parse(cachedOptions);
        setOwners(parsedData);
        setOwnerOptions(parsedOptions);
        setIsLoading(false);
        return;
      }
    }


    // Cache yoksa veya süresi geçmişse yeni veri çek
    await fetchOwners();
  };

  const fetchOwners = async () => {

    try {
      const token = localStorage.getItem('authToken');

      const response = await axios.get('https://api.dev1.fugesoft.com/api/owners/all', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });


      

      // Verileri alfabetik sıraya göre sırala
      const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
      
      const formattedOptions = sortedData.map(owner => ({
        value: owner._id,
        label: `${owner.name} ${owner.phone_number ? `- ${owner.phone_number}` : ''}`,
        owner: owner,
        data: owner // react-windowed-select için gerekli
      }));

      // Verileri localStorage'a kaydet
      localStorage.setItem('ownersList', JSON.stringify(sortedData));
      localStorage.setItem('ownerOptions', JSON.stringify(formattedOptions));
      localStorage.setItem('ownersListTimestamp', Date.now().toString());

      
      setOwners(sortedData);
      setOwnerOptions(formattedOptions);
    } catch (error) {

      toast.error('Hasta sahipleri yüklenirken bir hata oluştu');
    } finally {
      setIsLoading(false);

    }
  };

  useEffect(() => {
    loadOwners();
  }, []);

  const [formData, setFormData] = useState({
    owner_id: defaultOwnerId || '',
    name: '',
    species: '',
    breed: '',
    age: '',
    gender: '',
    microchip_id: '',
    weight: '',
    height: '',
    complaint: '',
    diagnosis: '',
    doctor_comment: '',
    hasta_mizaci: '',
    status: 'Aktif',
    photos: [],
    price: 0,

    order: 0,
    istek: []
  });

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const token = localStorage.getItem('authToken');

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setErrors(prev => ({ ...prev, [name]: '' }));
    setFormData(prev => ({
      ...prev,
      [name]: type === 'number' ? (value === '' ? '' : Number(value)) : value
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prev => [...prev, ...files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      // Handle file uploads first if any
      const uploadedPhotos = [];
      if (selectedFiles.length > 0) {
        for (const file of selectedFiles) {
          const formData = new FormData();
          formData.append('file', file);
          
          try {
            const uploadResponse = await axios.post('https://api.dev1.fugesoft.com/api/upload', formData, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            });
            
            if (uploadResponse.data?.url) {
              uploadedPhotos.push(uploadResponse.data.url);
            }
          } catch (error) {

            toast.error('Dosya yüklenirken hata oluştu');
          }
        }
      }

      // Prepare submission data
      const now = new Date();
      // Get owner details
      const selectedOwner = owners.find(owner => owner._id === formData.owner_id);
      
      // Format numeric fields and prepare submission data
      const submissionData = {
        name: formData.name || '',
        owner_name: selectedOwner?.name || '',
        owner_phone: selectedOwner?.phone_number?.toString() || '',
        owner_address: selectedOwner?.address || '',
        species: formData.species || '',
        breed: formData.breed || '',
        age: formData.age ? Number(formData.age) : 0,
        gender: formData.gender || '',
        microchip_id: formData.microchip_id || '',
        weight: formData.weight ? Number(formData.weight) : 0,
        height: formData.height ? Number(formData.height) : 0,
        complaint: formData.complaint || '',
        diagnosis: formData.diagnosis || '',
        doctor_comment: formData.doctor_comment || '',
        hasta_mizaci: formData.hasta_mizaci || '',
        status: formData.status || 'Aktif',
        photos: uploadedPhotos,
        price: formData.price ? Number(formData.price) : 0,
        appointments_history: [],
        treatments_history: [],
        task_list: [],
        istek: [],
        order: 0,
        created_at: format(now, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        updated_at: format(now, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      };


      const response = await axios.post('https://api.dev1.fugesoft.com/api/pets', submissionData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        toast.success('Hasta başarıyla eklendi');
        onSuccess?.();
        onClose?.();
      }
    } catch (error) {


      toast.error(error.response?.data?.message || 'Hasta eklenirken bir hata oluştu. Lütfen tüm zorunlu alanları doldurun.');
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.owner_id) newErrors.owner_id = 'Lütfen hasta sahibi seçin';
    if (!formData.name) newErrors.name = 'Lütfen hasta adını girin';
    if (!formData.species) newErrors.species = 'Lütfen tür seçin';
    if (!formData.complaint) newErrors.complaint = 'Lütfen şikayet bilgisi girin';
    if (!formData.hasta_mizaci) newErrors.hasta_mizaci = 'Lütfen hasta mizacını seçin';
    
    // Numeric field validation
    if (formData.weight && isNaN(Number(formData.weight))) {
      newErrors.weight = 'Lütfen geçerli bir ağırlık girin';
    }
    if (formData.height && isNaN(Number(formData.height))) {
      newErrors.height = 'Lütfen geçerli bir boy girin';
    }
    if (formData.age && isNaN(Number(formData.age))) {
      newErrors.age = 'Lütfen geçerli bir yaş girin';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep = (step) => {
    switch(step) {
      case 1:
        if (!formData.owner_id || !formData.name || !formData.species) {
          toast.error('Lütfen hasta sahibi, hasta adı ve türünü seçin');
          return false;
        }
        break;
      case 2:
        if (!formData.complaint) {
          toast.error('Lütfen şikayet bilgisini girin');
          return false;
        }
        break;
      case 3:
        if (!formData.hasta_mizaci) {
          toast.error('Lütfen hasta mizacını seçin');
          return false;
        }
        break;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(prev => Math.min(prev + 1, 3));
    }
  };

  const renderStep1 = () => (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Hasta Sahibi *</label>
        <div className="relative">
          <WindowedSelect
            name="owner_id"
            value={ownerOptions.find(option => option.value === formData.owner_id)}
            onChange={(selectedOption) => {
              setFormData(prev => ({
                ...prev,
                owner_id: selectedOption?.value || ''
              }));
              setErrors(prev => ({ ...prev, owner_id: '' }));
            }}
            options={ownerOptions}
            isClearable
            isSearchable
            isLoading={isLoading}
            placeholder="Hasta sahibi seçin veya arayın..."
            noOptionsMessage={() => "Hasta sahibi bulunamadı"}
            windowThreshold={100} // 100'den fazla seçenek olduğunda windowing aktif olur
            components={{
              MenuList: components.MenuList
            }}
            classNames={{
              control: (state) => 
                `h-12 ${errors.owner_id ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-gray-400'}`
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                boxShadow: errors.owner_id ? '0 0 0 1px #f56565' : base.boxShadow,
                borderColor: errors.owner_id ? '#f56565' : base.borderColor,
                backgroundColor: isLoading ? '#f3f4f6' : 'white',
                '&:hover': {
                  borderColor: errors.owner_id ? '#f56565' : base.borderColor
                }
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999
              }),
              option: (base) => ({
                ...base,
                padding: '8px 12px'
              })
            }}
          />
          {isLoading && (
            <div className="absolute right-10 top-1/2 transform -translate-y-1/2 pointer-events-none">
              <div className="flex space-x-1">
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
              </div>
            </div>
          )}
        </div>
        {errors.owner_id && (
          <p className="mt-1 text-sm text-red-500">{errors.owner_id}</p>
        )}
      </div>

      {formData.owner_id && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Seçilen Hasta Sahibi Bilgileri</h4>
          {(() => {
            const selectedOwner = owners.find(o => o._id === formData.owner_id);
            return selectedOwner ? (
              <>
                <p className="text-sm text-gray-600">İsim: {selectedOwner.name}</p>
                {selectedOwner.protocol_no && <p className="text-sm text-gray-600">Protokol No: {selectedOwner.protocol_no}</p>}
                {selectedOwner.phone_number && <p className="text-sm text-gray-600">Telefon: {selectedOwner.phone_number}</p>}
                {selectedOwner.address && <p className="text-sm text-gray-600">Adres: {selectedOwner.address}</p>}
                {selectedOwner.group && <p className="text-sm text-gray-600">Grup: {selectedOwner.group}</p>}
                {selectedOwner.description && <p className="text-sm text-gray-600">Not: {selectedOwner.description}</p>}
              </>
            ) : null;
          })()}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">İsim *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className={`mt-1 block w-full rounded-md shadow-sm h-12 px-4 ${errors.name ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}`}
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-500">{errors.name}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Tür *</label>
          <select
            name="species"
            value={formData.species}
            onChange={handleChange}
            required
            className={`mt-1 block w-full rounded-md shadow-sm h-12 px-4 ${errors.species ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}`}
          >
            <option value="">Seçiniz</option>
            <option value="Kedi">Kedi</option>
            <option value="Köpek">Köpek</option>
            <option value="Kuş">Kuş</option>
            <option value="Diğer">Diğer</option>
          </select>
          {errors.species && (
            <p className="mt-1 text-sm text-red-500">{errors.species}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Irk</label>
          <input
            type="text"
            name="breed"
            value={formData.breed}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Cinsiyet</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          >
            <option value="">Seçiniz</option>
            <option value="Erkek">Erkek</option>
            <option value="Dişi">Dişi</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Yaş</label>
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            min="0"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Kilo (kg)</label>
          <input
            type="number"
            name="weight"
            value={formData.weight}
            onChange={handleChange}
            step="0.1"
            min="0"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Boy (cm)</label>
          <input
            type="number"
            name="height"
            value={formData.height}
            onChange={handleChange}
            min="0"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4"
          />
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Mikroçip ID</label>
        <input
          type="text"
          name="microchip_id"
          value={formData.microchip_id}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Şikayet</label>
        <textarea
          name="complaint"
          value={formData.complaint}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Teşhis</label>
        <textarea
          name="diagnosis"
          value={formData.diagnosis}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Doktor Notu</label>
        <textarea
          name="doctor_comment"
          value={formData.doctor_comment}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Hasta Mizacı</label>
        <select
          name="hasta_mizaci"
          value={formData.hasta_mizaci}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="">Seçiniz</option>
          <option value="Sakin">Sakin</option>
          <option value="Agresif">Agresif</option>
          <option value="Ürkek">Ürkek</option>
          <option value="Normal">Normal</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Fotoğraflar</label>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <FaUpload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="flex text-sm text-gray-600">
              <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                <span>Fotoğraf yükle</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  className="sr-only"
                />
              </label>
              <p className="pl-1">ya da sürükle bırak</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF max 10MB</p>
          </div>
        </div>
        {selectedFiles.length > 0 && (
          <div className="mt-2">
            <p className="text-sm text-gray-500">{selectedFiles.length} dosya seçildi</p>
            <ul className="mt-2 divide-y divide-gray-200">
              {selectedFiles.map((file, index) => (
                <li key={index} className="py-2 text-sm text-gray-500">
                  {file.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>


    </div>
  );

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Yeni Hasta Ekle</h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Progress bar */}
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
          <div 
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
            style={{ width: `${(currentStep / 3) * 100}%` }}
          ></div>
        </div>

        {/* Step content */}
        {currentStep === 1 && renderStep1()}
        {currentStep === 2 && renderStep2()}
        {currentStep === 3 && renderStep3()}

        {/* Navigation buttons */}
        <div className="flex justify-between pt-4">
          <button
            type="button"
            onClick={() => setCurrentStep(prev => Math.max(prev - 1, 1))}
            disabled={currentStep === 1}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            Geri
          </button>
          
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              İptal
            </button>
            
            {currentStep < 3 ? (
              <button
                type="button"
                onClick={handleNext}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                İleri
              </button>
            ) : (
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
              >
                {loading ? 'Kaydediliyor...' : 'Kaydet'}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewPetForm;
