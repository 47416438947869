import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  FaPlus, FaEdit, FaTrash, FaPaw, FaCalendar, FaUser, FaPhone, 
  FaMapMarkerAlt, FaIdCard, FaMoneyBill, FaCat, FaDog, FaDove, FaHorse, 
  FaHistory, FaBell, FaEnvelope, FaSms, FaFileAlt,
  FaChartLine, FaClipboardList, FaExclamationCircle
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import LoadingSpinner from '../../../components/LoadingSpinner';
import AppointmentModal from '../../../components/AppointmentModal';
import RecentConversations from './components/RecentConversations';
import NewPetForm from './NewPetForm';
import UpdateOwnerForm from './components/UpdateOwnerForm';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '800px',
    width: '100%',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000
  }
};

function PetOwnerDetailsScreen() {
  const { owner_id } = useParams();
  const navigate = useNavigate();
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [pets, setPets] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [newEvent, setNewEvent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [newNote, setNewNote] = useState('');
  const itemsPerPage = 5;

  const [paymentHistory, setPaymentHistory] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notes, setNotes] = useState([]);

  const [stats, setStats] = useState({
    totalVisits: 0,
    totalSpent: 0,
    lastVisit: null,
    averageVisitPerMonth: 0
  });

  const token = localStorage.getItem('authToken');
  const baseURL = 'https://api.dev1.fugesoft.com/api';

  const addConversation = async (content) => {
    try {
      const response = await axios.post(
        `${baseURL}/owners/${owner_id}/conversations`,
        { content },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );

      // Görüşme başarıyla eklendiyse owner detaylarını yeniden yükle
      if (response.status === 200 || response.status === 201) {
        await fetchOwnerDetails();
        toast.success('Görüşme kaydı eklendi');
      }
    } catch (err) {
      console.error('Görüşme eklenirken hata:', err);
      toast.error(
        err.response?.data?.message ||
        'Görüşme kaydı eklenirken bir hata oluştu'
      );
    }
  };

  // API calls
  const fetchOwnerDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}/owners/${owner_id}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      
      if (response.data) {
        

        // Temel owner bilgilerini set et
        setOwnerDetails({
          _id: response.data._id,
          name: response.data.name,
          phone_number: response.data.phone_number,
          email: response.data.email,
          address: response.data.address,
          protocol_no: response.data.protocol_no,
          status: response.data.status,
          balance: response.data.balance || 0,
          group: response.data.group,
          description: response.data.description,
          recent_conversations: response.data.recent_conversations || [] // Include recent_conversations in ownerDetails
        });

        // Pet bilgilerini set et
        if (response.data.pets && Array.isArray(response.data.pets)) {
          const formattedPets = response.data.pets.map(pet => ({
            _id: pet._id,
            name: pet.name,
            species: pet.species,
            breed: pet.breed,
            birth_date: pet.birth_date,
            gender: pet.gender,
            color: pet.color,
            status: pet.status,
            appointments: [] // Şimdilik boş array olarak set ediyoruz
          }));
          setPets(formattedPets);
        } else {
          setPets([]);
        }
      }
    } catch (error) {
      // Detaylı hata loglaması
      console.error('Error fetching owner details:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data,
        config: {
          url: error.config?.url,
          method: error.config?.method,
          headers: error.config?.headers
        }
      });

      if (error.response?.status === 500) {
        toast.error('Sunucu hatası oluştu. Lütfen daha sonra tekrar deneyin.');
      } else if (error.response?.status === 404) {
        toast.error('Hasta bulunamadı.');
      } else if (error.response?.status === 403) {
        toast.error('Bu bilgilere erişim yetkiniz yok.');
      } else {
        toast.error('Hasta bilgileri yüklenirken bir hata oluştu');
      }

      setOwnerDetails(null);
      setPets([]);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${baseURL}/users`, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      
      // API paginated response formatını kontrol et
      if (response.data && response.data.users && Array.isArray(response.data.users)) {
        const formattedUsers = response.data.users.map(user => ({
          value: user.id || user._id,
          label: user.name,
          color: user.color || 'blue'
        }));
        setUsers(formattedUsers);
      } else {
        console.error('Invalid users response:', response.data);
        setUsers([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Kullanıcı listesi yüklenirken bir hata oluştu');
      setUsers([]);
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await axios.get(`${baseURL}/appointments/owner/${owner_id}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      
      if (response.data && Array.isArray(response.data.appointments)) {
        // Tarihe göre sırala (en yeni en üstte)
        const sortedAppointments = response.data.appointments.sort((a, b) => {
          const dateA = new Date(a.date + 'T' + a.time);
          const dateB = new Date(b.date + 'T' + b.time);
          return dateB - dateA;
        });

        setAppointments(sortedAppointments);
      } else {
        console.error('Invalid appointments response:', response.data);
        setAppointments([]);
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
      toast.error('Randevu bilgileri yüklenirken bir hata oluştu');
      setAppointments([]);
    }
  };

  // Initialize empty arrays for features that will be implemented later
  useEffect(() => {
    setPaymentHistory([]);
    setNotes([]);
    setNotifications([]);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!owner_id || !token) {
        console.error('Missing owner_id or token');
        return;
      }

      setIsLoading(true);
      try {
        try {
          await fetchOwnerDetails();
        } catch (error) {
          console.error('Error in fetchOwnerDetails:', error);
          toast.error('Hasta bilgileri yüklenirken hata oluştu');
        }

        try {
          await fetchUsers();
        } catch (error) {
          console.error('Error in fetchUsers:', error);
          toast.error('Kullanıcı listesi yüklenirken hata oluştu');
        }

        try {
          await fetchAppointments();
        } catch (error) {
          console.error('Error in fetchAppointments:', error);
          toast.error('Randevu bilgileri yüklenirken hata oluştu');
        }

        // Payment history, notes, and notifications APIs will be implemented later
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [owner_id, token]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAppointments = appointments.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(appointments.length / itemsPerPage);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'tamamlandı':
        return 'bg-green-100 text-green-800';
      case 'yapılacak':
        return 'bg-yellow-100 text-yellow-800';
      case 'iptal':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleAddItem = async (type, formData) => {
    try {
      if (type === 'pet') {
        await axios.post(`${baseURL}/owners/${owner_id}/pets`, formData, {
          headers: { Authorization: `Bearer ${token}` }
        });
        toast.success('Evcil hayvan başarıyla eklendi');
        await fetchOwnerDetails();
      }
      setModalIsOpen(false);
    } catch (error) {
      console.error(`Error adding ${type}:`, error);
      toast.error(`${type === 'pet' ? 'Evcil hayvan' : 'Randevu'} eklenirken bir hata oluştu`);
    }
  };

  const handleDeleteItem = async (type, id) => {
    try {
      if (type === 'pet') {
        await axios.delete(`${baseURL}/owners/${owner_id}/pets/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        toast.success('Evcil hayvan başarıyla silindi');
        await fetchOwnerDetails();
      }
    } catch (error) {
      console.error(`Error deleting ${type}:`, error);
      toast.error(`${type === 'pet' ? 'Evcil hayvan' : 'Randevu'} silinirken bir hata oluştu`);
    }
  };

  const handleAppointmentSubmit = async (appointmentData) => {
    try {
      const response = await axios.post(`${baseURL}/appointments`, appointmentData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data) {
        toast.success('Randevu başarıyla oluşturuldu');
        setShowAppointmentModal(false);
      }
    } catch (error) {
      console.error('Error creating appointment:', error);
      toast.error('Randevu oluşturulurken bir hata oluştu');
    }
  };

  const getPetIcon = (species) => {
    const speciesLower = (species || '').toLowerCase();
    if (speciesLower.includes('kedi')) {
      return { icon: FaCat, color: 'bg-orange-100 text-orange-500' };
    } else if (speciesLower.includes('köpek')) {
      return { icon: FaDog, color: 'bg-blue-100 text-blue-500' };
    } else if (speciesLower.includes('kuş')) {
      return { icon: FaDove, color: 'bg-sky-100 text-sky-500' };
    } else if (speciesLower.includes('at')) {
      return { icon: FaHorse, color: 'bg-amber-100 text-amber-500' };
    }
    return { icon: FaPaw, color: 'bg-purple-100 text-purple-500' };
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    setFormData({});
  };

  const handleAppointmentModalClose = () => {
    setShowAppointmentModal(false);
    setNewEvent(null);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!ownerDetails) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h2 className="text-2xl font-bold mb-4">Müşteri bulunamadı</h2>
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Geri Dön
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Owner Information Card */}
        <div className="space-y-6">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaUser className="mr-2" />
                Müşteri Bilgileri
              </h3>
              <button
                onClick={() => setUpdateModalIsOpen(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center text-sm"
              >
                <FaEdit className="mr-2" /> Düzenle
              </button>
            </div>
            <div className="space-y-3">
              <p className="flex items-center text-gray-700">
                <FaUser className="mr-2" />
                <span className="font-semibold">İsim:</span> {ownerDetails.name}
              </p>
              <p className="flex items-center text-gray-700">
                <FaPhone className="mr-2" />
                <span className="font-semibold">Telefon:</span> {ownerDetails.phone_number}
              </p>
              <p className="flex items-center text-gray-700">
                <FaMapMarkerAlt className="mr-2" />
                <span className="font-semibold">Adres:</span> {ownerDetails.address}
              </p>
              <p className="flex items-center text-gray-700">
                <FaIdCard className="mr-2" />
                <span className="font-semibold">TC:</span> {ownerDetails.tc}
              </p>
              <p className="flex items-center text-gray-700">
                <FaMoneyBill className="mr-2" />
                <span className="font-semibold">Bakiye:</span> {ownerDetails.balance?.toLocaleString('tr-TR')} TL
              </p>
              {ownerDetails.description && (
                <div className="mt-4 p-4 bg-yellow-50 rounded-lg border border-yellow-200">
                  <p className="flex items-center text-gray-700">
                    <FaExclamationCircle className="mr-2 text-yellow-500" />
                    <span className="font-semibold">Özel Not:</span>
                  </p>
                  <p className="mt-2 text-gray-600 whitespace-pre-wrap">
                    {ownerDetails.description?.replace(/_x000D_/g, '\n')}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Payment History Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaHistory className="mr-2" />
                Ödeme Geçmişi
              </h3>
              <button
                onClick={() => setShowPaymentHistory(!showPaymentHistory)}
                className="text-blue-500 hover:text-blue-600"
              >
                {showPaymentHistory ? 'Gizle' : 'Göster'}
              </button>
            </div>
            {showPaymentHistory && (
              <div className="space-y-3">
                {paymentHistory.map(payment => (
                  <div key={payment.id} className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
                    <div>
                      <p className="font-semibold">{payment.description}</p>
                      <p className="text-sm text-gray-500">{payment.date}</p>
                    </div>
                    <span className="font-bold text-green-600">{payment.amount} TL</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Recent Conversations Section */}
          <RecentConversations 
            ownerDetails={ownerDetails}
            token={token}
            baseURL={baseURL}
            onUpdate={fetchOwnerDetails}
          />

          {/* Statistics Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold flex items-center mb-4">
              <FaChartLine className="mr-2" />
              İstatistikler
            </h3>
              <div className="grid grid-cols-2 gap-4">
              <div className="bg-blue-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Toplam Ziyaret</p>
                <p className="text-xl font-bold text-blue-600">{stats.totalVisits}</p>
              </div>
              <div className="bg-green-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Toplam Harcama</p>
                <p className="text-xl font-bold text-green-600">{stats.totalSpent?.toLocaleString('tr-TR')} TL</p>
              </div>
              <div className="bg-purple-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Aylık Ort. Ziyaret</p>
                <p className="text-xl font-bold text-purple-600">{stats.averageVisitPerMonth}</p>
              </div>
              <div className="bg-orange-50 p-4 rounded-lg">
                <p className="text-sm text-gray-600">Son Ziyaret</p>
                <p className="text-xl font-bold text-orange-600">{stats.lastVisit ? format(new Date(stats.lastVisit), 'dd MMM yyyy', { locale: tr }) : '-'}</p>
              </div>
            </div>
          </div>
          
          
        </div>

        <div className="lg:col-span-2 space-y-6">
          
          {/* Pets Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaPaw className="mr-2" />
                Evcil Hayvanlar
              </h3>
              <button
                onClick={() => setModalIsOpen(true)}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 flex items-center"
              >
                <FaPlus className="mr-2" /> Yeni Evcil Hayvan
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {pets.map((pet) => {
                const { icon: PetIcon, color } = getPetIcon(pet.species);
                return (
                  <div 
                    key={pet.id} 
                    className="bg-gray-50 p-4 rounded-lg shadow transition-all hover:shadow-md cursor-pointer hover:bg-gray-100"
                    onClick={() => navigate(`/pet-details/${pet._id}`)}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h4 className="text-lg font-bold mb-2 flex items-center">
                          <PetIcon className={`mr-2 ${color}`} />
                          {pet.name}
                        </h4>
                        <p className="text-gray-600"><span className="font-semibold">Tür:</span> {pet.species || 'Belirtilmemiş'}</p>
                        <p className="text-gray-600"><span className="font-semibold">Cins:</span> {pet.breed || 'Belirtilmemiş'}</p>
                        <p className="text-gray-600"><span className="font-semibold">Yaş:</span> {pet.age || 'Belirtilmemiş'}</p>
                      </div>
                      <div className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                        <button
                          onClick={() => handleDeleteItem('pet', pet.id)}
                          className="text-red-500 hover:text-red-600"
                          title="Sil"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              {pets.length === 0 && (
                <div className="col-span-2 text-center py-8 text-gray-500">
                  Henüz evcil hayvan eklenmemiş
                </div>
              )}
            </div>
          </div>
{/* Notifications Section */}
<div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold flex items-center">
                <FaBell className="mr-2" />
                Bildirimler ve Hatırlatıcılar
              </h3>
              <div className="flex space-x-2">
                <button
                  disabled
                  className="bg-gray-400 text-white px-3 py-2 rounded-lg flex items-center opacity-50 cursor-not-allowed"
                >
                  <FaSms className="mr-2" />
                  SMS Gönder
                  <span className="ml-1 text-xs">(Yakında)</span>
                </button>
                <button
                  disabled
                  className="bg-gray-400 text-white px-3 py-2 rounded-lg flex items-center opacity-50 cursor-not-allowed"
                >
                  <FaEnvelope className="mr-2" />
                  E-posta Gönder
                  <span className="ml-1 text-xs">(Yakında)</span>
                </button>
              </div>
            </div>
            <div className="space-y-3">
              {notifications.length > 0 ? (
                notifications.map(notification => (
                  <div key={notification._id} className="flex items-start p-4 bg-yellow-50 rounded-lg">
                    <FaExclamationCircle className="text-yellow-500 mt-1 mr-3" />
                    <div>
                      <p className="font-semibold">{notification.pet?.name || 'Evcil Hayvan'} - {notification.message}</p>
                      <p className="text-sm text-gray-600">
                        Tarih: {notification.due_date ? format(new Date(notification.due_date), 'dd MMM yyyy', { locale: tr }) : '-'}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center py-4 text-gray-500">
                  Aktif bildirim bulunmuyor
                </div>
              )}
            </div>
          </div>

          

          
        </div>
      </div>

      {/* Update Owner Modal */}
      <Modal
        isOpen={updateModalIsOpen}
        onRequestClose={() => setUpdateModalIsOpen(false)}
        style={customModalStyles}
        contentLabel="Müşteri Bilgilerini Güncelle"
      >
        <UpdateOwnerForm
          owner={ownerDetails}
          onClose={() => setUpdateModalIsOpen(false)}
          onSuccess={() => {
            setUpdateModalIsOpen(false);
            fetchOwnerDetails();
          }}
        />
      </Modal>

      {/* New Pet Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customModalStyles}
        contentLabel="Yeni Evcil Hayvan"
      >
        <div className="relative p-4">
          <NewPetForm
            onClose={() => setModalIsOpen(false)}
            onSuccess={() => {
              setModalIsOpen(false);
              fetchOwnerDetails();
            }}
            defaultOwnerId={owner_id}
          />
          <button
            onClick={handleModalClose}
            className="absolute top-0 right-0 text-gray-600 hover:text-gray-800"
          >
            ✕
          </button>
          <h2 className="text-2xl font-bold mb-4">
            Yeni Evcil Hayvan Ekle
          </h2>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleAddItem('pet', { ...formData, owner_id });
          }} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">İsim</label>
              <input
                type="text"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.name || ''}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Tür</label>
              <input
                type="text"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.species || ''}
                onChange={(e) => setFormData({ ...formData, species: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Cins</label>
              <input
                type="text"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.breed || ''}
                onChange={(e) => setFormData({ ...formData, breed: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Yaş</label>
              <input
                type="number"
                required
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.age || ''}
                onChange={(e) => setFormData({ ...formData, age: parseInt(e.target.value) })}
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={handleModalClose}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
              >
                İptal
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                Kaydet
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default PetOwnerDetailsScreen;
