import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Divider
} from '@mui/material';
import { format } from 'date-fns';

const StockOperationModal = ({ isOpen, onClose, type, products, onSubmit }) => {
  const [formData, setFormData] = useState({
    // Ortak alanlar
    operationDate: format(new Date(), 'yyyy-MM-dd'),
    operationNumber: '',
    description: '',
    warehouse: '',
    barcode: '',
    serialNumber: '',
    unit: '',
    quantity: '',
    
    // Yeni ürün için ek alanlar
    productName: '',
    productType: '',
    multiplier: '',
    isPrescriptionProduct: false,
    productGroup: '',
    purchasePrice: '',
    includePurchaseVAT: false,
    salePrice: '',
    includeSaleVAT: false,
    notificationQuantity: '',
    info: '',
    
    // Stok giriş/çıkış için ek alanlar
    product: '',
    expiryDate: '',
    factor: '',
    currentStock: '',
    outputQuantity: ''
  });

  const [isNewProduct, setIsNewProduct] = useState(false);

  useEffect(() => {
    if (type === 'new') {
      setIsNewProduct(true);
    } else {
      setIsNewProduct(false);
    }
  }, [type]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: e.target.type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
    onClose();
  };

  const renderNewProductForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Yeni Ürün Bilgileri</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Ürün Adı"
          name="productName"
          value={formData.productName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Ürün Tipi</InputLabel>
          <Select
            name="productType"
            value={formData.productType}
            onChange={handleChange}
          >
            <MenuItem value="ilaç">İlaç</MenuItem>
            <MenuItem value="malzeme">Malzeme</MenuItem>
            <MenuItem value="aşı">Aşı</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Birim"
          name="unit"
          value={formData.unit}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Çarpan"
          name="multiplier"
          type="number"
          value={formData.multiplier}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="isPrescriptionProduct"
              checked={formData.isPrescriptionProduct}
              onChange={handleChange}
            />
          }
          label="Reçete Ürünü"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Barkod"
          name="barcode"
          value={formData.barcode}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Ürün Grubu</InputLabel>
          <Select
            name="productGroup"
            value={formData.productGroup}
            onChange={handleChange}
          >
            <MenuItem value="antibiyotik">Antibiyotik</MenuItem>
            <MenuItem value="vitamin">Vitamin</MenuItem>
            <MenuItem value="aşı">Aşı</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Alış Fiyatı"
          name="purchasePrice"
          type="number"
          value={formData.purchasePrice}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              name="includePurchaseVAT"
              checked={formData.includePurchaseVAT}
              onChange={handleChange}
            />
          }
          label="KDV Dahil (Alış)"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Satış Fiyatı"
          name="salePrice"
          type="number"
          value={formData.salePrice}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              name="includeSaleVAT"
              checked={formData.includeSaleVAT}
              onChange={handleChange}
            />
          }
          label="KDV Dahil (Satış)"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Bildirim Miktarı"
          name="notificationQuantity"
          type="number"
          value={formData.notificationQuantity}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Bilgi"
          name="info"
          multiline
          rows={2}
          value={formData.info}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );

  const renderStockInputForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Stok Giriş Bilgileri</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="date"
          label="İşlem Tarihi"
          name="operationDate"
          value={formData.operationDate}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="İşlem No"
          name="operationNumber"
          value={formData.operationNumber}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Ürün</InputLabel>
          <Select
            name="product"
            value={formData.product}
            onChange={handleChange}
          >
            {products.map(product => (
              <MenuItem key={product._id} value={product._id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Barkod"
          name="barcode"
          value={formData.barcode}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Depo</InputLabel>
          <Select
            name="warehouse"
            value={formData.warehouse}
            onChange={handleChange}
          >
            <MenuItem value="ana-depo">Ana Depo</MenuItem>
            <MenuItem value="yedek-depo">Yedek Depo</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Birim"
          name="unit"
          value={formData.unit}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="date"
          label="Miat"
          name="expiryDate"
          value={formData.expiryDate}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Faktör"
          name="factor"
          type="number"
          value={formData.factor}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Çarpan"
          name="multiplier"
          type="number"
          value={formData.multiplier}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Miktar"
          name="quantity"
          type="number"
          value={formData.quantity}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Seri No"
          name="serialNumber"
          value={formData.serialNumber}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Açıklama"
          name="description"
          multiline
          rows={2}
          value={formData.description}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );

  const renderStockOutputForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Stok Çıkış Bilgileri</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="date"
          label="İşlem Tarihi"
          name="operationDate"
          value={formData.operationDate}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="İşlem No"
          name="operationNumber"
          value={formData.operationNumber}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Açıklama"
          name="description"
          multiline
          rows={2}
          value={formData.description}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Depo</InputLabel>
          <Select
            name="warehouse"
            value={formData.warehouse}
            onChange={handleChange}
          >
            <MenuItem value="ana-depo">Ana Depo</MenuItem>
            <MenuItem value="yedek-depo">Yedek Depo</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>Ürün</InputLabel>
          <Select
            name="product"
            value={formData.product}
            onChange={handleChange}
          >
            {products.map(product => (
              <MenuItem key={product._id} value={product._id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Barkod"
          name="barcode"
          value={formData.barcode}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Birim"
          name="unit"
          value={formData.unit}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Stok Miktarı"
          name="currentStock"
          type="number"
          value={formData.currentStock}
          onChange={handleChange}
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Çıkış Miktarı"
          name="outputQuantity"
          type="number"
          value={formData.outputQuantity}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="date"
          label="Miat"
          name="expiryDate"
          value={formData.expiryDate}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Seri No"
          name="serialNumber"
          value={formData.serialNumber}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {type === 'new' && 'Yeni Ürün Ekle'}
        {type === 'add' && 'Stok Girişi'}
        {type === 'remove' && 'Stok Çıkışı'}
      </DialogTitle>
      <DialogContent>
        <div className="mt-4">
          {type === 'new' && renderNewProductForm()}
          {type === 'add' && renderStockInputForm()}
          {type === 'remove' && renderStockOutputForm()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {type === 'new' ? 'Ekle' : 'Kaydet'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockOperationModal;
