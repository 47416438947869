import React from 'react';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { FaUpload, FaDownload, FaRegFileAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

const DocumentsTab = ({ documents }) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-bold">Ek Belgeler ve Dosyalar</h3>
        <button
          onClick={() => toast.info('Dosya yükleme özelliği yakında eklenecek')}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center"
        >
          <FaUpload className="mr-2" />
          Dosya Yükle
        </button>
      </div>
      <div className="space-y-4">
        {documents.map(doc => (
          <div key={doc.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center">
              <FaRegFileAlt className="text-gray-500 mr-3" />
              <div>
                <p className="font-semibold">{doc.name}</p>
                <p className="text-sm text-gray-500">
                  {format(new Date(doc.date), 'dd MMMM yyyy', { locale: tr })} - {doc.size}
                </p>
              </div>
            </div>
            <button
              onClick={() => toast.info('İndirme özelliği yakında eklenecek')}
              className="text-blue-500 hover:text-blue-600"
            >
              <FaDownload />
            </button>
          </div>
        ))}
        {documents.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            Henüz belge bulunmamaktadır.
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentsTab;
