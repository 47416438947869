import React from 'react';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { FaCreditCard } from 'react-icons/fa';
import { toast } from 'react-toastify';

const FinanceTab = ({ financeData }) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div className="bg-red-50 p-4 rounded-lg">
          <h4 className="text-lg font-semibold text-red-700 mb-2">Toplam Borç</h4>
          <p className="text-2xl font-bold text-red-600">{financeData.totalDebt} TL</p>
        </div>
        <div className="bg-green-50 p-4 rounded-lg">
          <h4 className="text-lg font-semibold text-green-700 mb-2">Son Ödeme</h4>
          <p className="text-2xl font-bold text-green-600">{financeData.lastPayment.amount} TL</p>
          <p className="text-sm text-green-600">
            {format(new Date(financeData.lastPayment.date), 'dd MMMM yyyy', { locale: tr })}
          </p>
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Ödeme Geçmişi</h3>
          <button
            onClick={() => toast.info('Ödeme alma özelliği yakında eklenecek')}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 flex items-center"
          >
            <FaCreditCard className="mr-2" />
            Ödeme Al
          </button>
        </div>
        {financeData.payments.map(payment => (
          <div key={payment.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
            <div>
              <p className="font-semibold">{payment.description}</p>
              <p className="text-sm text-gray-500">
                {format(new Date(payment.date), 'dd MMMM yyyy', { locale: tr })}
              </p>
            </div>
            <span className="font-bold text-green-600">{payment.amount} TL</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinanceTab;
