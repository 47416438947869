import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUserCircle, FaPhone, FaEnvelope, FaUser, FaPalette, FaTrash, FaUserPlus } from "react-icons/fa";

function Profile() {
  const [userData, setUserData] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    username: "",
    role: "",
    color: "",
  });
  const [selectedColor, setSelectedColor] = useState("");
  const token = localStorage.getItem("authToken");
  const userId = localStorage.getItem("user_id");

  const [users, setUsers] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    username: "",
    password: "",
    role: "user",
    color: "blue"
  });

  const colorOptions = [
    { name: "blue", hex: "#3B82F6" },
    { name: "green", hex: "#10B981" },
    { name: "red", hex: "#EF4444" },
    { name: "purple", hex: "#8B5CF6" },
    { name: "orange", hex: "#F97316" }
  ];

  useEffect(() => {
    const initializeData = async () => {
      await fetchUserData();
      if (userData.role === 'admin') {
        fetchUsers();
      }
    };
    initializeData();
  }, []);

  useEffect(() => {
    if (userData.role === 'admin') {
      fetchUsers();
    }
  }, [userData.role]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://api.dev1.fugesoft.com/api/users/${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserData(response.data);
      setSelectedColor(response.data.color);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleSaveColor = async () => {
    try {
      const updatedData = { ...userData, color: selectedColor };
      await axios.put(
        `https://api.dev1.fugesoft.com/api/users/${userId}`,
        updatedData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserData(updatedData);
      alert("Renk başarıyla güncellendi!");
    } catch (error) {
      console.error("Error updating color:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://api.dev1.fugesoft.com/api/users",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Extract users array from the response
      setUsers(response.data.users || []);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleCreateUser = async () => {
    try {
      await axios.post(
        "https://api.dev1.fugesoft.com/api/users",
        newUser,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setShowCreateModal(false);
      setNewUser({
        name: "",
        surname: "",
        phone: "",
        email: "",
        username: "",
        password: "",
        role: "user",
        color: "blue"
      });
      fetchUsers();
      alert("Kullanıcı başarıyla oluşturuldu!");
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Kullanıcı oluşturulurken bir hata oluştu!");
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm("Bu kullanıcıyı silmek istediğinizden emin misiniz?")) {
      try {
        await axios.delete(
          `https://api.dev1.fugesoft.com/api/users/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        fetchUsers();
        alert("Kullanıcı başarıyla silindi!");
      } catch (error) {
        console.error("Error deleting user:", error);
        alert("Kullanıcı silinirken bir hata oluştu!");
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto">
        {/* Profil Başlığı */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex items-center">
            <div className="relative">
              <FaUserCircle
                className={`text-7xl text-${selectedColor}-500 transition-colors duration-300`}
              />
              <div className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 border-2 border-white rounded-full"></div>
            </div>
            <div className="ml-6">
              <h1 className="text-2xl font-bold text-gray-900">{`${userData.name} ${userData.surname}`}</h1>
              <p className="text-gray-500 mt-1">{userData.role}</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Kişisel Bilgiler */}
          <div className="md:col-span-2">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Kişisel Bilgiler</h2>
              <div className="space-y-4">
                <div className="flex items-center p-3 bg-gray-50 rounded-lg">
                  <FaUser className="text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">Ad Soyad</p>
                    <p className="font-medium text-gray-900">{`${userData.name} ${userData.surname}`}</p>
                  </div>
                </div>
                
                <div className="flex items-center p-3 bg-gray-50 rounded-lg">
                  <FaPhone className="text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">Telefon</p>
                    <p className="font-medium text-gray-900">{userData.phone}</p>
                  </div>
                </div>

                <div className="flex items-center p-3 bg-gray-50 rounded-lg">
                  <FaEnvelope className="text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">E-posta</p>
                    <p className="font-medium text-gray-900">{userData.email}</p>
                  </div>
                </div>

                <div className="flex items-center p-3 bg-gray-50 rounded-lg">
                  <FaUser className="text-gray-400 mr-3" />
                  <div>
                    <p className="text-sm text-gray-500">Kullanıcı Adı</p>
                    <p className="font-medium text-gray-900">{userData.username}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Renk Seçimi */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center mb-6">
              <FaPalette className="text-gray-400 mr-2" />
              <h2 className="text-xl font-semibold text-gray-900">Tema Rengi</h2>
            </div>
            <div className="space-y-4">
              <div className="grid grid-cols-5 gap-3">
                {colorOptions.map((color) => (
                  <button
                    key={color.name}
                    onClick={() => handleColorChange(color.name)}
                    className={`w-full aspect-square rounded-lg transition-all duration-200 ${
                      selectedColor === color.name 
                        ? 'ring-2 ring-offset-2 ring-gray-900' 
                        : 'hover:scale-110'
                    }`}
                    style={{ backgroundColor: color.hex }}
                  />
                ))}
              </div>
              <button
                onClick={handleSaveColor}
                className="w-full mt-4 bg-gray-900 text-white py-2 px-4 rounded-lg hover:bg-gray-800 transition-colors duration-200"
              >
                Rengi Kaydet
              </button>
            </div>
          </div>
        </div>

        {/* Admin Section - Only visible for admin users */}
        {userData.role === 'admin' && (
          <div className="mt-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900">Klinik Kullanıcıları</h2>
                <button
                  onClick={() => setShowCreateModal(true)}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <FaUserPlus />
                  Yeni Kullanıcı Ekle
                </button>
              </div>

              {/* Users Table */}
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Ad Soyad</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Kullanıcı Adı</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Rol</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Renk</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">E-posta</th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">İşlemler</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user) => (
                      <tr key={user._id}>
                        <td className="px-6 py-4 whitespace-nowrap">{`${user.name || ''} ${user.surname || ''}`}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 rounded-full text-xs ${
                            user.role === 'admin' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'
                          }`}>
                            {user.role}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user.color && (
                            <span 
                              className={`px-2 py-1 rounded-full text-xs bg-${user.color}-100 text-${user.color}-800 capitalize`}
                            >
                              {user.color}
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <button
                            onClick={() => handleDeleteUser(user._id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Create User Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">Yeni Kullanıcı Oluştur</h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Ad</label>
                <input
                  type="text"
                  value={newUser.name}
                  onChange={(e) => setNewUser({...newUser, name: e.target.value})}
                  className="w-full p-2 border rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Soyad</label>
                <input
                  type="text"
                  value={newUser.surname}
                  onChange={(e) => setNewUser({...newUser, surname: e.target.value})}
                  className="w-full p-2 border rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Telefon</label>
                <input
                  type="text"
                  value={newUser.phone}
                  onChange={(e) => setNewUser({...newUser, phone: e.target.value})}
                  className="w-full p-2 border rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">E-posta</label>
                <input
                  type="email"
                  value={newUser.email}
                  onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                  className="w-full p-2 border rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Kullanıcı Adı</label>
                <input
                  type="text"
                  value={newUser.username}
                  onChange={(e) => setNewUser({...newUser, username: e.target.value})}
                  className="w-full p-2 border rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Şifre</label>
                <input
                  type="password"
                  value={newUser.password}
                  onChange={(e) => setNewUser({...newUser, password: e.target.value})}
                  className="w-full p-2 border rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Rol</label>
                <select
                  value={newUser.role}
                  onChange={(e) => setNewUser({...newUser, role: e.target.value})}
                  className="w-full p-2 border rounded-md"
                >
                  <option value="user">Kullanıcı</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setShowCreateModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                İptal
              </button>
              <button
                onClick={handleCreateUser}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Oluştur
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
