import React, { useState, useEffect } from 'react';
import {
  FaTasks,
  FaUserCheck,
  FaMoneyBillWave,
  FaFileAlt,
  FaClipboardList,
  FaComments,
  FaCalendar,
  FaWallet,
  FaCreditCard,
  FaRegBell,
  FaSmile,
  FaAngry,
  FaMeh,
  FaRegFileAlt,
  FaRegClock,
  FaStethoscope,
  FaExclamationTriangle,
  FaPaw,FaTimes
} from 'react-icons/fa';
import Modal from 'react-modal';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import PetDetailsTab from './components/PetDetailsTab';
import DocumentsTab from './components/DocumentsTab';
import FinanceTab from './components/FinanceTab';
import AppointmentsTab from './components/AppointmentsTab';
import NotesTab from './components/NotesTab';
import TreatmentProcessTab from './components/TreatmentProcessTab';
import TreatmentHistoryView from './components/TreatmentHistoryView';

Modal.setAppElement('#root');

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.5rem',
    padding: '2rem',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000
  }
};

function PetDetails() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalNotes, setModalNotes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [pet, setPet] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [newNote, setNewNote] = useState('');
  const [activeTab, setActiveTab] = useState('details');
  const [behaviorNote, setBehaviorNote] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const { petId } = useParams();
  const navigate = useNavigate();
  const [treatmentHistoryModalOpen, setTreatmentHistoryModalOpen] = useState(false);

  // Mock data
  const mockDocuments = [
    { id: 1, name: 'Röntgen Sonucu.pdf', type: 'xray', date: '2024-01-20', size: '2.4 MB' },
    { id: 2, name: 'Kan Tahlili.pdf', type: 'lab', date: '2024-01-15', size: '1.1 MB' },
    { id: 3, name: 'Aşı Kartı.pdf', type: 'vaccine', date: '2024-01-10', size: '0.8 MB' },
  ];

  const mockFinance = {
    totalDebt: 2500,
    lastPayment: { date: '2024-01-15', amount: 450 },
    payments: [
      { id: 1, date: '2024-01-15', amount: 450, description: 'Rutin kontrol' },
      { id: 2, date: '2024-01-10', amount: 850, description: 'Aşı uygulaması' },
    ],
  };

  const mockAppointments = [
    { id: 1, date: '2024-02-01', time: '14:30', type: 'Kontrol', status: 'upcoming' },
    { id: 2, date: '2024-01-20', time: '11:00', type: 'Aşı', status: 'completed' },
  ];

  const mockBehavior = {
    temperament: 'Sakin',
    aggressionLevel: 'Düşük',
    notes: [
      { id: 1, date: '2024-01-20', note: 'Diğer hayvanlarla iyi anlaşıyor', mood: 'positive' },
      { id: 2, date: '2024-01-15', note: 'Muayene sırasında gergin', mood: 'neutral' },
    ],
  };

  useEffect(() => {
    fetchPetDetails();
    fetchAppointments();
  }, [petId]);

  const fetchPetDetails = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`https://api.dev1.fugesoft.com/api/pets/${petId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPet(response.data);
      setEditableFields(response.data);
    } catch (error) {
      toast.error("Hasta bilgileri yüklenirken bir hata oluştu");
      console.error("Error fetching pet details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`https://api.dev1.fugesoft.com/api/appointments/pet/${petId}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      
      if (response.data && Array.isArray(response.data.appointments)) {
        // Tarihe göre sırala (en yeni en üstte)
        const sortedAppointments = response.data.appointments.sort((a, b) => {
          const dateA = new Date(a.date + 'T' + a.time);
          const dateB = new Date(b.date + 'T' + b.time);
          return dateB - dateA;
        });

        setAppointments(sortedAppointments);
      } else {
        console.error('Invalid appointments response:', response.data);
        setAppointments([]);
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
      toast.error('Randevu bilgileri yüklenirken bir hata oluştu');
      setAppointments([]);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditableFields(pet);
    }
  };

  const handleInputChange = (field, value) => {
    setEditableFields(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.put(
        `https://api.dev1.fugesoft.com/api/pets/${petId}`,
        editableFields,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      await fetchPetDetails();
      setIsEditing(false);
      toast.success("Hasta bilgileri başarıyla güncellendi");
    } catch (error) {
      toast.error("Bilgiler güncellenirken bir hata oluştu");
      console.error("Error updating pet details:", error);
    }
  };

  const handleDischarge = () => {
    // TODO: Implement discharge logic
    toast.info('Taburcu işlemi gerçekleştirildi.');
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = pet.owner_phone?.replace(/\D/g, '');
    if (phoneNumber) {
      window.open(`https://wa.me/${phoneNumber}`, '_blank');
    }
  };

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
    setModalNotes([]);
  };

  const addNote = () => {
    if (newNote.trim()) {
      setModalNotes(prev => [...prev, newNote]);
      setNewNote('');
    }
  };

  const categories = [
    {
      title: 'Muayeneler',
      icon: <FaStethoscope className="text-blue-500" />,
      bgColor: 'bg-blue-50',
      hoverColor: 'hover:bg-blue-100',
      onClick: () => setTreatmentHistoryModalOpen(true)
    },
    {
      title: 'İstek',
      icon: <FaTasks className="text-blue-500" />,
      onClick: () => navigate(`/istek-pet/${petId}`),
      bgColor: 'bg-blue-50',
      hoverColor: 'hover:bg-blue-100'
    },
    {
      title: 'Ödeme Al',
      icon: <FaMoneyBillWave className="text-gray-400" />,
      bgColor: 'bg-gray-50',
      hoverColor: '',
      disabled: true
    },
    {
      title: 'Döküman Ekle',
      icon: <FaFileAlt className="text-gray-400" />,
      bgColor: 'bg-gray-50',
      hoverColor: '',
      disabled: true
    },
    {
      title: 'Order',
      icon: <FaClipboardList className="text-gray-400" />,
      bgColor: 'bg-gray-50',
      hoverColor: '',
      disabled: true
    },
    {
      title: 'Görüşme/Yorum Ekle',
      icon: <FaComments className="text-gray-400" />,
      bgColor: 'bg-gray-50',
      hoverColor: '',
      disabled: true
    },
  ];

  // Pagination için current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAppointments = appointments.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(appointments.length / itemsPerPage);

  // Randevu durumuna göre renk belirleme
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'tamamlandı':
        return 'bg-green-100 text-green-800';
      case 'yapılacak':
        return 'bg-yellow-100 text-yellow-800';
      case 'iptal':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!pet) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <FaExclamationTriangle className="text-6xl text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-700">Hasta bulunamadı</h2>
        <p className="text-gray-500 mt-2">İstenen hasta bilgilerine ulaşılamadı</p>
        <button
          onClick={() => navigate('/pet-list')}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Hasta Listesine Dön
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8 mt-6">
        {/* Navigation Tabs */}
        <div className="flex space-x-4 mb-4 overflow-x-auto pb-2 mt-4">
          <button
            onClick={() => setActiveTab('details')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'details' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaPaw className="mr-2" />
            Detaylar
          </button>
          <button
            disabled
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 opacity-50 cursor-not-allowed ${
              activeTab === 'documents' 
                ? 'bg-gray-400 text-white shadow-lg' 
                : 'bg-white text-gray-500'
            }`}
          >
            <FaRegFileAlt className="mr-2" />
            Dökümanlar
            <span className="ml-2 text-xs">(Yakında)</span>
          </button>
          <button
            disabled
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 opacity-50 cursor-not-allowed ${
              activeTab === 'finance' 
                ? 'bg-gray-400 text-white shadow-lg' 
                : 'bg-white text-gray-500'
            }`}
          >
            <FaWallet className="mr-2" />
            Finans
            <span className="ml-2 text-xs">(Yakında)</span>
          </button>
          <button
            onClick={() => setActiveTab('appointments')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'appointments' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaRegClock className="mr-2" />
            Randevular
          </button>
          <button
            disabled
            className="px-6 py-3 rounded-lg flex items-center transition-all duration-200 bg-gray-100 text-gray-400 shadow-sm cursor-not-allowed opacity-50"
          >
            <FaClipboardList className="mr-2" />
            Notlar ve Yorumlar
            <span className="ml-1 text-xs">(Yakında)</span>
          </button>
          <button
            onClick={() => setActiveTab('kanban')}
            className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${
              activeTab === 'kanban' 
                ? 'bg-blue-600 text-white shadow-lg transform scale-105' 
                : 'bg-white hover:bg-blue-50 text-gray-700 shadow-sm hover:shadow'
            }`}
          >
            <FaTasks className="mr-2" />
            Tedavi Süreci
          </button>
        </div>

        {/* Tab Content */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          {activeTab === 'details' && (
            <PetDetailsTab 
              pet={pet}
              isEditing={isEditing}
              editableFields={editableFields}
              handleInputChange={handleInputChange}
              handleSave={handleSave}
              handleEditToggle={handleEditToggle}
              handleDischarge={handleDischarge}
              handleWhatsAppClick={handleWhatsAppClick}
              categories={categories}
            />
          )}

          {activeTab === 'documents' && (
            <DocumentsTab documents={mockDocuments} />
          )}

          {activeTab === 'finance' && (
            <FinanceTab financeData={mockFinance} />
          )}

          {activeTab === 'appointments' && (
            <AppointmentsTab 
              appointments={appointments}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
              getStatusColor={getStatusColor}
            />
          )}

          {activeTab === 'notes' && (
            <NotesTab 
              newNote={newNote}
              setNewNote={setNewNote}
              behaviorNote={behaviorNote}
              setBehaviorNote={setBehaviorNote}
              mockBehavior={mockBehavior}
            />
          )}

          {activeTab === 'kanban' && (
            <TreatmentProcessTab 
              petId={petId}
              treatmentHistoryModalOpen={treatmentHistoryModalOpen}
              setTreatmentHistoryModalOpen={setTreatmentHistoryModalOpen}
            />
          )}
        </div>
      </div>

      {/* Treatment History Modal */}
      <TreatmentHistoryView
        petId={petId}
        isOpen={treatmentHistoryModalOpen}
        onRequestClose={() => setTreatmentHistoryModalOpen(false)}
      />
    </div>
  );
}

// Info Field Component
const InfoField = ({
  icon: Icon,
  iconColor,
  label,
  value,
  isEditing,
  field,
  onChange,
  editableFields,
  type = 'text',
  multiline = false
}) => (
  <div className="relative">
    <div className="flex items-center space-x-2 text-sm text-gray-500 mb-1">
      <Icon className={iconColor} />
      <span>{label}</span>
    </div>
    {isEditing ? (
      multiline ? (
        <textarea
          value={editableFields[field] || ''}
          onChange={(e) => onChange(field, e.target.value)}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
          rows="3"
        />
      ) : (
        <input
          type={type}
          value={editableFields[field] || ''}
          onChange={(e) => onChange(field, e.target.value)}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
        />
      )
    ) : (
      <p className="font-medium text-gray-800">{value || 'Belirtilmedi'}</p>
    )}
  </div>
);

export default PetDetails;
