import React, { useState, useEffect } from "react";
import {
  FaCalendarPlus,
  FaUserPlus,
  FaShoppingCart,
  FaFileAlt,
  FaRandom,
  FaSyncAlt,
  FaBell,
  FaUserClock,
  FaNotesMedical,
  FaPrescriptionBottleAlt,
  FaSyringe
} from "react-icons/fa";
import NewPetForm from "../../CustomerList/NewPetForm";
import CustomerReminderModal from "./ActionButtonModals/CustomerReminderModal";
import SalesModal from "./ActionButtonModals/SalesModal";
import AppointmentModal from "../../../../components/AppointmentModal";
import TreatmentModal from "./ActionButtonModals/TreatmentModal";
import UpcomingVaccinesModal from "../components/UpcomingVaccinesModal";
import axios from "axios";
import moment from "moment";
import Modal from "react-modal";

function ActionButtons() {
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showNewPatientModal, setShowNewPatientModal] = useState(false);
  const [showCustomerReminderModal, setShowCustomerReminderModal] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [showTreatmentModal, setShowTreatmentModal] = useState(false);
  const [showUpcomingVaccinesModal, setShowUpcomingVaccinesModal] = useState(false);

  const [newEvent, setNewEvent] = useState({
    petId: "",
    owner_name: "",
    pet_name: "",
    description: "",
    date: moment().format("YYYY-MM-DD"),
    startTime: "",
    endTime: "",
    title: "",
    assignedTo: "",
    veterinarian: "",
    color: "blue"
  });

  const [pets, setPets] = useState([]);
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    fetchPets();
    fetchUsers();
  }, []);

  const fetchPets = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/owners/all", {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      // Sahipleri alfabetik sırala
      const sortedOwners = response.data.sort((a, b) => a.name.localeCompare(b.name));

      const petOptions = sortedOwners
        .filter(owner => owner.pets && owner.pets.length > 0)
        .flatMap(owner => 
          owner.pets.map(pet => ({
            value: pet.id,
            label: `${owner.name} - ${pet.name}${owner.protocol_no ? ` (${owner.protocol_no})` : ''}`,
            ownerName: owner.name,
            petName: pet.name,
            petId: pet.id,
            owner: {
              id: owner._id,
              name: owner.name,
              protocol_no: owner.protocol_no,
              email: owner.email || '',
              address: owner.address || ''
            }
          }))
        );
      setPets(petOptions);
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://api.dev1.fugesoft.com/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      const formattedUsers = (response.data?.users || [])
        .filter(user => user.role === 'vet' || user.role === 'admin')
        .map(user => ({
          value: user.id,
          label: user.name && user.surname 
            ? `${user.name} ${user.surname}` 
            : user.username,
          color: user.color || 'blue',
          id: user.id,
          name: user.name,
          surname: user.surname,
          username: user.username,
          role: user.role
        }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAddEvent = async (appointmentData) => {
    try {
      const response = await axios.post(
        'https://api.dev1.fugesoft.com/api/appointments',
        appointmentData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        alert('Randevu başarıyla oluşturuldu');
        setShowAppointmentModal(false);
      }
    } catch (error) {
      console.error('API Error Details:', error.response?.data);
      alert('Randevu oluşturulurken bir hata oluştu');
    }
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {/* Randevu Oluştur */}
      <button
        onClick={() => setShowAppointmentModal(true)}
        className="group bg-gradient-to-br from-blue-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-blue-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaCalendarPlus className="text-blue-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Randevu Oluştur</h3>
        </div>
      </button>

      {/* Yeni Hasta */}
      <button
        onClick={() => setShowNewPatientModal(true)}
        className="group bg-gradient-to-br from-green-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-green-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaUserPlus className="text-green-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Yeni Hasta</h3>
        </div>
      </button>

      {/* Satış Yap */}
      <button
        disabled
        className="group bg-gradient-to-br from-purple-50 to-white p-4 rounded-2xl shadow-lg opacity-50 cursor-not-allowed"
      >
        <div className="flex flex-col items-center">
          <div className="bg-purple-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaShoppingCart className="text-purple-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Satış Yap</h3>
        </div>
      </button>

      {/* Aylık Rapor */}
      <button
        disabled
        className="group bg-gradient-to-br from-yellow-50 to-white p-4 rounded-2xl shadow-lg opacity-50 cursor-not-allowed"
      >
        <div className="flex flex-col items-center">
          <div className="bg-yellow-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaFileAlt className="text-yellow-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Aylık Rapor</h3>
        </div>
      </button>

      

      {/* Order Oluştur */}
      <button
        disabled
        className="group bg-gradient-to-br from-indigo-50 to-white p-4 rounded-2xl shadow-lg opacity-50 cursor-not-allowed"
      >
        <div className="flex flex-col items-center">
          <div className="bg-indigo-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaPrescriptionBottleAlt className="text-indigo-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Order Oluştur</h3>
        </div>
      </button>

      {/* Tedavi Kayıt */}
      <button
        onClick={() => setShowTreatmentModal(true)}
        className="group bg-gradient-to-br from-teal-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-teal-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaNotesMedical className="text-teal-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Tedavi Kayıt</h3>
        </div>
      </button>

      {/* Müşteri Hatırlatma */}
      <button
        disabled
        className="group bg-gradient-to-br from-pink-50 to-white p-4 rounded-2xl shadow-lg opacity-50 cursor-not-allowed"
      >
        <div className="flex flex-col items-center">
          <div className="bg-pink-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaUserClock className="text-pink-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Müşteri Hatırlatma</h3>
        </div>
      </button>

      {/* Yaklaşan Aşılar */}
      <button
        onClick={() => setShowUpcomingVaccinesModal(true)}
        className="group bg-gradient-to-br from-purple-50 to-white p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      >
        <div className="flex flex-col items-center">
          <div className="bg-purple-100/80 p-3 rounded-2xl mb-3 group-hover:scale-110 transition-transform duration-300">
            <FaSyringe className="text-purple-600 text-2xl" />
          </div>
          <h3 className="text-gray-700 font-medium text-center">Yaklaşan Aşılar</h3>
        </div>
      </button>

      {/* Modals */}
      {showAppointmentModal && (
        <AppointmentModal
          isOpen={showAppointmentModal}
          onRequestClose={() => setShowAppointmentModal(false)}
          pets={pets}
          users={users}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
          handleAddEvent={handleAddEvent}
        />
      )}
      {showNewPatientModal && (
        <Modal
          isOpen={showNewPatientModal}
          onRequestClose={() => setShowNewPatientModal(false)}
          style={{
            content: {
              width: '80%',
              maxWidth: '800px',
              margin: 'auto',
              height: 'fit-content',
              maxHeight: '90vh',
              overflow: 'auto',
              borderRadius: '8px',
              padding: '20px'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}
        >
          <NewPetForm
            onClose={() => setShowNewPatientModal(false)}
            onSuccess={() => {
              setShowNewPatientModal(false);
              fetchPets();
            }}
          />
        </Modal>
      )}
      {showCustomerReminderModal && (
        <CustomerReminderModal
          isOpen={showCustomerReminderModal}
          onClose={() => setShowCustomerReminderModal(false)}
        />
      )}
      {showSalesModal && (
        <SalesModal
          isOpen={showSalesModal}
          onClose={() => setShowSalesModal(false)}
        />
      )}
      {showTreatmentModal && (
        <TreatmentModal
          isOpen={showTreatmentModal}
          onClose={() => setShowTreatmentModal(false)}
        />
      )}
      {showUpcomingVaccinesModal && (
        <UpcomingVaccinesModal
          open={showUpcomingVaccinesModal}
          onClose={() => setShowUpcomingVaccinesModal(false)}
        />
      )}
    </div>
  );
}

export default ActionButtons;
