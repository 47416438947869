import React, { useState } from 'react';
import { FaPlus, FaSearch, FaFilter, FaSpinner, FaBoxOpen, FaExclamationTriangle, FaMoneyBillWave, FaClock, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import ProductModal from './components/ProductModal';
import StockOperationModal from './components/StockOperationModal';

// Dummy data
const DUMMY_PRODUCTS = [
  {
    _id: '1',
    name: 'Antibiyotik A',
    category: 'İlaç',
    quantity: 50,
    unit: 'Kutu',
    reorder_point: 10,
    expiry_date: '2024-12-31',
    supplier: 'Pharma Ltd.',
    price: 150.00,
    description: 'Geniş spektrumlu antibiyotik'
  },
  {
    _id: '2',
    name: 'Premium Kedi Maması',
    category: 'Mama',
    quantity: 5,
    unit: 'Kg',
    reorder_point: 10,
    expiry_date: '2024-06-30',
    supplier: 'Pet Food Co.',
    price: 250.00,
    description: 'Yetişkin kediler için premium mama'
  },
  {
    _id: '3',
    name: 'Köpek Tasması L',
    category: 'Aksesuar',
    quantity: 15,
    unit: 'Adet',
    reorder_point: 5,
    expiry_date: null,
    supplier: 'Pet Accessories Inc.',
    price: 120.00,
    description: 'Büyük boy köpekler için ayarlanabilir tasma'
  },
  {
    _id: '4',
    name: 'Steril Eldiven',
    category: 'Medikal Malzeme',
    quantity: 200,
    unit: 'Çift',
    reorder_point: 50,
    expiry_date: '2025-01-01',
    supplier: 'Medical Supplies Co.',
    price: 2.50,
    description: 'Tek kullanımlık steril eldiven'
  },
  {
    _id: '5',
    name: 'Vitamin B12',
    category: 'İlaç',
    quantity: 8,
    unit: 'Kutu',
    reorder_point: 15,
    expiry_date: '2024-08-15',
    supplier: 'Pharma Ltd.',
    price: 85.00,
    description: 'B12 vitamin takviyesi'
  }
];

function StockManagementScreen() {
  const [products, setProducts] = useState(DUMMY_PRODUCTS);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [operationType, setOperationType] = useState(null);

  // Calculate statistics
  const calculateStatistics = () => {
    const totalProducts = products.length;
    const lowStockProducts = products.filter(p => p.quantity <= p.reorder_point).length;
    const totalValue = products.reduce((sum, p) => sum + (p.quantity * p.price), 0);
    
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
    
    const expiringProducts = products.filter(p => {
      if (!p.expiry_date) return false;
      const expiryDate = new Date(p.expiry_date);
      return expiryDate <= thirtyDaysFromNow;
    }).length;

    return {
      totalProducts,
      lowStockProducts,
      totalValue,
      expiringProducts
    };
  };


// Yeni fonksiyonlar
const handleStockOperation = (type) => {
  setOperationType(type);
};

const handleSubmitStockOperation = (data) => {
  if (operationType === 'add') {
    // Stok ekleme işlemi
    setProducts(prev => prev.map(p => 
      p._id === data.product._id ? { ...p, quantity: p.quantity + data.quantity } : p
    ));
  } else if (operationType === 'remove') {
    // Stok çıkarma işlemi
    setProducts(prev => prev.map(p => 
      p._id === data.product._id ? { ...p, quantity: p.quantity - data.quantity } : p
    ));
  }
  setOperationType(null);
};

  const stats = calculateStatistics();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterCategory(e.target.value);
  };

  const handleAddProduct = () => {
    setSelectedProduct(null);
    setShowModal(true);
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleModalClose = (newProduct) => {
    if (newProduct) {
      if (selectedProduct) {
        // Edit existing product
        setProducts(prev => prev.map(p => 
          p._id === selectedProduct._id ? { ...newProduct, _id: selectedProduct._id } : p
        ));
      } else {
        // Add new product
        setProducts(prev => [...prev, { ...newProduct, _id: String(prev.length + 1) }]);
      }
    }
    setShowModal(false);
    setSelectedProduct(null);
  };

  const filteredProducts = products.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         product.category.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filterCategory === 'all' || product.category === filterCategory;
    return matchesSearch && matchesFilter;
  });

  const categories = ['İlaç', 'Mama', 'Aksesuar', 'Medikal Malzeme'];

  return (
    <div className="p-6">
      {/* Header ve diğer kısımlar aynı kalıyor */}

      
      
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Stok Yönetimi</h1>
        <button
          onClick={handleAddProduct}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700 transition-colors"
        >
          <FaPlus />
          Yeni Ürün Ekle
        </button>
        {/* Yeni Butonlar */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => handleStockOperation('new')}
          className="bg-green-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-green-700"
        >
          <FaPlus />
          Yeni Stok Ürünü Ekle
        </button>
        
        <button
          onClick={() => handleStockOperation('add')}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700"
        >
          <FaArrowUp />
          Stok Ekle
        </button>
        
        <button
          onClick={() => handleStockOperation('remove')}
          className="bg-red-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-red-700"
        >
          <FaArrowDown />
          Stok Çıkışı
        </button>
      </div>
      </div>




      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Toplam Ürün</p>
              <p className="text-2xl font-bold text-gray-900">{stats.totalProducts}</p>
            </div>
            <div className="bg-blue-100 p-3 rounded-full">
              <FaBoxOpen className="text-blue-600 text-xl" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Kritik Stok</p>
              <p className="text-2xl font-bold text-red-600">{stats.lowStockProducts}</p>
            </div>
            <div className="bg-red-100 p-3 rounded-full">
              <FaExclamationTriangle className="text-red-600 text-xl" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Toplam Değer</p>
              <p className="text-2xl font-bold text-green-600">{stats.totalValue.toLocaleString('tr-TR')} ₺</p>
            </div>
            <div className="bg-green-100 p-3 rounded-full">
              <FaMoneyBillWave className="text-green-600 text-xl" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">S.K.T. Yaklaşan</p>
              <p className="text-2xl font-bold text-orange-600">{stats.expiringProducts}</p>
            </div>
            <div className="bg-orange-100 p-3 rounded-full">
              <FaClock className="text-orange-600 text-xl" />
            </div>
          </div>
        </div>
      </div>

      {/* Search and Filter */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="relative">
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Ürün ara..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="relative">
          <FaFilter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <select
            value={filterCategory}
            onChange={handleFilterChange}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none bg-white"
          >
            <option value="all">Tüm Kategoriler</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {/* Products Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        {loading ? (
          <div className="flex items-center justify-center h-64">
            <FaSpinner className="animate-spin text-4xl text-blue-500" />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ürün Adı
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Kategori
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Stok Miktarı
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Birim
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Kritik Stok
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Son Kullanma Tarihi
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tedarikçi
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Fiyat
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredProducts.map((product) => (
                  <tr
                    key={product._id}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{product.name}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                        {product.category}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className={`text-sm ${product.quantity <= product.reorder_point ? 'text-red-600 font-medium' : 'text-gray-900'}`}>
                        {product.quantity}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product.unit}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product.reorder_point}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product.expiry_date ? new Date(product.expiry_date).toLocaleDateString('tr-TR') : '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product.supplier}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {product.price.toFixed(2)} ₺
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleEditProduct(product)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        Düzenle
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Product Modal */}
      <ProductModal
        isOpen={showModal}
        onClose={handleModalClose}
        product={selectedProduct}
      />

      {/* Yeni Modal Component'i */}
      <StockOperationModal
        isOpen={!!operationType}
        onClose={() => setOperationType(null)}
        type={operationType}
        products={products}
        onSubmit={handleSubmitStockOperation}
      />
    </div>
  );
}

export default StockManagementScreen; 