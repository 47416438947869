import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Alert
} from '@mui/material';
import { FaPlus, FaPencilAlt, FaTrash, FaCheck, FaRegCalendarAlt, FaSave } from 'react-icons/fa';

const mockVaccines = [
  { id: 1, name: 'Karma Aşı (FVRCP)', period: '4 hafta' },
  { id: 2, name: 'Kuduz Aşısı', period: '12 ay' },
  { id: 3, name: 'Lösemi Aşısı (FeLV)', period: '12 ay' },
  { id: 4, name: 'İç Parazit', period: '3 ay' },
  { id: 5, name: 'Dış Parazit', period: '15 gün' },
];

const formatPeriod = (value, type) => {
  // Türkçe karşılıklarını kullan
  const typeMap = {
    'Day': 'Gün',
    'Week': 'Hafta',
    'Month': 'Ay'
  };
  return `${value} ${typeMap[type] || type}`;
};

const normalizeDurationType = (type) => {
  // Türkçe değerleri İngilizce karşılıklarına çevir
  const typeMap = {
    'gün': 'Day',
    'hafta': 'Week',
    'ay': 'Month'
  };
  return typeMap[type.toLowerCase()] || type;
};

const AsiTakvimiScreen = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  
  // Edit modal states
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingVaccine, setEditingVaccine] = useState(null);
  const [editScheduleId, setEditScheduleId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    duration_value: '',
    duration_type: '',
    description: ''
  });

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        setFetchError(null);
        const response = await api.get('/vaccine-schedules/templates');
        setTemplates(response.data);
      } catch (err) {
        setFetchError(err.response?.data?.message || 'Şablonlar yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedVaccines, setSelectedVaccines] = useState([]);
  const [customVaccines, setCustomVaccines] = useState([]);

  const handleAddVaccine = (vaccineId) => {
    const vaccine = mockVaccines.find(v => v.id === vaccineId);
    const newVaccine = {
      id: vaccineId,
      name: vaccine.name,
      date: new Date().toISOString().split('T')[0],
      period: '1 gün' // Varsayılan değer
    };
    setCustomVaccines([...customVaccines, newVaccine]);
  };

  const handleRemoveVaccine = (index) => {
    const updated = [...customVaccines];
    updated.splice(index, 1);
    setCustomVaccines(updated);
  };

  const handleDateChange = (index, newDate) => {
    const updated = [...customVaccines];
    updated[index].date = newDate;
    setCustomVaccines(updated);
  };

  const handlePeriodValueChange = (index, value) => {
    const updated = [...customVaccines];
    const [_, type] = updated[index].period.split(' ');
    updated[index].period = `${value} ${type}`;
    setCustomVaccines(updated);
  };

  const handlePeriodTypeChange = (index, type) => {
    const updated = [...customVaccines];
    const [value] = updated[index].period.split(' ');
    updated[index].period = `${value} ${type}`;
    setCustomVaccines(updated);
  };

  const calculateNextDate = (startDate, period) => {
    const [value, unit] = period.split(' ');
    const date = new Date(startDate);
    
    if(unit === 'hafta') date.setDate(date.getDate() + (value * 7));
    if(unit === 'ay') date.setMonth(date.getMonth() + parseInt(value));
    
    return date.toISOString().split('T')[0];
  };

  const handleCreateTemplate = async () => {
    try {
      if (!templateName || !selectedType || customVaccines.length === 0) {
        setError('Lütfen tüm gerekli alanları doldurun');
        return;
      }

      setLoading(true);
      setError(null);

      // Veri doğrulama
      if (!templateName?.trim()) {
        throw new Error('Şablon adı boş olamaz');
      }

      if (!selectedType) {
        throw new Error('Hayvan türü seçilmedi');
      }

      if (!customVaccines?.length) {
        throw new Error('En az bir aşı eklemelisiniz');
      }

      const templateData = {
        name: templateName.trim(),
        animal_type: selectedType.toLowerCase(),
        vaccines: customVaccines.map(v => {
          if (!v.period || !v.name) {
            throw new Error(`Aşı bilgileri eksik: ${v.name || 'Isimsiz aşı'}`);
          }

          const [value, type] = v.period.split(' ');
          
          if (!value || isNaN(parseInt(value))) {
            throw new Error(`Geçersiz süre değeri: ${v.period}`);
          }

          if (!type) {
            throw new Error(`Geçersiz süre tipi: ${v.period}`);
          }

          const duration_type = normalizeDurationType(type);
          if (!duration_type) {
            throw new Error(`Geçersiz süre tipi: ${type}`);
          }

          return {
            name: v.name,
            duration_value: parseInt(value),
            duration_type,
            description: ''
          };
        })
      };

      // Debug log
      const response = await api.post('/vaccine-schedules/templates', templateData);
      
      if (response.data) {
        setTemplates(prevTemplates => [...prevTemplates, response.data]);
        handleCloseDialog();
      } else {
        throw new Error('Sunucudan geçersiz yanıt alındı');
      }
    } catch (err) {
      setError(
        err.response?.data?.message || 
        err.message || 
        'Şablon oluşturulurken bir hata oluştu'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEditVaccine = (templateId, vaccine) => {

    setEditScheduleId(vaccine.schedule_id);
    setEditingVaccine(vaccine);
    setEditFormData({
      duration_value: parseInt(vaccine.duration_value),
      duration_type: vaccine.duration_type.toLowerCase(),
      description: vaccine.description || ''
    });
    setEditModalOpen(true);
    setUpdateError(null);
  };

  const handleUpdateVaccine = async () => {
    try {
      setUpdateLoading(true);
      setUpdateError(null);

      const requestData = {
        duration_value: editFormData.duration_value,
        duration_type: editFormData.duration_type.charAt(0).toUpperCase() + editFormData.duration_type.slice(1),
        description: editFormData.description,
        name: editingVaccine.name
      };

      console.log('Gönderilen veri:', requestData);
      console.log('API endpoint:', `/vaccine-schedules/${editScheduleId}`);

      const response = await api.put(
        `/vaccine-schedules/${editScheduleId}`,
        {
          duration_value: editFormData.duration_value,
          duration_type: editFormData.duration_type.charAt(0).toUpperCase() + editFormData.duration_type.slice(1),
          description: editFormData.description,
          name: editingVaccine.name
        }
      );

      // Update the templates state with the new data
      setTemplates(prevTemplates =>
        prevTemplates.map(template => ({
          ...template,
          vaccines: template.vaccines.map(v =>
            v.schedule_id === editScheduleId
              ? {
                  ...v,
                  duration_value: editFormData.duration_value,
                  duration_type: editFormData.duration_type,
                  description: editFormData.description
                }
              : v
          )
        }))
      );

      console.log('API yanıtı:', response.data);
      handleCloseEditModal();
    } catch (err) {
      console.error('API hatası:', err.response?.data || err.message);
      console.error('Tam hata:', err);

      setUpdateError(
        err.response?.data?.message ||
        err.message ||
        'Aşı güncellenirken bir hata oluştu'
      );
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setEditingVaccine(null);
    setEditScheduleId(null);
    setEditFormData({
      duration_value: '',
      duration_type: '',
      description: ''
    });
    setUpdateError(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTemplateName('');
    setSelectedType('');
    setCustomVaccines([]);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Box sx={{ p: 3, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5">Aşı Takvimi Şablonları</Typography>
            <Button 
              variant="contained" 
              startIcon={<FaPlus />}
              onClick={() => setOpenDialog(true)}
            >
              Yeni Şablon
            </Button>
          </Box>
        </CardContent>
      </Card>

      {fetchError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {fetchError}
        </Alert>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      <Grid container spacing={3}>
        {templates.map(template => (
          <Grid item xs={12} md={6} lg={4} key={template._id}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6">{template.name}</Typography>
                  <Chip 
                    label={template.animal_type ? template.animal_type.charAt(0).toUpperCase() + template.animal_type.slice(1) : 'Belirtilmemiş'} 
                    color="primary" 
                  />
                </Box>

                <List dense sx={{ maxHeight: 300, overflow: 'auto' }}>
                  {template.vaccines.map((vaccine, index) => (
                    <ListItem 
                      key={index}
                      sx={{ 
                        borderBottom: '1px solid #e0e0e0',
                        '&:last-child': { borderBottom: 'none' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        gap: 1
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1">{vaccine.name}</Typography>
                          {vaccine.description && (
                            <Typography 
                              variant="body2" 
                              color="text.secondary"
                              sx={{ mt: 0.5 }}
                            >
                              {vaccine.description}
                            </Typography>
                          )}
                          <Typography 
                            variant="caption" 
                            color="text.secondary"
                            component="div"
                            sx={{ mt: 0.5 }}
                          >
                            Sıklık: {formatPeriod(vaccine.duration_value, vaccine.duration_type)}
                          </Typography>
                        </Box>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleEditVaccine(template._id, vaccine)}
                          startIcon={<FaPencilAlt />}
                        >
                          Düzenle
                        </Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Yeni Aşı Takvimi Şablonu</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="Şablon Adı"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              fullWidth
              disabled={loading}
            />
            
            <FormControl fullWidth disabled={loading}>
              <InputLabel>Hayvan Türü</InputLabel>
              <Select
                value={selectedType}
                label="Hayvan Türü"
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <MenuItem value="Kedi">Kedi</MenuItem>
                <MenuItem value="Köpek">Köpek</MenuItem>
                <MenuItem value="Diğer">Diğer</MenuItem>
              </Select>
            </FormControl>

            <Divider sx={{ my: 2 }} />

            <FormControl fullWidth>
              <InputLabel>Yeni Aşı Ekle</InputLabel>
              <Select
                value=""
                label="Yeni Aşı Ekle"
                onChange={(e) => handleAddVaccine(e.target.value)}
              >
                {mockVaccines.map(vaccine => (
                  <MenuItem key={vaccine.id} value={vaccine.id}>
                    {vaccine.name} ({vaccine.period})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {customVaccines.map((vaccine, index) => (
              <Box 
                key={index}
                sx={{ 
                  p: 2, 
                  border: '1px solid #e0e0e0', 
                  borderRadius: 1,
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center'
                }}
              >
                <Typography sx={{ flex: 1 }}>{vaccine.name}</Typography>
                
                <TextField
                  type="date"
                  label="İlk Tarih"
                  value={vaccine.date}
                  onChange={(e) => handleDateChange(index, e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <TextField
                    type="number"
                    label="Değer"
                    value={vaccine.period.split(' ')[0]}
                    onChange={(e) => handlePeriodValueChange(index, e.target.value)}
                    inputProps={{ min: 1 }}
                    sx={{ width: 100 }}
                  />
                  <FormControl sx={{ width: 120 }}>
                    <InputLabel>Birim</InputLabel>
                    <Select
                      value={vaccine.period.split(' ')[1]}
                      label="Birim"
                      onChange={(e) => handlePeriodTypeChange(index, e.target.value)}
                    >
                      <MenuItem value="gün">Gün</MenuItem>
                      <MenuItem value="hafta">Hafta</MenuItem>
                      <MenuItem value="ay">Ay</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <IconButton onClick={() => handleRemoveVaccine(index)}>
                  <FaTrash />
                </IconButton>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={loading}>İptal</Button>
          <Button 
            onClick={handleCreateTemplate} 
            variant="contained"
            disabled={loading || !templateName || !selectedType || customVaccines.length === 0}
          >
            {loading ? <CircularProgress size={24} /> : 'Oluştur'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Vaccine Modal */}
      <Dialog open={editModalOpen} onClose={handleCloseEditModal} fullWidth maxWidth="sm">
        <DialogTitle>Aşı Takvimi Güncelle</DialogTitle>
        <DialogContent>
          {updateError && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {updateError}
            </Alert>
          )}
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="subtitle1">
              {editingVaccine?.name}
            </Typography>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
              <TextField
                type="number"
                label="Değer"
                value={editFormData.duration_value}
                onChange={(e) => setEditFormData(prev => ({
                  ...prev,
                  duration_value: parseInt(e.target.value) || ''
                }))}
                inputProps={{ min: 1 }}
                sx={{ width: 120 }}
                disabled={updateLoading}
              />
              <FormControl sx={{ width: 120 }}>
                <InputLabel>Birim</InputLabel>
                <Select
                  value={editFormData.duration_type}
                  label="Birim"
                  onChange={(e) => setEditFormData(prev => ({
                    ...prev,
                    duration_type: e.target.value
                  }))}
                  disabled={updateLoading}
                >
                  <MenuItem value="gün">Gün</MenuItem>
                  <MenuItem value="hafta">Hafta</MenuItem>
                  <MenuItem value="ay">Ay</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <TextField
              label="Açıklama"
              value={editFormData.description}
              onChange={(e) => setEditFormData(prev => ({
                ...prev,
                description: e.target.value
              }))}
              multiline
              rows={2}
              disabled={updateLoading}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseEditModal} 
            disabled={updateLoading}
          >
            İptal
          </Button>
          <Button
            onClick={handleUpdateVaccine}
            variant="contained"
            disabled={updateLoading || !editFormData.duration_value || !editFormData.duration_type}
            startIcon={updateLoading ? <CircularProgress size={20} /> : <FaSave />}
          >
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AsiTakvimiScreen;