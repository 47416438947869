import React from 'react';
import KanbanBoard from '../../../yogunBakımServisi/KanbanBoard/KanbanBoard';

const TreatmentProcessTab = ({ petId }) => {
  return (
    <div className="mb-4">
      <KanbanBoard petId={petId} />
    </div>
  );
};

export default TreatmentProcessTab;
